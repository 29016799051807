<template>
  <v-row justify="center">
    <v-col :cols="12" :sm="10" :md="9">
      <div v-if="dataset.length === 0"
           class="empty-view animated jackInTheBox">
        <img src="../../assets/images/empty.png"/>
        <h6>No hay audios</h6>
      </div>
      <div class="list-grid animated bounceInUp"
           v-for="(item, idx) in dataset"
           :key="idx"
           v-else>
        <div class="item-layout">
          <div class="d-flex align-center">
            <eva-icon name="headphones" width="30" height="30" fill="#2297aa" />
            <span class="ml-5">{{item.title}}</span>
          </div>
          <div @click="onShowAudioCardModal(item.url)">
            <eva-icon name="arrow-ios-forward"
                      fill="#2297aa"/>
          </div>
        </div>
        <v-divider></v-divider>
      </div>
    </v-col>
    <v-dialog v-if="showAudioDialog && urlAudio"
              v-model="showAudioDialog"
              max-width="350"
              transition="dialog-bottom-transition">

        <v-card>
          <v-btn icon dark @click="unloadAudioComponent">
            <v-icon color="primary">mdi-close</v-icon>
          </v-btn>
          <v-divider></v-divider>
          <v-row justify="center">
            <v-col cols="11" class="mb-5">
              <audio-component :url="urlAudio" />
            </v-col>
          </v-row>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import AudioComponent from '../../components/audio-component.vue';
import { AudioStories } from '../../model/history.dictionary';
import States from '../../model/states.enum';

export default {
  name: 'audio-resources',
  components: {
    AudioComponent,
  },
  data: () => ({
    dataset: [],
    showAudioDialog: false,
    urlAudio: '',
  }),
  mounted() {
    const { flow } = this.$store.state.user;
    if (flow.includes(States.PHASE_0_LISTEN_HISTORY)) {
      this.dataset.push({
        title: 'Cuento de Puerta 0',
        url: AudioStories.phase_0,
      });
    }

    if (flow.includes(States.PHASE_1_LISTEN_HISTORY)) {
      this.dataset.push({
        title: 'Cuento de Puerta 1',
        url: AudioStories.phase_1,
      });
    }

    if (flow.includes(States.PHASE_2_LISTEN_HISTORY)) {
      this.dataset.push({
        title: 'Cuento de Puerta 2',
        url: AudioStories.phase_2,
      });
    }

    if (flow.includes(States.PHASE_3_LISTEN_HISTORY)) {
      this.dataset.push({
        title: 'Cuento de Puerta 3',
        url: AudioStories.phase_3,
      });
    }

    if (flow.includes(States.PHASE_4_LISTEN_HISTORY)) {
      this.dataset.push({
        title: 'Cuento de Puerta 4',
        url: AudioStories.phase_4,
      });
    }

    if (flow.includes(States.PHASE_5_LISTEN_HISTORY)) {
      this.dataset.push({
        title: 'Cuento de Puerta 5',
        url: AudioStories.phase_5,
      });
    }
  },
  methods: {
    onShowAudioCardModal(url) {
      this.showAudioDialog = true;
      this.getTheAudioURL(url);
    },
    getTheAudioURL(url) {
      const Path = `${process.env.VUE_APP_STORAGE_PATH}${url}`;
      this.$store
        .dispatch('GET_TEMPORAL_URL_FROM', Path)
        .then(this.loadAudioURL);
    },
    loadAudioURL(url) {
      this.urlAudio = url;
    },
    unloadAudioComponent() {
      this.showAudioDialog = false;
      this.urlAudio = '';
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  .list-grid {
    display: flex;
    flex-basis: 1;
    flex-direction: column;
    .item-layout {
      padding: .5em;
      display: flex;
      flex-basis: 1;
      justify-content: space-between;
    }
  }

  .empty-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 30%;
    }
  }
}

</style>

<template>
  <div class="video-container">
    <video ref="videoPlayer" class="video-js"></video>
  </div>
</template>

<script>
import VideoJS from 'video.js';
import 'video.js/dist/video-js.css';
import VideoService from '../services/video.service';

export default {
  name: 'VideoComponent',
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    player: null,
    videoOptions: {},
  }),
  mounted() {
    this.buildVideoOptions();
    this.buildVideJS();
    this.registerEvents();
  },
  methods: {
    buildVideoOptions() {
      this.videoOptions = this.getOptions();
    },
    getOptions() {
      const IsAward = this.src.includes('award');
      if (IsAward) {
        return this.getOptionsWithAudioAndWithoutAutoplay();
      }
      return this.getOptionsWithAutoplayAndMuted();
    },
    getOptionsWithAudioAndWithoutAutoplay() {
      return {
        autoplay: true,
        muted: false,
        controls: true,
        fluid: true,
        responsive: true,
        sources: [{
          src: this.src,
          type: 'video/mp4',
        }],
      };
    },
    getOptionsWithAutoplayAndMuted() {
      return {
        autoplay: true,
        muted: true,
        controls: false,
        fluid: true,
        responsive: true,
        sources: [{
          src: this.src,
          type: 'video/mp4',
        }],
      };
    },
    buildVideJS() {
      this.player = VideoJS(
        this.$refs.videoPlayer,
        this.videoOptions,
      );
    },
    registerEvents() {
      this.player.ready(this.ready);
      this.player.one('ended', this.ended);
    },
    ready() {
      const playPromise = this.player.play();
      if (playPromise) {
        playPromise.catch(() => {
          this.player.controls(true);
        });
      }
    },
    ended() {
      this.player.dispose();
      VideoService.hidden();
    },
  },
};
</script>

<style lang="scss" scoped>
.video-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}
</style>

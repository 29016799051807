import { Subject } from 'rxjs';

const subject = new Subject();

export default {
  show: () => subject.next({ show: true }),
  hidden: () => setTimeout(() => {
    subject.next({ show: false });
  }, 1000),
  getLoader: () => subject.asObservable(),
};

<template>
  <v-row v-if="currentExercise"
                class="fill-height">
    <transition name="fade">
      <div v-if="showImageCave" class="modal">
        <img src="images/cave.png"/>
      </div>
    </transition>

    <v-row justify="center"
           class="mt-10 mb-10">
      <v-col cols="10" md="5">
        <!-- Back button -->
        <v-row v-if="currentExercise.hasGoBack" class="mb-5">
          <v-icon @click="goBackExercise()"
                  color="black"
                  class="icon-background"
                  x-large>mdi-chevron-left</v-icon>
        </v-row>
        <!-- title -->
        <v-row justify="center">
          <h2>{{ currentExercise.title }}</h2>
        </v-row>
        <!-- Text -->
        <v-row justify="center" class="mt-10 mb-10 w-100">
          <vue-markdown class="markdown-body"
                        :emoji="true"
                        :breaks="true"
                        :typographer="true"
                        lang-prefix="markdown"
                        :source="getTextOfCurrentExercise()"></vue-markdown>
        </v-row>
        <v-row justify="center" class="w-100">
          <InputGroup v-if="currentExercise.type === 'INPUT'"
                  title="Escribe tu respuesta aquí">
            <textarea type="text"
                      rows="10"
                      cols="50"
                      v-bind:value="inputValue"/>
            <div class="divider"></div>
          </InputGroup>

          <img v-else-if="currentExercise.type === 'IMAGE_TEXT'"
                class="mxh-image"
                :src="getImageOfCurrentExercise()" />

          <list-images-component v-else-if="currentExercise.type === 'IMAGES'"
                        v-on:next="setNextExerciseComponent"
                        :dataset="currentExercise.dataset"
                        :margin="10"
                        :max="this.currentExercise.rules.max"
                        :min="this.currentExercise.rules.min" />
        </v-row>

        <v-row class="mt-10 w-100"
                justify="center"
                v-if="currentExercise.type !== 'IMAGES'
                      && currentExercise.type !== 'FLASH_TEXT'" >
          <v-btn @click="setNextExerciseComponent" color="primary">Continuar</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import random from 'lodash/random';
import VueMarkdown from 'vue-markdown';
import MenuService from '../services/menu.service';
import InputGroup from '../components/InputGroup.vue';
import ListImagesComponent from '../components/content_exercises/ListImagesComponent.vue';
import download from '../mixins/download';
import scroll from '../mixins/scroll';
import caveExercises from '../assets/datasets/cave.json';
import TextFromStates from '../model/text_from_states.model';
import States from '../model/states.enum';

export default {
  name: 'Cave',
  mixins: [download, scroll],
  components: {
    VueMarkdown,
    InputGroup,
    ListImagesComponent,
  },
  data() {
    return {
      user: null,
      flowState: 0,
      inputValue: '',
      showImageCave: true,
      exercises: caveExercises,
      currentExercise: null,
      autoSabotajeKey: null,
      shadowKey: null,
      resistanceKey: null,
    };
  },
  created() {
    MenuService.show(TextFromStates[States.CAVE], 'menu');
    if (this.$store.state.user.numerology) {
      this.initCave(this.$store.state.user);
    } else {
      this.$store.dispatch('GET_USER_DATA', this.$store.state.user.email)
        .then((user) => {
          this.initCave(user);
        });
    }
  },
  methods: {
    initCave(user) {
      this.flash(() => {
        this.showImageCave = false;
      });
      this.user = user;
      this.flowState = this.user.phases.temple.flowState;
      this.shadowKey = this.user.numerology.shadow;
      this.resistanceKey = this.user.numerology.quantumSequence.photon;
      this.setCurrentExercise();
    },
    goBackExercise() {
      this.flowState -= 1;
      this.setCurrentExercise();
    },
    setNextExerciseComponent(answer) {
      this.scrollToTop();
      this.inputValue = '';
      this.flowState += 1;

      if (this.flowState !== 5) {
        const options = {
          nextFlowState: this.flowState,
        };
        this.$store.dispatch('CAVE_NEXT_FLOW_STATE', options);
      } else {
        const [first] = answer;
        this.autoSabotajeKey = first.replace('.png', '').replace('images/characters/saboteurs/', '');
      }

      this.setCurrentExercise();
    },
    setCurrentExercise() {
      const STATE_SECOND_EVELUATION = 10;
      const STATE_TOTAL_EVELUATION = 10;
      if (
        this.flowState === STATE_SECOND_EVELUATION
        && this.user.phases.temple.totalPoints === null
      ) {
        this.$router.replace('/evaluation/6/cave');
      } else if (
        this.flowState === STATE_TOTAL_EVELUATION
        && this.user.phases.temple.totalPoints !== null
      ) {
        const { dispatch } = this.$store;
        dispatch('NEXT_STATE')
          .then(() => this.$router.replace('/evaluation/total/cave'));
      } else {
        this.currentExercise = this.exercises[this.flowState];
        if (this.currentExercise.type === 'FLASH_TEXT') {
          this.flash(() => this.setNextExerciseComponent());
        }
      }
    },
    getTextOfCurrentExercise(text) {
      let result;
      let textContent = text;
      if (!textContent) {
        if (this.currentExercise.selectTextByKey) {
          textContent = this.currentExercise.selectTextByKey;
        } else if (this.currentExercise.ramdomText) {
          textContent = this.currentExercise.ramdomText;
        } else if (this.currentExercise.text) {
          textContent = this.currentExercise.text;
        }
      }

      if (typeof textContent === 'string') {
        result = textContent;
      } else if (textContent instanceof Array) {
        const numOptions = textContent.length - 1;
        const optionSelected = random(0, numOptions);
        result = this.getTextOfCurrentExercise(textContent[optionSelected]);
      } else if (typeof textContent === 'object') {
        result = this.getTextOfCurrentExercise(textContent[this[this.currentExercise.targetKey]]);
      }
      return result;
    },
    getImageOfCurrentExercise() {
      return `${process.env.BASE_URL}${this.currentExercise.dataset.selectImageByKey[this[this.currentExercise.targetKey]].image}`;
    },
    flash(callback, time = 4000) {
      setTimeout(callback, time);
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  z-index: 1050;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;
  img {
    max-width: 90%;
  }
}
.icon-background {
  background-color: transparent;
}
.w-100 {
  width: 100%;
}

.mxh-image {
  max-height: 350px;
}
</style>

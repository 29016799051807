const TextFromPhases = {
  complete: {
    0: 'PUERTA 0',
    1: 'PUERTA 1',
    2: 'PUERTA 2',
    3: 'PUERTA 3',
    4: 'PUERTA 4',
    5: 'PUERTA 5',
  },
  compact: {
    0: 'PTA 0',
    1: 'PTA 1',
    2: 'PTA 2',
    3: 'PTA 3',
    4: 'PTA 4',
    5: 'PTA 5',
  },
};

export default TextFromPhases;

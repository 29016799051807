<template>
  <v-main v-if="this.phaseValue !== 'intro'">
    <v-container fluid>
      <v-row v-for="item of challenges"
             justify="center"
             align="center"
             :key="item.challenge">
        <v-col cols="8">
          <item-challenge-component :title="item.title"
                                    :image="item.image"
                                    :disabled="!itemEnabled(item)"
                                    @click.native="onclickEvent(item)"/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
import set from 'lodash/set';
import MenuService from '../services/menu.service';
import ItemChallengeComponent from '../components/ItemChallengeComponent.vue';
import assets from '../assets/require.assets';

export default {
  name: 'Challenges',
  components: { ItemChallengeComponent },
  data: () => ({
    phaseValue: null,
    challenges: [],
  }),
  mounted() {
    this.phaseValue = this.$route.params.id;
    this.$store
      .dispatch('GET_PHASE', this.phaseValue)
      .then((phase) => {
        if (this.phaseValue === 'intro') {
          const [IntroChallenge] = phase.challenges;
          this.onclickEvent(IntroChallenge);
        } else {
          this.challenges = phase.challenges;
          this.challenges[0].image = assets.images.challenges.teatro;
          this.challenges[0].title = 'Teatro';
          if (this.phaseValue !== '5') {
            this.challenges[1].image = assets.images.challenges.impulso;
            this.challenges[2].image = assets.images.challenges.materializar;
            this.challenges[3].image = assets.images.challenges.incosnciente;
            this.challenges[1].title = 'Impulso';
            this.challenges[2].title = 'Materializar';
            this.challenges[3].title = 'Inconsciente';
          }
        }
        MenuService.show(this.getTitle(), 'back', () => this.$router.replace('/home'));
      });
  },
  methods: {
    itemEnabled(item) {
      const { user } = this.$store.state;
      const { phases } = user;
      const { challenge } = item;
      let isEnabled = false;
      const phaseKey = `phase_${this.phaseValue}`;
      const Phase = phases[phaseKey];
      let challengeKey = 'theatre';

      if (challenge === 2) challengeKey = 'impulse';
      else if (challenge === 3) challengeKey = 'materialise';
      else if (challenge === 4) challengeKey = 'unconscious';

      if (challengeKey === 'theatre'
          && !Phase[challengeKey]
          && !Phase.impulse) isEnabled = true;
      else if (challengeKey === 'impulse'
               && !Phase[challengeKey]
               && Phase.theatre) isEnabled = true;
      else if (challengeKey === 'materialise'
               && !Phase[challengeKey]
               && Phase.impulse) isEnabled = true;
      else if (challengeKey === 'unconscious'
               && !Phase[challengeKey]
               && Phase.materialise) isEnabled = true;
      set(item, 'enabled', isEnabled);
      return isEnabled;
    },
    onclickEvent(item) {
      if (item.enabled) {
        this.$router.replace({
          name: 'activities',
          params: {
            phase: this.phaseValue,
            challenge: String(item.challenge),
          },
        });
      } else {
        this.$swal({
          type: 'info',
          title: 'Upss!',
          text: 'Este reto está bloqueado, puedes continuar jugando con el que tienes disponible',
          confirmButtonColor: '#2297aa',
        });
      }
    },
    getTitle() {
      switch (this.phaseValue) {
        case '5':
          return 'PUERTA 5. CONCLUIR';
        case '4':
          return 'PUERTA 4. MATERIALIZAR';
        case '3':
          return 'PUERTA 3. CREER';
        case '2':
          return 'PUERTA 2. CREAR';
        case '1':
          return 'PUERTA 1. DESEAR';
        case '0':
          return 'PUERTA 0. SOÑAR';
        default:
          return 'UNKNOW';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container-parent {
    display: grid;
    height: 100vh;
    grid-row-gap: 0px;
    grid-template: 3em auto / 100%;
}
.container {
    height: auto;
}
div.main {
  grid-area: main;
}

div.menu {
  -webkit-box-shadow: 0px -1px 8px 0px $shadow;
  -moz-box-shadow: 0px -1px 8px 0px $shadow;
  box-shadow: 0px -1px 8px 0px $shadow;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    padding: 1em;
    text-decoration: none;
  }

  h4 {
    margin-right: 1em;
  }
}
</style>

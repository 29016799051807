<template>
  <v-container fluid>
    <div class="travel">
      <TimelineItem v-for="(item, idx) in dataset"
                    :title="item.title"
                    :dotColor="item.color"
                    :key="idx" />
    </div>
  </v-container>
</template>

<script>
import filter from 'lodash/filter';
import map from 'lodash/map';
import has from 'lodash/has';
import TextFromStates from '../model/text_from_states.model';
import States from '../model/states.enum';
import MenuService from '../services/menu.service';
import TimelineItem from '../components/TimelineItem.vue';

export default {
  name: 'travel',
  components: {
    TimelineItem,
  },
  data: () => ({
    dataset: [],
  }),
  created() {
    MenuService.show('MI VIAJE', 'back', () => this.$router.replace('/home'));
    const { flow } = this.$store.state.user;
    const flowData = filter(flow, (state) => has(TextFromStates, state));
    this.dataset = map(flowData, (state) => {
      let color = 'primary';
      if (state === States.CAVE) {
        color = 'error';
      }
      return {
        title: TextFromStates[state],
        color,
      };
    });
  },
};
</script>

<style lang="scss" scoped>
.travel {
  margin: 0% auto;
  width: max-content;
}
</style>

<template>
  <v-banner v-model="existsNewVersion"
            color="grey lighten-4"
            transition="slide-y-transition"
            shaped>
    <p class="font-weight-black">
      Hay una nueva versión disponible.
      <br>
      Cuando pulses "Actualizar" se te cerrará la sesión y se llevará acabó la actualización.
    </p>
    <template v-slot:actions>
      <v-btn @click="updateVersion"
              color="primary"
              large>Actualizar</v-btn>
    </template>
  </v-banner>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'updates-banner-component',
  methods: {
    updateVersion() {
      const completeUpdate = () => {
        this.$store.dispatch('UNSUBSCRIBE_OF_VERSION_HANDLER');
        this.$store.dispatch('UPDATE_VERSION');
      };

      if (this.isLogged()) {
        this.$store.dispatch('SIGNOUT_USER').then(() => {
          completeUpdate();
        });
      } else {
        completeUpdate();
      }
    },
  },
  computed: {
    ...mapGetters([
      'isLogged',
      'existsNewVersion',
    ]),
  },
};
</script>

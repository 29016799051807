<template>
  <div>
    <div class="timer">
      <h3>{{ currentTime }} <span v-if="duration">/</span> {{ duration }}</h3>
    </div>

    <v-row v-show="isLoadedData" justify="space-around">
      <v-btn icon @click="moveTrack(-10)">
        <eva-icon name="rewind-left-outline"
                  fill="#2297aa"
                  width="35"
                  height="35"/>
      </v-btn>
      <v-btn icon @click="playSound" v-if="isPlayAction">
      <eva-icon name="play-circle-outline"
                fill="#2297aa"
                width="35"
                height="35"/>
      </v-btn>
      <v-btn icon @click="stopSound" v-else-if="isPlayAction === false">
        <eva-icon name="pause-circle-outline"
                  fill="#2297aa"
                  width="35"
                  height="35"/>
      </v-btn>
      <v-btn icon @click="moveTrack(10)">
        <eva-icon name="rewind-right-outline"
                  fill="#2297aa"
                  width="35"
                  height="35"/>
      </v-btn>
    </v-row>
    <v-row v-show="isLoadedData" justify="center" class="mt-10">
      <v-btn icon @click="changePlaybackRate(-0.25)">
        <eva-icon name="minus-circle-outline"
                  fill="#2297aa"
                  width="35"
                  height="35"/>
      </v-btn>
      <v-btn icon @click="changePlaybackRate(0.25)">
        <eva-icon name="plus-circle-outline"
                  fill="#2297aa"
                  width="35"
                  height="35"/>
      </v-btn>
    </v-row>
    <v-row justify="center" class="mt-10">
      <v-btn icon @click="reload()">
        <eva-icon name="refresh-outline"
                  fill="#2297aa"
                  width="35"
                  height="35"/>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import StayAwake from 'stayawake.js';
import device from 'current-device';

export default {
  name: 'audio-component',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    audio: null,
    isLoadedData: false,
    isPlayAction: true,
    duration: '',
    currentTime: '00:00',
  }),
  created() {
    StayAwake.init();
  },
  mounted() {
    this.audio = new Audio(this.url);
    if (device.ios()) {
      this.isLoadedData = true;
    } else {
      this.audio.addEventListener('canplaythrough', () => {
        this.isLoadedData = true;
      });
    }
  },
  destroyed() {
    this.stopSound();
    this.audio = null;
  },
  methods: {
    isDev() {
      return process.env.VUE_APP_MODELO === 'DEVELOPMENT';
    },
    moveTrack(time) {
      this.audio.currentTime += time;
    },
    reload() {
      this.stopSound();
      this.audio.load();
      this.playSound();
    },
    playSound() {
      if (!this.url) return;

      this.audio.play();
      this.audio.addEventListener('timeupdate', this.timeupdate);
      this.audio.addEventListener('ended', this.ended);
      this.duration = this.calculateTotalValue(this.audio.duration);
      this.isPlayAction = false;
      StayAwake.enable();
    },
    stopSound() {
      if (!this.url) return;

      this.audio.pause();
      this.audio.removeEventListener('timeupdate', this.timeupdate);
      this.audio.removeEventListener('ended', this.ended);
      this.isPlayAction = true;
      StayAwake.disable();
    },
    changePlaybackRate(rate) {
      this.audio.playbackRate += rate;
    },
    calculateTotalValue(length) {
      const minutes = Math.floor(length / 60);
      const secondsInt = length - minutes * 60;
      const secondsStr = secondsInt.toString();
      const seconds = secondsStr.substr(0, 2);
      const time = `${minutes}:${seconds}`;
      return time;
    },
    calculateCurrentValue(currentTime) {
      const currentMinute = parseInt(currentTime / 60, NaN) % 60;
      const currentSecondsLong = currentTime % 60;
      const currentSeconds = currentSecondsLong.toFixed();
      const currentTimeStr = `${currentMinute < 10 ? `${currentMinute}` : currentMinute}:${currentSeconds < 10 ? `0${currentSeconds}` : currentSeconds}`;
      if (this.duration === 'NaN:Na') {
        this.duration = this.calculateTotalValue(this.audio.duration);
      }
      return currentTimeStr;
    },
    timeupdate() {
      this.currentTime = this.calculateCurrentValue(this.audio.currentTime);
    },
    ended() {
      StayAwake.disable();
      this.$emit('ended', null);
    },
  },
};
</script>

<style scoped lang="scss">
.timer {
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: center;
}
.space-around {
  justify-content: space-around !important;
}
</style>

<template>
<div class="wrapper">
  <div class="home-container">
    <v-row class="mt-8 mr-3 basis-0" justify="end" align="center">
      <span class="semibold">{{valueText()}}</span>
      <img :src="valueSymbol()" class="height-symbol ml-5"/>
      <span class="semibold ml-5">{{getTargetText()}}</span>
    </v-row>
    <v-row justify="center" align="center" class="send-to-background">
      <v-col cols="11" sm="9" md="5">
        <img :src="labyrinthImage"
           class="img-zoom send-to-background width-100"
           alt="Laberinto">
      </v-col>
    </v-row>
    <div class="d-flex flex-wrap justify-space-around white elevation-5 pa-3">
        <img v-if="passageThrough('phase_0')"
             class="premio-height"
             src="/images/awards/dock.png"
             alt="Premio muelle">

        <img v-if="passageThrough('phase_1')"
             class="premio-height"
             src="/images/awards/ring.png"
             alt="Premio Anillo">

        <img v-if="passageThrough('phase_2')"
             class="premio-height"
             src="/images/awards/stick.png"
             alt="Premio Baston">

        <img v-if="passageThrough('phase_3')"
             class="premio-height"
             src="/images/awards/heart.png"
             alt="Premio Corazon Alado">

        <img v-if="passageThrough('phase_4')"
             class="premio-height"
             src="/images/awards/laurel.png"
             alt="Premio Laurel">
      <v-btn @click="continueChallenge"
             text>
        <span class="btn-continue">Continuar</span>
      </v-btn>
    </div>
  </div>
  <div class="chat-button">
    <ChatNotification :isActive="chatNotification.isActive"
                      :message="chatNotification.message" />
    <v-btn @click="openChat"
           color="#FAECCC"
           elevation="2"
           large
           fab>
      <img src="../assets/images/charlie_icon.png"
           alt="Botón abrir Chat"
           width="32px"
           height="32px" />
    </v-btn>
  </div>
</div>
</template>

<script>
import find from 'lodash/find';
import ChatNotification from '../components/ChatNotification.vue';
import MenuService from '../services/menu.service';
import States from '../model/states.enum';
import TextFromStates from '../model/text_from_states.model';
import NumberFromStates from '../model/number_from_states.model';
import LabyrinthFromStates from '../model/labyrinth_from_states.model';
import chatService from '../services/chat.service';

export default {
  name: 'Home',
  components: { ChatNotification },
  data: () => ({
    currentPhase: '',
    phaseNumber: '',
    labyrinthImage: '',
    chatNotification: {
      isActive: false,
      message: '',
    },
  }),
  created() {
    this.chatSubscription = chatService.getChatObservable().subscribe((state) => {
      if (state.notify) {
        this.chatNotification.isActive = true;
        this.chatNotification.message = state.message;
        setTimeout(() => {
          this.chatNotification.isActive = false;
        }, 10000);
      }
    });
  },
  mounted() {
    const { user } = this.$store.state;
    this.$store.dispatch('GET_CONFIG');
    this.currentPhase = TextFromStates[user.flowState];
    this.phaseNumber = NumberFromStates[user.flowState];
    this.labyrinthImage = LabyrinthFromStates[user.flowState];
    MenuService.show(this.currentPhase, 'menu');
  },
  methods: {
    continueChallenge() {
      const { user } = this.$store.state;
      if (
        user.flowState === States.PHASE_0
      ) {
        this.$router.replace('/phase/0');
      } else if (
        user.flowState === States.PHASE_1
      ) {
        this.$router.replace('/phase/1');
      } else if (
        user.flowState === States.PHASE_2
      ) {
        this.$router.replace('/phase/2');
      } else if (
        user.flowState === States.PHASE_3
      ) {
        this.$router.replace('/phase/3');
      } else if (
        user.flowState === States.PHASE_4
      ) {
        this.$router.replace('/phase/4');
      } else if (
        user.flowState === States.PHASE_5
      ) {
        this.$router.replace('/phase/5');
      }
    },
    passageThrough(keyPhase) {
      const { phases } = this.$store.state.user;
      let result = false;
      if (!phases) return result;
      const phaseObj = phases[keyPhase];
      if (!phaseObj) return result;
      const IsEvaluationCompleted = phaseObj.totalPoints;
      if (IsEvaluationCompleted) result = true;
      return result;
    },
    valueText() {
      const EmptyResult = '';

      const { exercises, email } = this.$store.state.user;
      if (!exercises || email === '') return EmptyResult;

      const EXERCISE_NUMBER = '1';
      const ActivityValue = find(exercises, ({ exercise }) => exercise === EXERCISE_NUMBER);
      if (!ActivityValue) return EmptyResult;

      const { answer } = ActivityValue;
      if (!answer) return EmptyResult;

      const INDEX_VALUE_EXERCISE = 3;
      const ExerciseAnswered = answer[INDEX_VALUE_EXERCISE];
      const [ValueSelected] = ExerciseAnswered.answer;
      return ValueSelected;
    },
    valueSymbol() {
      const { exercises, email } = this.$store.state.user;
      let result = '';
      if (exercises && email !== '') {
        const valueExercise = find(exercises, (item) => item.exercise === '1');
        if (!valueExercise) return '';
        const [valueImg] = valueExercise.answer[5].answer;
        if (valueImg.includes('images')) result = valueImg;
        else result = `/images/${valueImg}`;
      }
      return result;
    },
    getTargetText() {
      const { exercises, email } = this.$store.state.user;
      if (exercises && email !== '') {
        const ID_ACTION_PLAN = '173';
        const TYPE_ACTION_PLAN = 'ACTION_PLAN';
        const ACTION_PLAN_TARGET_INDEX = 0;
        const planning = exercises.find((item) => item.exercise === ID_ACTION_PLAN);
        if (planning && planning.answer) {
          const ActionPlan = planning.answer.find((exercise) => exercise.type === TYPE_ACTION_PLAN);
          const { description } = ActionPlan.answer[ACTION_PLAN_TARGET_INDEX];
          return description;
        }
      }
      return '';
    },
    openChat() {
      const { user } = this.$store.state;
      const context = {
        gender: user.gender || 'female',
        phase: this.phaseNumber,
      };
      chatService.show(context);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-continue {
  color: $primary;
}
.width-100 {
  width: 100% !important;
}
.wrapper {
  width: 100%;
  height: 100%;
  background-color: $background;
  background-image: url('/images/alas_azules.svg');
  background-size: 200px;
  background-repeat: repeat;
  background-position: top;
  .home-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .basis-0 {
      -webkit-box-flex: 0;
      -ms-flex: 0;
      flex: 0;
    }
    img.height-symbol {
      height: 50px;
    }
    div.home-menu {
      -webkit-box-shadow: 0px -1px 8px 0px $shadow;
      -moz-box-shadow: 0px -1px 8px 0px $shadow;
      box-shadow: 0px -1px 8px 0px $shadow;
      background-color: $background;
      ul {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          a, router-link {
            margin: 1em;
            text-decoration: none;
          }
          img {
            width: 50%;
          }
        }
      }
    }
  }
}

.img-zoom {
  -webkit-animation: scale-in-br 1s ease-in both;
  animation: scale-in-br 1s ease-in both;
}

@-webkit-keyframes scale-in-br {
  0% {
    -webkit-transform-origin: 62% 40%;
    transform-origin: 62% 40%;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: 62% 40%;
    transform-origin: 62% 40%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-br {
  0% {
    -webkit-transform-origin: 62% 40%;
    transform-origin: 62% 40%;
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  100% {
    -webkit-transform-origin: 62% 40%;
    transform-origin: 62% 40%;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.premio-height {
  height: 35px;
}

.chat-button {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 201;
  display: flex;
  height: 20%;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  gap: 1rem;
}
</style>

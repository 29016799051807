<template>
  <component v-if="guideData && activeComponent"
              :is="activeComponent"
              :guideData="guideData"/>
</template>

<script>
import guides from '../../model/guides.enum';
import GuideType from './guide-type';
import MenuService from '../../services/menu.service';
import States from '../../model/states.enum';
import TextFromStates from '../../model/text_from_states.model';

export default {
  name: 'end-game-view',
  components: {
    'guide-without-button-component': () => import('./components/guide-without-button-component.vue'),
  },
  data: () => ({
    guides,
    guideData: null,
    activeComponent: GuideType.GuideInCardWithoutButton,
  }),
  created() {
    MenuService.show(TextFromStates[States.END], 'menu');
    this.$store
      .dispatch('GET_USE_GUIDE')
      .then(this.loadGuideData);
  },
  methods: {
    loadGuideData(document) {
      const { guide, guideId } = document;
      this.guideData = {
        guide,
        guideId,
      };
    },
  },
};
</script>

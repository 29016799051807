<template>
  <v-row justify="center">
    <v-col cols="10" sm="6" lg="4" class="main">
      <h3 class="title mt-10">¿Cuánto sientes que te has involucrado a lo largo de la puerta?</h3>
      <div class="evaluation-options mt-10">
        <v-radio-group v-model="optionsSelected">
          <v-radio v-for="option in options"
                   :key="option.id"
                   :value="option.id"
                   class=" mt-5">
            <template v-slot:label>
              <div class="paragraph-container">
                <p class="text-options">{{option.label}}</p>
                <img :src="option.icon"
                    alt="icono de carita"
                    class="emoji-size emoji-position" />
              </div>
            </template>
          </v-radio>
        </v-radio-group>
      </div>

      <v-row class="mt-5 actions">
        <v-btn color="primary"
               large
               :disabled="disableButton"
               @click="onEnded">CONTINUAR</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import MenuService from '../services/menu.service';

export default {
  name: 'Evaluation',
  props: {
    phase: {
      type: String,
      required: false,
    },
    challenge: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    phaseValue: undefined,
    challengeValue: undefined,
    options: [
      {
        id: 5,
        label: 'Me he involucrado muchísimo, lo hice todo con muchas ganas.',
        icon: '/images/evaluation-emojis/five.png',
      },
      {
        id: 4,
        label: 'Me he involucrado unas veces más que otras.',
        icon: '/images/evaluation-emojis/four.png',
      },
      {
        id: 3,
        label: 'Me he involucrado lo necesario, he hecho lo que me pedían.',
        icon: '/images/evaluation-emojis/three.png',
      },
      {
        id: 2,
        label: 'No mucho, no me ha motivado demasiado.',
        icon: '/images/evaluation-emojis/two.png',
      },
      {
        id: 1,
        label: 'No me he involucrado nada.',
        icon: '/images/evaluation-emojis/one.png',
      },
    ],
    optionsSelected: null,
  }),
  created() {
    if (
      this.phase === undefined
      || this.challenge === undefined
    ) {
      const {
        phase,
        challenge,
      } = this.$route.params;
      this.phaseValue = phase;
      this.challengeValue = challenge;
    } else {
      this.phaseValue = this.phase;
      this.challengeValue = this.challenge;
    }
    MenuService.hidden();
  },
  computed: {
    disableButton() {
      return this.optionsSelected === null;
    },
  },
  methods: {
    onEnded() {
      const { phaseValue, optionsSelected } = this;
      let phase = `phase_${phaseValue}`;
      if (phase === 'phase_cave') phase = 'temple';
      const options = { phase, totalPoints: optionsSelected };
      this.$store
        .dispatch('SET_EVALUATION', options)
        .then(() => {
          if (phase === 'temple') {
            this.$store.dispatch('NEXT_STATE')
              .then(() => {
                this.$router.replace('/home');
              });
          } else {
            this.$emit('next-activity');
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .emoji-size {
    margin-left: 1%;
    width: 30px;
  }
  .evaluation-options {
      display: flex;
      flex-direction: column;
  }
  .evaluation-option {
    display: flex;
    flex-direction: row;
  }
  .title {
    font-size: 1.563rem !important;
    text-align: center;
  }
  .text-options {
    color: black;
    font-size: 1.125rem !important;
    font-weight: 500;
  }
  .paragraph-container {
    width: 100%;
    display: grid;
    justify-items: left;
    align-items: center;
    grid-template-columns: 1fr .4fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
  }
  .emoji-position {
    justify-self: center;
  }
</style>

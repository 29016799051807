<template>
  <v-col>
    <h6 class="text-align-left">{{ question.title }}</h6>

    <v-row justify="space-between">
      <v-col cols="12"
             sm="5"
             xs="12"
             class="mt-5"
             v-if="question.howIFeel !== undefined">
        <v-select v-model="question.howIFeel"
                  background-color="#efefef"
                  :items="options"
                  label="¿Cómo me siento ahora?"
                  hide-details
                  dense
                  filled></v-select>
      </v-col>
      <v-col cols="12"
             sm="5"
             xs="12"
             class="mt-5"
             v-if="question.howDoIWantToSeeMe !== undefined">
        <v-select background-color="#efefef"
                  v-model="question.howDoIWantToSeeMe"
                  :items="options"
                  label="¿Cómo quiero verme?"
                  hide-details
                  dense
                  filled></v-select>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'question-row-component',
  components: { },
  props: {
    question: {
      title: {
        type: String,
        required: true,
      },
      howIFeel: {
        type: Number,
        required: true,
      },
      howDoIWantToSeeMe: {
        type: Number,
        required: true,
      },
    },
  },
  data: () => ({
    options: [0, 1, 2, 3],
  }),
};
</script>

<style lang="scss" scoped>
.text-align-left {
  text-align: left;
}
</style>

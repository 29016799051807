<template>
  <div class="fill-height">
    <v-toolbar color="white">
      <h5>ACUERDO CON CHARLIE</h5>
      <v-spacer></v-spacer>
    </v-toolbar>

    <div class="chat__container">
      <div class="chat__messages">
        <div v-for="(message, index) in messages" class="fade-in" :key="index">
          <h6 class="mt-4" v-if="message.role === 'assistant'">Charlie</h6>
          <h6 class="mt-4" v-else>Tú</h6>
          <markdown-component :source="message.content" />
        </div>
        <div v-if="(messages.length >= initialMessages.length)" class="text-center fade-in">
          <v-btn color="primary"
                 class="mt-4"
                 @click="next">
            ACEPTO EL ACUERDO
          </v-btn>
        </div>
      </div>
      <div class="chat__message-input">
        <div class="chat__message-input__container">
          <div class="chat__message-input__input">
            <v-text-field
              label="Escribe tu mensaje aquí"
              v-model="newMessage"
              :disabled="loading"
              autofocus
            ></v-text-field>
          </div>
          <div class="chat__message-input__button">
            <v-btn icon :disabled="loading" @click="sendMessage">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <img src="../assets/images/characters/charlie/charlie_welcome.png"
          class="charlie-welcome send-to-background animated bounceInUp" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import MarkdownComponent from '../components/markdown-component.vue';
import menuService from '../services/menu.service';

const maleInitialMessages = [
  `🌟 ¡Hola Viajero!
  Soy Charlie, tu guía de descubrimientos.`,

  `Estoy encantado de embarcarnos en esta emocionante aventura juntos. Aquí está nuestro acuerdo para hacer de este viaje una experiencia inolvidable:

  ##### Tu Deber y Función 🚀
  1. **Explorador Intrépido**: Tú eres el capitán de este barco. Estoy aquí para ayudarte a navegar, pero tú decides la dirección del viaje. ¡Descubramos juntos ese destino único que te llena de entusiasmo! 🧭
  2. **Reflexión Personal**: A medida que avanzamos, reflexionarás sobre tus metas, valores y pasiones. Algunas joyas escondidas pueden surgir, y estoy aquí para ayudarte a identificarlas. 💎

  ##### Mi Deber y Función 🌈
  1. **Facilitador de Descubrimientos**: Mi trabajo es ayudarte a desenterrar tus metas y pasiones. Preguntas, ejercicios y charlas estarán a la orden del día para destapar esas joyas ocultas. 🔍
  2. **Apoyo Incondicional**: Estaré a tu lado durante cada subida y bajada. Mi objetivo es ser tu ancla en las tormentas y tu viento a favor cuando necesites un empujón. 💪

  ##### Confidencialidad 🤐
  1. **Caja Fuerte de Secretos**: Todo lo que compartas conmigo se queda entre nosotros. Tu confidencialidad es mi prioridad, así que siéntete libre de abrirte sin reservas.
  2. **Transparencia Recíproca**: En la misma línea, apreciaré tu honestidad para que nuestro viaje sea lo más auténtico posible.

  ##### Tipo de Relación 👫
  1. **Aliados de Viaje**: Somos un equipo. Mi objetivo es apoyarte, desafiarte y motivarte mientras recorremos este camino juntos.
  2. **Celebración de Logros**: Cada pequeño logro merece una celebración. ¡Incluso las pequeñas victorias son grandes hitos en nuestro viaje! 🏆

  ¡Y así, con nuestra brújula apuntando hacia el descubrimiento, comenzamos nuestro recorrido hacia un destino asombroso que aún está por revelarse!`,
  '¿Listo para embarcarte en esta travesía de autoconocimiento?',
];
const femaleInitialMessages = [
  `🌟 ¡Hola Viajera!
  Soy Charlie, tu guía de descubrimientos.`,

  `Estoy encantada de embarcarnos en esta emocionante aventura juntas. Aquí está nuestro acuerdo para hacer de este viaje una experiencia inolvidable:

  ##### Tu Deber y Función 🚀
  1. **Exploradora Intrépida**: Tú eres la capitana de este barco. Estoy aquí para ayudarte a navegar, pero tú decides la dirección del viaje. ¡Descubramos juntas ese destino único que te llena de entusiasmo! 🧭
  2. **Reflexión Personal**: A medida que avanzamos, reflexionarás sobre tus metas, valores y pasiones. Algunas joyas escondidas pueden surgir, y estoy aquí para ayudarte a identificarlas. 💎

  ##### Mi Deber y Función 🌈
  1. **Facilitadora de Descubrimientos**: Mi trabajo es ayudarte a desenterrar tus metas y pasiones. Preguntas, ejercicios y charlas estarán a la orden del día para destapar esas joyas ocultas. 🔍
  2. **Apoyo Incondicional**: Estaré a tu lado durante cada subida y bajada. Mi objetivo es ser tu ancla en las tormentas y tu viento a favor cuando necesites un empujón. 💪

  ##### Confidencialidad 🤐
  1. **Caja Fuerte de Secretos**: Todo lo que compartas conmigo se queda entre nosotras. Tu confidencialidad es mi prioridad, así que siéntete libre de abrirte sin reservas.
  2. **Transparencia Recíproca**: En la misma línea, apreciaré tu honestidad para que nuestro viaje sea lo más auténtico posible.

  ##### Tipo de Relación 👭
  1. **Aliadas de Viaje**: Somos un equipo. Mi objetivo es apoyarte, desafiarte y motivarte mientras recorremos este camino juntas.
  2. **Celebración de Logros**: Cada pequeño logro merece una celebración. ¡Incluso las pequeñas victorias son grandes hitos en nuestro viaje! 🏆

  ¡Y así, con nuestra brújula apuntando hacia el descubrimiento, comenzamos nuestro recorrido hacia un destino asombroso que aún está por revelarse!`,
  '¿Lista para embarcarte en esta travesía de autoconocimiento?',
];

const notValidMessage = 'Lo siento, no puedo responder a eso.';

export default {
  components: {
    MarkdownComponent,
  },
  data() {
    return {
      initialMessages: [],
      dialog: false,
      loading: false,
      conversationEnabled: false,
      newMessage: '',
      messages: [],
      chatSubscription: null,
    };
  },
  created() {
    menuService.hidden();
    const { user } = this.$store.state;
    this.initialMessages = femaleInitialMessages;
    if (user.gender === 'male') {
      this.initialMessages = maleInitialMessages;
    }
    this.messages = [];
    this.initialMessages.forEach((message, index) => {
      setTimeout(() => {
        this.messages.push({ content: message, role: 'assistant' });
        if (index === this.initialMessages.length - 1) this.scrollToLastMessage();
      }, index * 3000);
    });
  },
  destroyed() {
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe();
    }
  },
  methods: {
    scrollToLastMessage() {
      this.$nextTick(() => {
        const container = document.querySelector('.chat__messages');
        if (container.lastElementChild) {
          const lastMessagePosition = container.lastElementChild.offsetTop;
          container.scrollTop = lastMessagePosition;
        }
      });
    },
    async sendMessage() {
      if (this.newMessage.trim() !== '') {
        try {
          this.loading = true;
          this.messages.push({ content: this.newMessage, role: 'user' });
          const baseURL = 'https://europe-west2-pwa-charlie.cloudfunctions.net/charlieAssistantFunction';
          const url = `${baseURL}/api/charlie-agreement`;
          const { user } = this.$store.state;
          const body = {
            messages: this.messages.slice(-3),
            context: {
              gender: user.gender,
            },
          };
          const response = await axios.post(url, body);
          this.newMessage = '';
          let answer = response.data.response;
          if (answer === 'no') {
            answer = notValidMessage;
          }
          answer = answer.replace(/\$\{writename\}/g, user.name).replace(/\$\{write name\}/g, user.name);
          this.messages.push({ content: answer, role: 'assistant' });
        } catch (error) {
          console.error(error);
        } finally {
          this.scrollToLastMessage();
          this.loading = false;
        }
      }
    },
    next() {
      this.$store
        .dispatch('NEXT_STATE')
        .then(() => this.$router.replace('/guide'));
    },
  },
};
</script>

<style scoped>
.chat__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 54px);
  background-color: #fff;
  padding: 1rem 0rem 0rem 0rem;
}

.chat__messages {
  z-index: 2;
  width: 100%;
  padding: 0rem 1rem;
  flex: 1;
  overflow-y: scroll;
}

.chat__messages p {
  margin: 0;

}

.chat__message-input {
  background-color: white;
  z-index: 2;
  padding: 1rem 0.5rem;
  width: 100%;
}

.chat__message-input__container {
  padding: 0.5rem 1rem;
  border: 1px solid #707070;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat__message-input__input {
  width: 100%;
}

.chat__message-input__button {
}

.messages__container {
  height: 100%;
  overflow-y: scroll;
}

img.charlie-welcome {
  opacity: 0.2;
  position: absolute;
  bottom: 5%;
  right: 0%;
  max-width: 350px;
}

@include sm-portrait {
  img.charlie-welcome {
    visibility: hidden;
  }
}
@include md {
  img.charlie-welcome {
    visibility: visible;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s;
}
</style>

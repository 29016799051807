const impulso = require('./images/challenges/impulso@x1.png');
const incosnciente = require('./images/challenges/incosnciente@x1.png');
const materializar = require('./images/challenges/materializar@x1.png');
const teatro = require('./images/challenges/teatro@x1.png');

const assets = {
  images: {
    challenges: {
      impulso,
      incosnciente,
      materializar,
      teatro,
    },
  },
};

export default assets;

<template>
  <div class="item">
      <img id="simbol" :src="image">
      <span class="title"
            v-bind:class="{
              disabled: disabled,
            }">{{ title }}</span>
      <img id="icon"
           src="../assets/images/icons/next.png"/>
  </div>
</template>

<script>
export default {
  name: 'ItemChallengeComponent',
  props: {
    image: {
      default: 'https://via.placeholder.com/50',
    },
    title: {
      default: 'TITULO RETO',
    },
    disabled: {
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.item {
    height: 50px;
    text-align: left;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-top: 1em;
    -webkit-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.2);
    box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.2);
    img#simbol {
      max-width: 100%;
      height: auto;
      border-radius: 10px;
    }

    .title {
      width: 90%;
      color: #71ABB5;
      font-weight: bold;
      margin-left: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    img#icon {
      margin-right: 3%;
    }
}

.disabled {
  color: $divider !important;
}
</style>

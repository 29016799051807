function getNoRequiredActivities(allActivities, requiredActivities) {
  const excludeRequiredActivities = (activityRef) => !requiredActivities.includes(activityRef);
  return allActivities.filter(excludeRequiredActivities);
}

function getRequiredActivities(requiredActivities) {
  return requiredActivities.map((activityID) => `exercises/${activityID}`);
}

function getReferences(activity) {
  return activity.reference;
}

function buildRouteOfActivities({ challenge, addEveluation, completedActivities }) {
  let { exercises } = challenge;
  const { rules } = challenge;
  const { required, optional } = rules;
  exercises = exercises
    .map(getReferences)
    .filter((reference) => !completedActivities.includes(reference));
  const Activities = getRequiredActivities(required);
  let ramdomActivities = getNoRequiredActivities(exercises, Activities);
  let NumberOfOptions = ramdomActivities.length - 1;

  for (let index = 0; index < optional; index += 1) {
    const IndexOfActivitySelected = Math.round(Math.random() * NumberOfOptions);
    const ActivityRefSelected = ramdomActivities[IndexOfActivitySelected];
    Activities.push(ActivityRefSelected);
    const deleteSelectedActivity = (activityRef) => activityRef !== ActivityRefSelected;
    ramdomActivities = ramdomActivities.filter(deleteSelectedActivity);
    NumberOfOptions -= 1;
  }

  if (addEveluation) {
    Activities.push('exercise/evaluation');
  }
  return Activities;
}

export default buildRouteOfActivities;

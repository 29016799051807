<template>
    <div>
        <ul>
            <li v-for="(item, idx) in dataset.items"
                :key="idx"
                class="mt-10">
              <InputGroup :title="item.lable">
                <input v-model.number="item.value.input"
                       :type="item.inputType"
                       max="10"
                       min="1"/>
                <div class="divider"></div>
              </InputGroup>
            </li>
        </ul>
        <AdjetivesSumary class="mt-5" :dataset="summary" />
        <div class="actions">
          <v-btn color="primary" class="mt-10 mb-10" @click="next()">SIGUIENTE</v-btn>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import AdjetivesSumary from './AdjetivesSumary.vue';
import InputGroup from '../InputGroup.vue';

export default {
  name: 'IdentifyYourGargoyles',
  components: { InputGroup, AdjetivesSumary },
  props: {
    dataset: {
      type: Object,
      required: true,
    },
  },
  computed: {
    summary() {
      const tags = _.chain(this.dataset.items)
        .filter((item) => item.value.input < 7)
        .map((item) => item.lable.trim())
        .value();
      return [{
        title: 'Resumen',
        tags,
      }];
    },
  },
  methods: {
    next() {
      const isValid = this.dataset.items.every((item) => item.value.input !== '');
      if (isValid) {
        this.$emit('next', this.dataset);
      } else {
        this.$swal({
          type: 'info',
          title: 'Hola!',
          text: 'Necesito que completes todos los campos.',
          confirmButtonColor: '#2297aa',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/app.scss';
.disabled {
 label {
   color: $divider !important;
 }
}
</style>

<template>
  <v-row class="mt-10" justify="center">
    <div class="score-table-size">
      <ScoreTableRadarChart :exercise="dataset"></ScoreTableRadarChart>
    </div>
  </v-row>
</template>

<script>
import MenuService from '../services/menu.service';
import ScoreTableRadarChart from '../components/content_exercises/ScoreTableRadarChart.vue';

export default {
  name: 'MandalaView',
  components: {
    ScoreTableRadarChart,
  },
  data: () => ({
    dataset: null,
  }),
  created() {
    MenuService.show('MANDALA', 'back', () => this.$router.replace('/home'));
    this.dataset = this.getMandala();
  },
  methods: {
    getMandala() {
      const { exercises } = this.$store.state.user;
      let result;
      if (
        exercises
      ) {
        result = exercises.find((item) => item.exercise === '3');
      }
      return result.answer[1];
    },
  },
};
</script>

<style lang="scss" scoped>
v-row {
  background-color: $background;
  .score-table-size {
    width: 50%;
  }
}
</style>

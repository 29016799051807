<template>
  <div class="center">
    <div v-if="showTotem === false" class="animated hinge delay-1s">
        <img class="height" :src="demonPath" />
    </div>
    <div v-if="showTotem === true" class="animated jackInTheBox">
        <img class="height" :src="totem" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'TotemVSDebility',
  props: {
    totem: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    demonPath: `${process.env.BASE_URL}images/demons/amenaza.png`,
    showTotem: false,
  }),
  mounted() {
    setTimeout(() => {
      this.showTotem = true;
    }, 1500);
  },
};
</script>

<style>
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.height {
  height: 250px !important;
}
</style>

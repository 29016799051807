<template>
  <ContainerSimbols color="secondary-variant" class="fill-height"></ContainerSimbols>
</template>
<script>
import MenuService from '../services/menu.service';
import ContainerSimbols from '../components/ContainerSimbols.vue';
import States from '../model/states.enum';

export default {
  name: 'Question',
  components: { ContainerSimbols },
  created() {
    MenuService.hidden();
  },
  mounted() {
    const { flowState } = this.$store.state.user;
    let question = '';
    let keyOfQuestion = '';
    if (flowState === States.PHASE_3_QUESTION_YOU_HAVE_BLOCKAGES) {
      question = '¿Sientes que tienes bloqueos, frenos o miedos que no te dejan avanzar?';
      keyOfQuestion = 'youHaveBlockages';
    } else if (flowState === States.PHASE_3_QUESTION_TONE_DOWN_EMOTION) {
      question = '¿Tienes alguna emoción que te gustaría bajar su intensidad?';
      keyOfQuestion = 'toneDownEmotion';
    }
    this.$swal({
      type: 'question',
      text: question,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Si',
      cancelButtonText: 'No',
      confirmButtonColor: '#2297aa',
      cancelButtonColor: '#ff6400',
    })
      .then((result) => {
        const { value } = result;
        let valueOfQuestion = false;
        if (value) {
          valueOfQuestion = true;
        }
        this.$store.dispatch('SET_HAS_QUESTION', {
          keyOfQuestion,
          valueOfQuestion,
        });
        this.$store
          .dispatch('NEXT_STATE')
          .then(() => this.$router.replace('/home'));
      });
  },
};
</script>
<style scoped lang="scss">
.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  * {
    margin-bottom: 10%;
    color: $foreground-secondary;
  }
}
</style>

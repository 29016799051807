import States from './states.enum';

const NumberFromStates = {
  [States.CAVE]: 'cueva',

  [States.PHASE_0]: '0',

  [States.PHASE_1]: '1',

  [States.PHASE_2]: '2',

  [States.PHASE_3]: '3',

  [States.PHASE_4]: '4',

  [States.PHASE_5]: '5',

  [States.END]: 'fin',
};

export default NumberFromStates;

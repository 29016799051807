<template>
  <v-app>
    <chat-component ref="chatModal"></chat-component>
    <Menu :header="header" />

    <transition name="fade">
      <Loader :activeLoader="showLoader"></Loader>
    </transition>

    <VideoComponent v-if="showVideo"
                    :src="videoSource"></VideoComponent>
    <v-navigation-drawer class="pa-4"
                         width="300"
                         v-model="header.showDrawable"
                         :disableRouteWatcher="true"
                         :disable-resize-watcher="true"
                         clipped
                         app>
      <v-list dense>
        <v-list-item>
          <v-list-item-content>
            <v-col cols="3">
              <v-list-item-title class="title">
                <img src="/images/icono_charlie.svg"
                      alt="Icono de Charlie">
              </v-list-item-title>
            </v-col>
            <v-col cols="8" class="ml-4">
              <v-list-item-subtitle>
                <h6>{{ this.$store.state.user.name }}</h6>
                <h6>{{ this.$store.state.user.firstSurname }}
                    {{ this.$store.state.user.secondSurname }}</h6>
                <p class="mt-2">Versión: {{ appVersión }}</p>
              </v-list-item-subtitle>
            </v-col>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <!-- Mi perfil -->
        <v-list-item link
                    @click="goTo('/account')">
          <v-list-item-action>
            <eva-icon name="person" fill="#2297aa" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mi perfil</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Recursos -->
        <v-list-item link
                    @click="goTo('/resources')">
          <v-list-item-action>
            <eva-icon name="briefcase" fill="#2297aa" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Recursos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Mi viaje -->
        <!--
        <v-list-item link
                    @click="goTo('/path')">
          <v-list-item-action>
            <eva-icon name="pin-outline" fill="#2297aa" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mi Viaje</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->
        <!-- Mi Mandala -->
        <v-list-item link
                    v-if="passageThroughForExercise('3')"
                    @click="goTo('/mandala')">
          <v-list-item-action>
            <eva-icon name="pie-chart-outline" fill="#2297aa" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mi Mandala</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Plan de Acción -->
        <v-list-item link
                    v-if="passageThroughForExercise('173')"
                    @click="goTo('/planning')">
          <v-list-item-action>
            <eva-icon name="checkmark-circle" fill="#2297aa" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Plan de Acción</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!--
        <v-list-item v-if="!isEndState"
                     link
                     target="_blank"
                     @click="goTo('/guide/before/signup')">
          <v-list-item-action>
            <eva-icon name="info-outline" fill="#2297aa" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Guía de Uso</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        -->
        <v-list-item link
                     v-if="isLogged()"
                     @click=logoutAction>
          <v-list-item-action>
            <eva-icon name="log-out" fill="#2297aa" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <transition name="fade" v-if="!showVideo && !showLoader">
      <v-main class="d-flex">
        <updates-banner-component />
        <router-view />
      </v-main>
    </transition>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoService from './services/video.service';
import LoaderService from './services/loader.service';
import MenuService from './services/menu.service';
import UpdatesBannerComponent from './components/updates-banner-component.vue';
import VideoComponent from './components/VideoComponent.vue';
import Loader from './components/Loader.vue';
import Menu from './components/Menu.vue';
import States from './model/states.enum';
import TextFromStates from './model/text_from_states.model';
import auth from './mixins/auth';
import ChatComponent from './views/ChatComponent.vue';

export default {
  name: 'app-container',
  components: {
    UpdatesBannerComponent,
    VideoComponent,
    Loader,
    Menu,
    ChatComponent,
  },
  mixins: [auth],
  data: () => ({
    appVersión: process.env.APP_VERSION.trim(),
    drawer: null,
    disableResizableDrawer: true,
    header: {
      title: '',
      showBurger: true,
      showBack: false,
      show: true,
      showDrawable: false,
    },
    showLoader: false,
    showVideo: false,
    videoSubscription: null,
    loaderSubscription: null,
    menuObservable: null,
    videoSource: null,
  }),
  created() {
    this.videoSubscription = VideoService.getVideo();
    this.loaderSubscription = LoaderService.getLoader();
    this.menuObservable = MenuService.getMenuObservable();

    this.videoSubscription = this.videoSubscription.subscribe(this.onVideoSubscription);
    this.loaderSubscription = this.loaderSubscription.subscribe(this.onLoaderSubscription);
    this.menuObservable = this.menuObservable.subscribe(this.onMenuObservable);
    this.logForAnalyticsOfNewSessionsForPhase();
    this.$store.dispatch('VERSION_HANDLER');
  },
  mounted() {
    this.$nextTick(() => {
      this.showVideoEntry();
    });
  },
  beforeDestroy() {
    this.$store.dispatch('UNSUBSCRIBE_OF_VERSION_HANDLER');
    this.videoSubscription.unsubscribe();
    this.loaderSubscription.unsubscribe();
  },
  methods: {
    showVideoEntry() {
      const isFirstTime = sessionStorage.getItem('first_time');
      if (!isFirstTime) {
        VideoService.show('logo_animado.mp4');
        sessionStorage.setItem('first_time', true);
      }
    },
    goTo(path) {
      this.header.showDrawable = false;
      this.$router.replace(path);
    },
    logoutAction() {
      this.header.showDrawable = false;
      this.logoutDispatch();
    },
    onVideoSubscription(data) {
      if (data && data.video) {
        const path = `${process.env.VUE_APP_STORAGE_PATH}${data.video}`;
        this.$store
          .dispatch('GET_TEMPORAL_URL_FROM', path)
          .then((url) => {
            MenuService.hidden();
            this.videoSource = url;
            this.showVideo = !this.showVideo;
          });
      } else {
        MenuService.show();
        this.showVideo = !this.showVideo;
      }
    },
    onLoaderSubscription(response) {
      this.showLoader = response.show;
    },
    onMenuObservable(response) {
      if (!response) {
        this.header.show = false;
      } else {
        this.header.show = true;
        this.header.title = response.title;
        if (response.action === 'menu') {
          this.header.showBurger = true;
          this.header.showBack = false;
        } else if (response.action === 'back') {
          this.header.showBurger = false;
          this.header.showDrawable = false;
          this.header.showBack = true;
          this.header.actionCallback = response.actionCallback;
        }
      }
    },
    passageThroughForExercise(number) {
      const { exercises } = this.$store.state.user;
      if (
        exercises
        && exercises.find((item) => item.exercise === number)
      ) {
        return true;
      }
      return false;
    },
    logForAnalyticsOfNewSessionsForPhase() {
      if (!this.isLogged()) return;

      const { flowState } = this.$store.getters.getUser;
      const requiredStatus = [
        States.INTRO, States.PHASE_0, States.PHASE_1,
        States.PHASE_2, States.PHASE_3, States.PHASE_4,
        States.PHASE_5, States.CAVE, States.DOWNLOAD_LOGBOOK,
        States.END,
      ];
      if (requiredStatus.includes(flowState)) {
        const TextPhase = TextFromStates[flowState].toLowerCase;
        const eventName = `new session in phase: ${TextPhase}`;
        this.$store.dispatch('LOG_EVENT', { eventName });
      }
    },
  },
  computed: {
    isEndState() {
      const { flowState } = this.$store.state.user;
      return flowState === States.END;
    },
    ...mapGetters([
      'isLogged',
    ]),
  },
};
</script>

<style lang="scss" scoped>
#app, main {
  background-color: $background;
}
.disabled {
  color: $divider;
}
</style>

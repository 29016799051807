import { render, staticRenderFns } from "./AudioComponent.vue?vue&type=template&id=38d1b066&scoped=true"
import script from "./AudioComponent.vue?vue&type=script&lang=js"
export * from "./AudioComponent.vue?vue&type=script&lang=js"
import style0 from "./AudioComponent.vue?vue&type=style&index=0&id=38d1b066&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d1b066",
  null
  
)

export default component.exports
import { fromEvent } from 'rxjs';
import { set } from 'lodash';
import store from '../plugins/store';

function dispatchDropOutPointLog(currentStep) {
  const configuration = {
    eventName: `drop out point: ${currentStep}`,
  };
  store.dispatch('LOG_EVENT', configuration);
}

function onBeforeUnload(event) {
  event.preventDefault();
  set(event, 'returnValue', '');

  const { isLogged, getUser } = store.getters;

  if (!isLogged()) return;

  const { currentRoute } = getUser;
  const { currentStep } = currentRoute;

  if (!currentStep) return;

  dispatchDropOutPointLog(currentStep);
}

fromEvent(window, 'beforeunload')
  .subscribe(onBeforeUnload);

import firebase from 'firebase';

const FireStorageModule = {
  actions: {
    GET_TEMPORAL_URL_FROM: (context, path) => {
      const ref = firebase.storage().refFromURL(path);
      return ref.getDownloadURL();
    },
  },
};

export default FireStorageModule;

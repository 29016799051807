<template>
  <v-row justify="center">
    <v-col :cols="12" :sm="10" :md="9">
      <div v-if="dataset.length === 0"
           class="empty-view animated jackInTheBox">
        <img src="../../assets/images/empty.png"/>
        <h6>No hay imagenes</h6>
      </div>
      <div class="list-grid animated bounceInUp"
          v-for="(item, idx) in dataset"
          :key="idx">
        <div class="item-layout">
          <img class="mx-w" :src="buildUrlFrom(item.url)" />
          <span>{{item.title}}</span>
          <eva-icon name="download" fill="#2297aa" @click="download(item.url)" />
        </div>
        <v-divider></v-divider>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import download from '../../mixins/download';
import States from '../../model/states.enum';

export default {
  name: 'ImageResources',
  mixins: [download],
  data: () => ({
    dataset: [],
  }),
  mounted() {
    const { exercises, flow } = this.$store.state.user;
    const documents = ['1', '5', '45', '241', '245', '290'];
    if (exercises && exercises.length) {
      const result = _.chain(exercises)
        .filter((item) => _.includes(documents, item.exercise))
        .value();
      for (let i = 0; i < result.length; i += 1) {
        const item = result[i];

        if (item.exercise === '1') {
          const [first] = item.answer[5].answer;
          let url = first;
          if (!url.includes('images')) url = `images/${first}`;
          const value = {
            url,
            title: 'SIMBOLO',
          };
          this.dataset.push(value);
        }

        if (item.exercise === '5') {
          const value = [
            {
              url: 'images/guardians/creador.png',
              title: 'GUARDIAN CREADOR',
            },
            {
              url: 'images/guardians/destructor.png',
              title: 'GUARDIAN DESTRUCTOR',
            },
            {
              url: 'images/guardians/mantenedor.png',
              title: 'GUARDIAN MANTENEDOR',
            },
          ];
          this.dataset.push(...value);
        }

        if (item.exercise === '45') {
          const image = this.findImageSelectedInActivity('45', item);

          if (!image) {
            this.dataset.push({
              url: 'images/guardians/visionary.png',
              title: 'GUARDIAN FORTALEZA',
            });
            this.dataset.push({
              url: 'images/guardians/praiser.png',
              title: 'GUARDIAN OPORTUNIDAD',
            });
          } else this.dataset.push(image);
        }

        if (item.exercise === '241') {
          const value = [
            {
              url: 'images/demons/debilidad.png',
              title: 'DEBILIDAD',
            },
            {
              url: 'images/demons/amenaza.png',
              title: 'AMENAZA',
            },
          ];
          this.dataset.push(...value);
        }
      }
    }

    if (flow.includes(States.PHASE_4_LISTEN_HISTORY)) {
      const value = [
        {
          url: 'images/totems/tigre.png',
          title: 'TIGRE',
        },
        {
          url: 'images/totems/zorro.png',
          title: 'ZORRO',
        },
        {
          url: 'images/totems/hormiga.png',
          title: 'HORMIGA',
        },
        {
          url: 'images/totems/pegaso.png',
          title: 'PEGASO',
        },
        {
          url: 'images/totems/pez.png',
          title: 'PEZ',
        },
        {
          url: 'images/totems/halcon.png',
          title: 'HALCON',
        },
        {
          url: 'images/totems/pantera.png',
          title: 'PANTERA',
        },
      ];
      this.dataset.push(...value);
    }

    if (flow.includes(States.PHASE_3_LISTEN_HISTORY)) {
      const value = [
        {
          url: 'images/gargoyle/posibilidad.png',
          title: 'GARGOLA POSIBILIDAD',
        },
        {
          url: 'images/gargoyle/capacidad.png',
          title: 'GARGOLA CAPACIDAD',
        },
        {
          url: 'images/gargoyle/merecimiento.png',
          title: 'GARGOLA MERECIMIENTO',
        },
      ];
      this.dataset.push(...value);
    }
  },
  methods: {
    findImageSelectedInActivity(idxExercise, exercises) {
      const { answer } = exercises;
      if (
        answer
        && answer.length > 0
      ) {
        const item = _.find((answer), (exercise) => exercise.type === 'IMAGENES');
        if (item.answer) {
          const URL_IMAGE = 'https://www.charlieelemprendedor.com/pwa_charlie_assets/';
          let [url] = item.answer;
          if (url.includes(URL_IMAGE)) url = url.replace(URL_IMAGE, 'images/');
          return {
            url,
            title: item.title,
          };
        }
      }
      return null;
    },
    buildUrlFrom(path) {
      return `${process.env.BASE_URL}${path}`;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  .list-grid {
    display: flex;
    flex-basis: 1;
    flex-direction: column;
    .item-layout {
      padding: .2em;
      display: flex;
      flex-basis: 1;
      justify-content: space-between;
      align-items: center;
    }
  }

  .flex-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .mx-w {
    max-width: 60px;
  }

  .empty-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 30%;
    }
  }
}
</style>

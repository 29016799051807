<template>
  <v-app-bar app light clipped-left v-if="header.show">
    <v-btn v-if="header.showBurger"
           @click="() => header.showDrawable = !header.showDrawable"
           color="primary"
           class="mr-2"
           outlined
           large>MENÚ</v-btn>
    <a v-else-if="header.showBack">
        <img src="../assets/images/icons/back@2x.png"
              @click="goBack()" />
    </a>
    <v-toolbar-title><h1>{{ header.title }}</h1></v-toolbar-title>
  </v-app-bar>
</template>

<script>
export default {
  name: 'cmenu',
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goBack() {
      if (this.header.actionCallback) {
        this.header.actionCallback();
      }
    },
  },
};
</script>

<style scoped lang="scss">
  img {
    width: 30px;
  }
  h1 {
    padding-bottom: 0.3em;
    font-size: 1.75rem;
    justify-self: flex-end;
    margin-right: 8%;
  }
</style>

<template>
  <div class="input-group">
    <label v-if="title && title != ''">{{title}}</label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'InputGroup',
  props: {
    title: String,
  },
};
</script>

<style scoped lang="scss">
div.input-group {
  width: 100%;
  display: flex;
  flex-direction: column;

  label {
    text-align: start;
    margin-bottom: 15px;
  }

  input, select {
    width: 100%;
    height: 40px;
    padding-left: .5em;
    padding-right: .5em;
    font-size: 1.2em;
    background-color: #EFEFEF;
    border-style: none;
    outline: none;
  }

  select {
    /* Positions background arrow image */
    background-image:
     url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAz'
          +'G8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJ'
          +'lz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbM'
          +'AckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo'
          +'5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC');
    background-repeat: no-repeat;
    background-position: 95% center;
  }

  textarea {
    padding-left: .5em;
    padding-right: .5em;
    font-size: 1.2em;
    background-color: #EFEFEF;
    border-style: none;
    outline: none;
  }

  div.divider {
    height: 1px;
    background-color: var(--v-primary-base);
  }
}
</style>

<template>
  <div>
    <input v-model="valData"
           v-on:input="valData = $event.target.value"
           :placeholder="placeholder"
           :name="name"
           :type="type"
           :disabled="disabled"
           v-bind:min="min"
           v-bind:max="max"
           autocomplete="off">
    <div class="divider"></div>
    <div class="bold error--text" v-if="!success">
      {{ errorMsg }}
    </div>
  </div>
</template>

<script>
import Validator from 'validatorjs';

require('validatorjs/dist/lang/es');

Validator.useLang('es');

export default {
  name: 'InputValidatorComponent',
  props: {
    value: {
      default: null,
    },
    label: {
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      default: 'text',
    },
    name: {
      default: 'element',
    },
    rules: {
      default: '',
    },
    disabled: {
      default: false,
    },
    min: {
      type: Number,
      required: false,
    },
    max: {
      type: Number,
      required: false,
    },
    errorMessages: {
      default: null,
    },
  },
  data() {
    return {
      valData: null,
      success: false,
      errorMsg: '',
    };
  },
  created() {
    if (this.value && this.value !== '') {
      this.valData = this.value;
    }
  },
  watch: {
    valData(value) {
      this.validation(value);
    },
  },
  methods: {
    validation(value) {
      const obj = {};
      const rules = {};

      obj[this.name] = value;
      rules[this.name] = this.rules;

      const validation = new Validator(obj, rules);

      if (validation.fails()) {
        this.$emit('error', this.name, validation.errors.first(this.name));
        this.errorMsg = validation.errors.first(this.name);
        this.errorMsg = this.errorMsg.replace(this.name, this.label);
      }

      this.$emit('status-change', this.name, validation.passes());
      this.success = validation.passes();

      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="scss">
  input {
      width: 100%;
      height: 52px;
      padding-left: .5em;
      padding-right: .5em;
      font-size: 1.2em;
      background-color: #EFEFEF;
      border-style: none;
      outline: none;
  }
  ::-webkit-credentials-auto-fill-button {
      visibility: hidden;
      display: none !important;
      pointer-events: none;
      position: absolute;
      right: 0;
  }
  div.divider {
    height: 1px;
    background-color: gray;
  }
</style>

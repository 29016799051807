<template>
  <section
    class="timeline__item">
    <span
      class="timeline__item__dot"
      :class="{
        'timeline__item__dot--primary': dotColor === 'primary',
        'timeline__item__dot--secondary': dotColor === 'secondary',
        'timeline__item__dot--error': dotColor === 'error',
      }"
    />
    <h4 class="timeline__item__title" v-html="title"/>
  </section>
</template>

<script>
export default {
  name: 'TimelineItem',
  props: {
    title: {
      type: String,
      default: 'Unknow',
    },
    dotColor: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline__item {
  display: flex;
  align-items: flex-end;
  height: 60px;
  &::before {
    content: '';
    width: 10px;
    height: 100%;
    background-color: rgb(231, 231, 231);
  }
  &__title {
    position: relative;
    margin: 0;
    font-size: 15px;
    font-weight: 500;
  }
  &__dot {
    width: 20px;
    height: 20px;
    position: relative;
    left: -15.5px;
    top: 2px;
    z-index: 1;
    border-radius: 50%;
    &--primary {
      background-color: rgb(231, 231, 231);
      border: 5px solid #2297aa;
    }
    &--secondary {
      background-color: rgb(231, 231, 231);
      border: 5px solid #efbd50;
    }
    &--error {
      background-color: rgb(231, 231, 231);
      border: 5px solid #ff6400;
    }
  }
}
</style>

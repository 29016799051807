<template>
  <component v-if="guideData && activeComponent"
              :is="activeComponent"
              :guideData="guideData"
              @ended="ended"/>
</template>

<script>
import { mapGetters } from 'vuex';
import guides from '../../model/guides.enum';
import GuideType from './guide-type';
import MenuService from '../../services/menu.service';

export default {
  name: 'guide-view',
  components: {
    'guide-basic-component': () => import('./components/guide-basic-component.vue'),
    'guide-in-card-component': () => import('./components/guide-in-card-component.vue'),
    'guide-with-image-component': () => import('./components/guide-with-image-component.vue'),
  },
  data: () => ({
    guides,
    guideData: null,
    activeComponent: 'guide-basic-component',
  }),
  created() {
    MenuService.hidden();
    this.$store
      .dispatch('GET_GUIDE')
      .then(this.loadGuideData);
  },
  computed: {
    ...mapGetters([
      'isLogged',
    ]),
  },
  methods: {
    loadGuideData(document) {
      const { guide, guideId } = document;
      this.guideData = {
        guide,
        guideId,
      };
      this.activeComponent = this.getActiveComponent();
    },
    getActiveComponent() {
      if (this.showCardSyndbols()) return GuideType.GuideInCard;
      if (this.showCharlieImage()) return GuideType.GuideWithImage;
      return GuideType.GuideBasic;
    },
    showCharlieImage() {
      const options = [
        this.guides.AFTER_INTRO,
      ];
      const { guideId } = this.guideData;
      return options.includes(guideId);
    },
    showCardSyndbols() {
      const options = [
        this.guides.BEFORE_REGISTER,
        this.guides.AFTER_AWARD_P0,
        this.guides.AFTER_AWARD_P1,
        this.guides.AFTER_AWARD_P2,
        this.guides.AFTER_AWARD_P3,
        this.guides.AFTER_AWARD_P4,
        this.guides.AFTER_AWARD_P5,
        this.guides.END,
      ];
      const { guideId } = this.guideData;
      return options.includes(guideId);
    },
    ended() {
      if (this.isLogged()) {
        this.$store
          .dispatch('NEXT_STATE')
          .then(() => this.$router.replace('/home'));
      } else if (this.guides.BEFORE_REGISTER === this.guideData.guideId) {
        this.$router.replace('/signup');
      }
    },
  },
};
</script>

/* eslint-disable no-unused-vars */
import firebase from 'firebase';

const isNewVersion = (localVersion, remoteVersion) => {
  if (!localVersion) return false;
  if (!remoteVersion) return false;

  const localVersionSplited = localVersion.split('.').map(Number);
  const remoteVersionSplited = remoteVersion.split('.').map(Number);

  if (!localVersionSplited) return false;
  if (!remoteVersionSplited) return false;

  const [localMajor, localMinor, localPatch] = localVersionSplited;
  const [remoteMajor, remoteMinor, remotePatch] = remoteVersionSplited;

  if (remoteMajor > localMajor) return true;
  if (remoteMinor > localMinor) return true;
  if (remotePatch > localPatch) return true;

  return false;
};

const VersionHandlerModule = {
  state: {
    unsubscribe: null,
    newUpdate: false,
    update: {
      exists: false,
      logoutRequired: false,
    },
  },
  mutations: {
    SET_UNSUBSCRIBE: (state, unsubscribe) => {
      state.unsubscribe = unsubscribe;
    },
    ACTIVE_NEW_UPDATE: (state, payload) => {
      state.update = payload;
    },
    DESACTIVE_NEW_UPDATE: (state) => {
      state.update = {
        exists: false,
        logoutRequired: false,
      };
    },
  },
  getters: {
    currentVersion: (state) => () => process.env.APP_VERSION.trim(),
    existsNewVersion: ({ update }) => update.exists,
  },
  actions: {
    UPDATE_VERSION: ({ commit }) => {
      commit('DESACTIVE_NEW_UPDATE');
      window.location.reload();
    },
    VERSION_HANDLER: ({ dispatch, commit, getters }) => {
      const PATH_DOCUMENT = 'version/app';
      const unsubscribe = firebase
        .firestore()
        .doc(PATH_DOCUMENT)
        .onSnapshot(
          (snapshot) => {
            const { logoutRequired, version } = snapshot.data();
            const existNewVersion = isNewVersion(getters.currentVersion(), version);
            if (!existNewVersion) return;
            const payload = {
              exists: true,
              logoutRequired,
            };
            commit('ACTIVE_NEW_UPDATE', payload);
          },
          () => dispatch('CHECK_WITH_REQUEST'),
        );
      commit('SET_UNSUBSCRIBE', unsubscribe);
    },
    UNSUBSCRIBE_OF_VERSION_HANDLER: ({ state }) => {
      const { unsubscribe } = state;
      if (unsubscribe) unsubscribe();
    },
    CHECK_WITH_REQUEST: async ({ commit, getters }) => {
      const PATH_DOCUMENT = 'version/app';
      const { data } = await firebase.firestore().doc(PATH_DOCUMENT).get();
      const { logoutRequired, version } = data();
      const existNewVersion = isNewVersion(version, getters.currentVersion());
      if (!existNewVersion) return;
      const payload = {
        exists: true,
        logoutRequired,
      };
      commit('ACTIVE_NEW_UPDATE', payload);
    },
  },
};

export default VersionHandlerModule;

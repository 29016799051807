export default {
  methods: {
    logoutDispatch() {
      this.$store
        .dispatch('SIGNOUT_USER')
        .then(() => {
          this.$router.replace('/access');
        });
    },
    onlyTheFirebaseSignoutDispatch() {
      this.$store
        .dispatch('ONLY_THE_FIREBASE_SIGNOUT')
        .then(() => this.$router.replace('/access'));
    },
  },
};

<template>
  <v-dialog v-model="dialog" fullscreen color>
    <v-toolbar color="white">
      <h5>CHARLIE</h5>
      <v-spacer></v-spacer>
      <v-btn icon @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <div class="chat__container">
      <div class="chat__messages">
        <div v-for="(message, index) in messages" class="fade-in" :key="index">
          <h6 v-if="message.role === 'assistant'">Charlie</h6>
          <h6 v-else>Tú</h6>
          <markdown-component :source="message.content" />
        </div>
      </div>
      <div class="chat__message-input">
        <div class="chat__message-input__container">
          <div class="chat__message-input__input">
            <v-text-field
              label="Escribe tu mensaje aquí"
              v-model="newMessage"
              :disabled="loading"
              autofocus
            ></v-text-field>
          </div>
          <div class="chat__message-input__button">
            <v-btn icon :disabled="loading" @click="sendMessage">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <img src="../assets/images/characters/charlie/charlie_welcome.png"
          class="charlie-welcome send-to-background animated bounceInUp" />
    </div>
</v-dialog>
</template>

<script>
import axios from 'axios';
import MarkdownComponent from '../components/markdown-component.vue';
import chatService from '../services/chat.service';

const notValidMessage = 'Lo siento, no puedo responder a eso.';

export default {
  components: {
    MarkdownComponent,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      newMessage: '',
      messages: [],
      chatSubscription: null,
      context: { noGreetingRequired: false },
    };
  },
  created() {
    this.chatSubscription = chatService.getChatObservable().subscribe((state) => {
      if (state.notify) {
        this.dialog = state.show;
        if (!this.messages.find((message) => message.content === state.message)) {
          this.messages.push({ content: state.message, role: 'assistant' });
        }
        this.scrollToLastMessage();
      } else if (state.show) {
        this.dialog = state.show;
        this.context = { ...this.context, ...state.context };
      }
    });
  },
  destroyed() {
    if (this.chatSubscription) {
      this.chatSubscription.unsubscribe();
    }
  },
  methods: {
    scrollToLastMessage() {
      this.$nextTick(() => {
        const container = document.querySelector('.chat__messages');
        if (container.lastElementChild) {
          const lastMessagePosition = container.lastElementChild.offsetTop;
          container.scrollTop = lastMessagePosition;
        }
      });
    },
    async sendMessage() {
      if (this.newMessage.trim() !== '') {
        try {
          this.loading = true;
          this.messages.push({ content: this.newMessage, role: 'user' });
          const baseURL = 'https://europe-west2-pwa-charlie.cloudfunctions.net/charlieAssistantFunction';
          // const baseURL = 'http://localhost:5001';
          const url = `${baseURL}/api/chat`;
          const body = {
            messages: this.messages.slice(-3),
            context: this.context,
          };
          const response = await axios.post(url, body);
          this.newMessage = '';
          let answer = response.data.response;
          if (answer === 'no') {
            answer = notValidMessage;
          }
          const { user } = this.$store.state;
          answer = answer.replace(/\$\{writename\}/g, user.name);
          this.messages.push({ content: answer, role: 'assistant' });
        } catch (error) {
          console.error(error);
        } finally {
          this.scrollToLastMessage();
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped>
.chat__container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 54px);
  background-color: #fff;
  padding: 1rem 0rem 0rem 0rem;
}

.chat__messages {
  z-index: 2;
  width: 100%;
  padding: 0rem 1rem;
  flex: 1;
  overflow-y: scroll;
}

.chat__messages p {
  margin: 0;

}

.chat__message-input {
  background-color: white;
  z-index: 2;
  padding: 1rem 0.5rem;
  width: 100%;
}

.chat__message-input__container {
  padding: 0.5rem 1rem;
  border: 1px solid #707070;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chat__message-input__input {
  width: 100%;
}

.chat__message-input__button {
}

.messages__container {
  height: 100%;
  overflow-y: scroll;
}

img.charlie-welcome {
  opacity: 0.2;
  position: absolute;
  bottom: 5%;
  right: 0%;
  max-width: 350px;
}

@include sm-portrait {
  img.charlie-welcome {
    visibility: hidden;
  }
}
@include md {
  img.charlie-welcome {
    visibility: visible;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s;
}
</style>

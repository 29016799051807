<template>
  <component v-if="guideData && activeComponent"
              :is="activeComponent"
              :guideData="guideData"
              title="Guía de Uso"
              @ended="ended"/>
</template>

<script>
import { mapGetters } from 'vuex';
import guides from '../../model/guides.enum';
import GuideType from './guide-type';
import MenuService from '../../services/menu.service';

export default {
  name: 'use-guide-view',
  components: {
    'guide-in-card-component': () => import('./components/guide-in-card-component.vue'),
  },
  data: () => ({
    guides,
    guideData: null,
    activeComponent: GuideType.GuideInCard,
  }),
  created() {
    MenuService.hidden();
    this.$store
      .dispatch('GET_USE_GUIDE')
      .then(this.loadGuideData);
  },
  computed: {
    ...mapGetters([
      'isLogged',
    ]),
  },
  methods: {
    loadGuideData(document) {
      const { guide, guideId } = document;
      this.guideData = {
        guide,
        guideId,
      };
    },
    ended() {
      const Path = this.getPath();
      this.$router.replace(Path);
    },
    getPath() {
      return this.isLogged() ? '/home' : '/signup';
    },
  },
};
</script>

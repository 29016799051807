<template>
  <v-row  align="center"
          justify="center"
          class="design"
          :class="{
              primary: isPrimary,
              secondary: isSecondary,
              'secondary-variant': isSecondaryVariant
          }">
  <slot></slot>
  </v-row>
</template>
<script>
import random from 'lodash/random';

export default {
  name: 'ContainerSimbols',
  props: {
    color: {
      default: 'primary',
      type: String,
    },
    ramdomColor: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      colorValue: '',
    };
  },
  computed: {
    isPrimary() {
      return (this.ramdomColor && this.colorValue === 'primary')
             || (!this.ramdomColor && this.color === 'primary');
    },
    isSecondary() {
      return (this.ramdomColor && this.colorValue === 'secondary')
             || (!this.ramdomColor && this.color === 'secondary');
    },
    isSecondaryVariant() {
      return (this.ramdomColor && this.colorValue === 'secondary-variant')
             || (!this.ramdomColor && this.color === 'secondary-variant');
    },
  },
  created() {
    if (this.ramdomColor) {
      const colors = ['secondary', 'primary', 'secondary-variant'];
      const ramdom = random(0, colors.length - 1);
      const color = colors[ramdom];
      this.colorValue = color;
    }
  },
};
</script>
<style scoped lang="scss">
.design {
  height: 100%;
  background-image: url('~@/assets/images/background/simbolos.png') !important;
  background-repeat: space;
  background-size: contain !important;
}

button {
  background-color: $foreground-secondary !important;
  color: $surface !important;
}

.primary {
  background-color: $primary !important;
}

.secondary {
  background-color: $secondary !important;
}

.secondary-variant {
  background-color: $error !important;
}
@include sm-landscape {
  .design {
    background-size: auto !important;
  }
}
</style>

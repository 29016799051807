<template>
<v-row justify="center">
  <v-progress-linear v-model="getValueFromProgressInExercises"
                     color="secondary"
                     height="25">
    {{ Math.ceil(getValueFromProgressInExercises) }}%
  </v-progress-linear>
  <v-col cols="10" md="6">
    <div class="container">
      <div class="main"
          v-if="currentExercise !==  null
                && currentExercise !==  undefined">

        <!-- title -->
        <div class="mt-10">
          <h5>{{ currentExercise.title }}</h5>
        </div>
        <!-- text -->
        <markdown-component :source="currentExercise.text" />

        <AdjetivesSumary v-if="activeSummary"
                        class="mt-5 mb-5"
                        :dataset="summary" />
        <!-- content -->
        <div v-if="currentExercise.type === 'SCORE_TABLE'">
          <ScoreTable v-on:next="nextStep" :exercise="currentExercise"></ScoreTable>
        </div>

        <div v-else-if="currentExercise.type === 'ADJECTIVES_SUMMARY'">
          <AdjetivesSumary :dataset="getAdjetivesDataset()"></AdjetivesSumary>
        </div>

        <div v-else-if="currentExercise.type === 'MANDALA'">
          <ScoreTableRadarChart :exercise="currentExercise"></ScoreTableRadarChart>
        </div>

        <div v-else-if="currentExercise.type === 'SELECT_LIST' && currentExercise.rules">
          <SelectableList v-on:next="nextStep"
                          :dataset="currentExercise.dataset"
                          :selectedItems="currentExercise.answer"
                          :max="currentExercise.rules.max"
                          :min="currentExercise.rules.min" />
        </div>

        <div v-else-if="currentExercise.type === 'TOTEM_AND_DEBILITY'">
          <TotemVSDebility :totem="getTotemSelected()" />
        </div>

        <div class="mt-10" v-else-if="currentExercise.type === 'IMAGENES' && currentExercise.rules">
          <list-images-component v-on:next="nextStep"
                                :dataset="currentExercise.dataset"
                                :margin="20"
                                :max="currentExercise.rules.max"
                                :min="currentExercise.rules.min" />
        </div>

        <div v-else-if="currentExercise.type === 'INPUT'">
          <InputGroup class="mt-5" title="Escribe tu respuesta aquí">
            <textarea v-model="currentExercise.dataset"
                      type="text"
                      rows="10"
                      data-cy="textarea-exercise"/>
            <div class="divider"></div>
          </InputGroup>
        </div>

        <div v-else-if="currentExercise.type === 'IMAGE_WITH_INPUT'">
          <img :src="getImage(currentExercise.dataset.image)" :alt="currentExercise.title">
          <InputGroup class="mt-5" title="Escribe tu respuesta aquí">
            <textarea v-model="currentExercise.dataset.input"
                      type="text"
                      rows="10"
                      data-cy="textarea-exercise"/>
            <div class="divider"></div>
          </InputGroup>
        </div>

        <div  v-else-if="showAudioComponent && currentExercise.type === 'AUDIO'">
          <AudioComponent :dataset="currentExercise.audioURL"
                          v-on:next="nextStep"></AudioComponent>
        </div>

        <div v-else-if="currentExercise.type === 'SUEÑOS'">
          <Dreams v-on:next="nextStep"></Dreams>
        </div>

        <div v-else-if="currentExercise.type === 'METAPHORS'">
          <Metaphors v-on:next="nextStep" :dataset="currentExercise.dataset"></Metaphors>
        </div>

        <div v-else-if="currentExercise.type === 'IDENTIFY_YOUR_GARGOYLES'">
          <IdentifyYourGargoyles v-on:next="nextStep"
                                :dataset="currentExercise.dataset"></IdentifyYourGargoyles>
        </div>

        <div class="d-flex mt-10 justify-space-around"
             v-else-if="currentExercise.type === 'DOCUMENT'">
          <v-btn color="primary" @click="() => {
              this.downloadDocuments(currentExercise.dataset);
            }">DESCARGAR</v-btn>
          <v-btn color="primary" @click="nextStep">CONTINUAR</v-btn>
        </div>

        <!-- TODO: REFACTOR TYPE IMAGENES+DOCUMENT -->
        <div v-else-if="currentExercise.type === 'IMAGENES+DOCUMENT'
                                    && currentExercise.rules">
          <list-images-component :dataset="currentExercise.dataset"
                                :margin="20"
                                :max="currentExercise.rules.max"
                                :min="currentExercise.rules.min" />
          <div class="mt-10 v actions">
            <v-btn color="primary" @click="() => {
              let [image] = currentExercise.dataset;

              if (image.includes('symbols') && !image.includes('images')) {
                image = `images/${image}`;
              }
              // this.download(image);
              this.nextStep(null);
            }">DESCARGAR</v-btn>
          </div>
        </div>

        <div v-else-if="currentExercise.type === 'ACTOR'">
          <p class="body-1">
            {{ currentExercise.dataset[this.exercise[currentStep-1].answer[0]] }}
          </p>
        </div>
        <div v-else-if="currentExercise.type === 'ACTION_PLAN'">
          <Planning v-on:next="nextStep"/>
        </div>

        <div v-else-if="currentExercise.type === 'DOWNLOAD'" class="actions mt-10 mb-10">
          <v-btn color="primary"
                x-large
                @click="() => {
                  this.download(currentExercise.dataset);
                  this.nextStep(null);
                }">DESCARGAR</v-btn>
        </div>

        <div v-else-if="currentExercise.type === 'NONE'"></div>

        <div v-else>
          EJERCICIO NO DISPONIBLE
        </div>

        <!-- actions -->
        <div v-if="currentExercise.type !== 'SCORE_TABLE'
                  && currentExercise.type !== 'SELECT_LIST'
                  && currentExercise.type !== 'AUDIO'
                  && currentExercise.type !== 'SUEÑOS'
                  && currentExercise.type !== 'METAPHORS'
                  && currentExercise.type !== 'DOCUMENT'
                  && currentExercise.type !== 'IMAGENES+DOCUMENT'
                  && currentExercise.type !== 'IMAGENES'
                  && currentExercise.type !== 'ACTION_PLAN'
                  && currentExercise.type !== 'IDENTIFY_YOUR_GARGOYLES'
                  && currentExercise.type !== 'DOWNLOAD'"
            class="actions mt-10 mb-10">
          <v-btn @click="nextStep"
                color="primary"
                x-large
                data-cy="btn-next-exercise">{{ button }}</v-btn>
        </div>
      </div>
    </div>
    <cover-component :active="charlieCover"></cover-component>
  </v-col>

  <div class="chat-button" v-if="currentExercise.activateAssistant">
    <ChatNotification :isActive="chatNotification.isActive"
                      :message="chatNotification.message" />
    <v-btn @click="openChat"
           color="#FAECCC"
           elevation="2"
           large
           fab>
      <img src="../../assets/images/charlie_icon.png"
           alt="Botón abrir Chat"
           width="32px"
           height="32px" />
    </v-btn>
  </div>
</v-row>
</template>

<script>
import _ from 'lodash';
import MarkdownComponent from '../../components/markdown-component.vue';
import TextFromChallenges from '../../model/text_from_challenges.model';
import TextFromPhases from '../../model/text_from_phases.model';
import MenuService from '../../services/menu.service';
import InputGroup from '../../components/InputGroup.vue';
import ScoreTable from '../../components/content_exercises/ScoreTable.vue';
import AdjetivesSumary from '../../components/content_exercises/AdjetivesSumary.vue';
import Planning from '../../components/content_exercises/Planning.vue';
import ScoreTableRadarChart from '../../components/content_exercises/ScoreTableRadarChart.vue';
import SelectableList from '../../components/content_exercises/SelectableList.vue';
import AudioComponent from '../../components/content_exercises/AudioComponent.vue';
import ListImagesComponent from '../../components/content_exercises/ListImagesComponent.vue';
import Dreams from '../../components/content_exercises/Dreams.vue';
import Metaphors from '../../components/content_exercises/Metaphors.vue';
import IdentifyYourGargoyles from '../../components/content_exercises/IdentifyYourGargoyles.vue';
import TotemVSDebility from '../../components/content_exercises/TotemVSDebility.vue';
import CoverComponent from './components/cover-component.vue';
import ChatNotification from '../../components/ChatNotification.vue';
import download from '../../mixins/download';
import scroll from '../../mixins/scroll';
import chatService from '../../services/chat.service';

export default {
  name: 'Exercise',
  mixins: [download, scroll],
  components: {
    MarkdownComponent,
    ScoreTable,
    InputGroup,
    SelectableList,
    AudioComponent,
    ListImagesComponent,
    ScoreTableRadarChart,
    Dreams,
    Metaphors,
    AdjetivesSumary,
    Planning,
    IdentifyYourGargoyles,
    TotemVSDebility,
    CoverComponent,
    ChatNotification,
  },
  props: {
    phase: {
      type: String,
      required: true,
    },
    challenge: {
      type: String,
      required: true,
    },
    activityIdx: {
      type: String,
      required: true,
    },
    activity: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    showAudioComponent: false,
    charlieCover: false,
    idxExercise: null,
    exercise: null,
    currentExercise: null,
    currentStep: 0,
    button: 'SIGUIENTE',
    activeSummary: false,
    summary: null,
    momentOfEntryIntoExercise: null,
    chatNotification: {
      isActive: false,
      message: '',
    },
  }),
  created() {
    this.exercise = this.activity;
    this.idxExercise = this.activityIdx;
    this.chatSubscription = chatService.getChatObservable().subscribe((state) => {
      if (state.notify) {
        this.chatNotification.isActive = true;
        this.chatNotification.message = state.message;
        setTimeout(() => {
          this.chatNotification.isActive = false;
        }, 10000);
      }
    });
    this.assignCurrentStep();
  },
  beforeMount() {
    this.setMomentOfEntryIntoExercise();
  },
  updated() {
    this.$store.commit('SET_DRAFT', this.exercise);
  },
  beforeDestroy() {
    this.momentExitOfExerciseForAnalysis();
  },
  methods: {
    goBack() {
      if (
        this.exercise
        && this.currentExercise
        && this.exercise[0] === this.currentExercise
      ) {
        this.$router.replace(`/phase/${this.phase}`);
      } else {
        this.backStep();
        this.menuAction();
      }
    },
    backStep() {
      this.currentStep -= 1;
      this.currentExercise = this.exercise[this.currentStep];
    },
    nextStep(answer) {
      this.charlieCover = true;
      window.setTimeout(this.runNextStep.bind(this, answer), 1000);
    },
    runNextStep(answer) {
      this.nextExerciseAnalysis();
      if (
        this.currentExercise.type === 'SELECT_LIST'
        || this.currentExercise.type === 'IMAGENES'
        || this.currentExercise.type === 'ACTION_PLAN'
        || this.currentExercise.type === 'SUEÑOS'
      ) {
        this.currentExercise.answer = answer;
      }

      if (this.currentExercise.type === 'IMAGENES') {
        let [path] = this.currentExercise.answer;
        if (path.includes('symbols') && !path.includes('images')) {
          path = `images/${path}`;
        }
        // this.download(path);
      }

      if (this.currentStep < (this.exercise.length - 1)) {
        this.assignNextDataset(answer);
        this.currentStep += 1;
        this.assignCurrentStep();
        this.scrollToTop();
      } else {
        this.saveExercise(this.exercise);
      }
      this.hiddenCover();
    },
    assignNextDataset(lastAnswer) {
      const nextIdx = this.currentStep + 1;
      if (
        this.exercise[nextIdx].dataset != null
        && this.exercise[nextIdx].dataset.last_answer
      ) {
        this.exercise[nextIdx].dataset = lastAnswer;
      }
    },
    assignCurrentStep() {
      this.currentExercise = null;
      this.currentExercise = this.exercise[this.currentStep];
      if (this.currentExercise.type === 'ACTION_PLAN') {
        chatService.notify('Tu plan es un eje crucial, si me necesitas te puedo ayudar');
      }
      if (this.currentExercise.dataset && this.currentExercise.dataset.referenceToAnswer) {
        const { exercise, subexercise } = this.currentExercise.dataset.referenceToAnswer;
        const itemExercise = this.$store.state
          .user.exercises
          .find((item) => parseInt(item.exercise, NaN) === exercise);
        if (this.currentExercise.type === 'IMAGE_WITH_INPUT') {
          const [first] = itemExercise.answer[subexercise].answer;
          this.currentExercise.dataset.image = first;
        } else if (this.currentExercise.type === 'IMAGENES+DOCUMENT') {
          this.currentExercise.dataset = itemExercise.answer[subexercise].answer;
          this.currentExercise.rules = itemExercise.answer[subexercise].rules;
        } else if (this.currentExercise.type === 'INPUT') {
          this.currentExercise.dataset = itemExercise.answer[subexercise].dataset;
        }
      } else if (this.currentExercise.dataset && typeof this.currentExercise.dataset.addSummaryFrom === 'number') {
        // For exercise type: IDENTIFY_YOUR_GARGOYLES
        this.activeSummary = true;
        const target = this.exercise[this.currentExercise.dataset.addSummaryFrom];
        this.summary = this.getSummary(target.dataset.items);
        this.currentExercise.dataset = '';
      }
      if (this.currentExercise === this.exercise[this.exercise.length - 1]) {
        this.button = 'GUARDAR';
      }

      if (this.currentExercise.addedForText) {
        const {
          exerciseRef,
          subExerciseRef,
          target,
          useTargetIn,
        } = this.currentExercise.addedForText;
        const exerciseTarget = this.$store.state.user.exercises
          .find((item) => parseInt(item.exercise, NaN) === exerciseRef);
        this.currentExercise[useTargetIn] += `\n\n${exerciseTarget.answer[subExerciseRef][target]}`;
      }

      if (this.currentExercise.type === 'AUDIO') {
        this.getTheAudioURL(this.currentExercise.dataset);
      }

      this.menuAction();
    },
    getTheAudioURL(url) {
      const Path = `${process.env.VUE_APP_STORAGE_PATH}${url}`;
      this.$store
        .dispatch('GET_TEMPORAL_URL_FROM', Path)
        .then(this.loadAudioURL);
    },
    loadAudioURL(temporalURL) {
      this.showAudioComponent = true;
      this.currentExercise.audioURL = temporalURL;
    },
    menuAction() {
      // Set menu action:
      let action = 'menu';
      if (this.phase !== 'intro') {
        action = 'back';
      } else if (
        this.phase === 'intro'
        && this.exercise
        && this.currentExercise
        && this.exercise[0] !== this.currentExercise) {
        action = 'back';
      }
      MenuService.show(this.getTitle(), action, this.goBack.bind(this));
    },
    saveExercise(values) {
      this.$store.dispatch('SAVE_EXERCISE', {
        phase: this.phase,
        challenge: this.challenge,
        exercise: this.idxExercise,
        answer: values,
      })
        .then(() => {
          this.$emit('next-activity');
        });
    },
    getImage(routeImage) {
      let result = routeImage;
      const IncludesImages = routeImage.includes('images');
      if (
        routeImage
        && !routeImage.includes('http')
        && !IncludesImages
      ) {
        result = `${process.env.BASE_URL}images/${routeImage}`;
      } else if (
        routeImage
        && !routeImage.includes('http')
        && IncludesImages
      ) {
        result = `${process.env.BASE_URL}${routeImage}`;
      }
      return result;
    },
    getAdjetivesDataset() {
      const dataset = [];
      for (let i = 0; i < this.exercise.length; i += 1) {
        const { answer } = this.exercise[i];
        let { text } = this.exercise[i];
        text = text.replace('Puedes elegir 3.', '').replace('**', '').replace('**', '');
        if (answer && answer instanceof Array) {
          const options = {
            title: text,
            tags: answer,
          };
          dataset.push(options);
        }
      }
      return dataset;
    },
    getTitle() {
      const ChallengeName = TextFromChallenges[this.challenge];
      const PhaseName = TextFromPhases.compact[this.phase];

      let value = 'RE-CONOCIÉNDOTE';
      if (this.phase !== 'intro') {
        value = `${PhaseName} - ${ChallengeName}`;
      }
      return value;
    },
    hiddenCover() {
      this.charlieCover = false;
    },
    getTotemSelected() {
      const { exercises } = this.$store.state.user;
      const ID_ACTION_PLAN = '290';
      const itemExercise = exercises.find((item) => item.exercise === ID_ACTION_PLAN);
      let result = '';
      if (itemExercise) {
        const path = _.last(_.last(itemExercise.answer).answer);
        result = `${process.env.BASE_URL}${path}`;
      }
      return result;
    },
    getSummary(dataset) {
      const tags = _.chain(dataset)
        .filter((item) => item.value.input < 7)
        .map((item) => item.lable.trim())
        .value();
      return [{
        title: 'Resumen',
        tags,
      }];
    },
    nextExerciseAnalysis() {
      this.inputTypeExerciseAnalysis();
    },
    inputTypeExerciseAnalysis() {
      if (
        this.currentExercise.type === 'INPUT'
        && !this.currentExercise.dataset
      ) {
        const titleLowerCase = this.currentExercise.title.toLowerCase();
        const parameters = { eventName: `empty field in ${titleLowerCase}` };
        this.$store.dispatch('LOG_EVENT', parameters);
      }
    },
    momentExitOfExerciseForAnalysis() {
      const momentEnd = Date.now();
      const durationTimestamp = momentEnd - this.momentOfEntryIntoExercise;
      const durationInSeconds = durationTimestamp / 1000;
      const titleLowerCase = this.currentExercise.title.toLowerCase();
      const parameters = {
        duration: durationInSeconds,
        measurementUnit: 'seconds',
        activity: {
          title: titleLowerCase,
          number: this.idxExercise,
          phase: this.phase,
          challenge: this.challenge,
        },
      };
      this.$store.dispatch('LOG_SESSION_DURATION_IN_EXERCISES', parameters);
    },
    setMomentOfEntryIntoExercise() {
      this.momentOfEntryIntoExercise = Date.now();
    },
    openChat() {
      const { user } = this.$store.state;
      const context = {
        gender: user.gender || 'female',
        phase: this.phase,
        challenge: this.challenge,
        exercise: this.idxExercise,
        currentExercise: this.currentExercise,
      };
      chatService.show(context);
    },
  },
  computed: {
    getValueFromProgressInExercises() {
      const MAXIMUM_IN_PERCENTAGE = 100;
      const MAXIMUM_VALUE = this.exercise.length;
      const CURRENT_VALUE = this.currentStep;

      return (MAXIMUM_IN_PERCENTAGE * CURRENT_VALUE) / MAXIMUM_VALUE;
    },
  },
};
</script>

<style scoped lang="scss">
.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 201;
}
.ta-jusify {
  text-align: justify;
}

div.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.activity-indicator {
  display: flex;
  justify-content: space-between;
}

.chat-button {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 201;
  display: flex;
  height: 20%;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
  gap: 1rem;
}
</style>

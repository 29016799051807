<template>
  <div>
    <div class="mt-10">
        <InputGroup title="Primer sueño">
            <input-validator-component
                    v-model="dataset.firstAnswer"
                    type="text"
                    rules="required|string"
                    name="firstAnswer"
                    @status-change="onValidationStatusChange"
                    @error="onValidationError"/>
        </InputGroup>
    </div>
    <div class="mt-10">
        <InputGroup title="Segundo sueño">
            <input-validator-component
                    v-model="dataset.secondAnswer"
                    type="text"
                    rules="required|string"
                    name="secondAnswer"
                    @status-change="onValidationStatusChange"
                    @error="onValidationError"/>
        </InputGroup>
    </div>
    <div class="mt-10">
        <InputGroup title="Tercer sueño">
            <input-validator-component
                    v-model="dataset.thirdAnswer"
                    type="text"
                    rules="required|string"
                    name="thirdAnswer"
                    @status-change="onValidationStatusChange"
                    @error="onValidationError"/>
        </InputGroup>
    </div>
    <div class="actions">
      <v-btn @click="next()"
             :disabled="!isFormValid"
             color="primary"
             class="mt-10">CONTINUAR</v-btn>
    </div>
  </div>
</template>

<script>
import InputGroup from '../InputGroup.vue';
import InputValidatorComponent from '../InputValidatorComponent.vue';
import validationsMixin from '../../mixins/validations';

export default {
  name: 'dreams',
  components: { InputValidatorComponent, InputGroup },
  mixins: [validationsMixin],
  data: () => ({
    dataset: {
      firstAnswer: '',
      secondAnswer: '',
      thirdAnswer: '',
    },
    validation: {
      success: {
        firstAnswer: false,
        secondAnswer: false,
        thirdAnswer: false,
      },
      errors: {},
    },
  }),
  methods: {
    next() {
      this.$emit('next', this.dataset);
      this.dataset = {
        firstAnswer: '',
        secondAnswer: '',
        thirdAnswer: '',
      };
    },
  },
};
</script>

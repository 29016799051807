<template>
  <div v-if="shouldShow" class="chat-notification">
    <span class="notification-text">{{ message }}</span>
  </div>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
    message: String,
  },
  data() {
    return {
      shownMessages: [],
      shouldShow: false,
    };
  },
  watch: {
    isActive: 'checkAndShowNotification',
    message: 'checkAndShowNotification',
  },
  methods: {
    checkAndShowNotification() {
      if (this.isActive && !this.shownMessages.includes(this.message)) {
        this.shownMessages.push(this.message);
        this.shouldShow = true;
      } else {
        this.shouldShow = false;
      }
    },
  },
};
</script>

<style scoped>
.chat-notification {
  display: flex;
  align-items: center;
  z-index: 202;
  max-width: 280px;
}

.notification-text {
  font-size: 0.8rem;
  font-weight: 800;
  color: white;
  background-color: #F36622;
  padding: 0.8rem 1rem;
  border-radius: 1rem 1rem 0rem 1rem;
}
</style>

<template>
  <v-row v-show="pathToStoryAudio" justify="center">
    <v-col cols="10" sm="8" md="5" class="send-to-surface mt-10 mb-10">
      <h3>Ey!! Es hora de escuchar la historia de Charlie.</h3>
      <p class="mt-5">
        Ponte cómodo y elige un lugar tranquilo.
        <br>
        Puedes leer la historia descargandola desde la sección de Recursos o escuchar el audio.
        <br>
        Tu eliges.
      </p>
      <audio-component v-if="pathToStoryAudio"
                       @ended="onEndedAudio"
                       :url="pathToStoryAudio"
                       class="mt-10" />
      <v-row justify="space-around"
             class="mt-10">
        <v-btn color="primary"
               outlined
               @click="onDownloadForRead">Prefiero Leer Mas Tarde</v-btn>
        <v-btn color="primary"
              :disabled="disableContinueButton"
              @click="onContinue">Continuar</v-btn>
      </v-row>
    </v-col>
    <img src="../assets/images/characters/charlie/charlie_welcome.png"
        class="charlie-welcome send-to-background" />
  </v-row>
</template>

<script>
import MenuService from '../services/menu.service';
import videoService from '../services/video.service';
import { AudioStories, ReadingStories } from '../model/history.dictionary';
import AudioComponent from '../components/audio-component.vue';
import download from '../mixins/download';

export default {
  name: 'SeeHistory',
  mixins: [download],
  components: {
    AudioComponent,
  },
  data: () => ({
    phase: null,
    pathToStoryAudio: null,
    pathToStoryDocument: null,
    disableContinueButton: true,
  }),
  created() {
    MenuService.show('Cuento', 'menu');
  },
  mounted() {
    const { id } = this.$route.params;
    this.id = id;
    this.phase = `phase_${id}`;
    this.pathToStoryDocument = ReadingStories[this.phase];
    const AudioPath = AudioStories[this.phase];
    const Path = `${process.env.VUE_APP_STORAGE_PATH}${AudioPath}`;
    this.$store
      .dispatch('GET_TEMPORAL_URL_FROM', Path)
      .then(this.loadAudioURL);
  },
  methods: {
    loadAudioURL(url) {
      this.pathToStoryAudio = url;
    },
    onEndedAudio() {
      this.disableContinueButton = false;
    },
    onContinue() {
      videoService.show(`animation_${this.phase}.mp4`);
      this.$store
        .dispatch('NEXT_STATE')
        .then(() => this.$router.replace('/home'));
    },
    onDownloadForRead() {
      this.onContinue();
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
}
div.send-to-surface {
  grid-area: main;
  display: flex;
  flex-direction: column;
}

img.charlie-welcome {
  position: absolute;
  bottom: 0%;
  right: 0%;
}

@include sm-portrait {
  img.charlie-welcome {
    max-width: 250px;
  }
}
@include md {
  img.charlie-welcome {
    max-width: 350px;
  }
}
</style>

<template>
  <v-col cols="12" class="elevation-5 pa-10 rounded">
    <v-row justify="space-between">
      <h3>{{title | capitalize}}</h3>
      <eva-icon name="trash"
                fill="#2297aa"
                width="30"
                height="30"
                v-if="addDeleteButtom"
                @click="$emit('delete', idx)"/>
    </v-row>
    <v-row class="mt-5">
      <p>{{subtitle}}</p>
    </v-row>
    <v-row>
      <v-col cols="12">
        <slot name="textarea-description"></slot>
      </v-col >
      <v-col cols="12"
             :class="{ 'mb-10': addCheckList, }">
        <slot name="input-timeLimit"></slot>
      </v-col>
      <v-col v-if="addCheckList"
             cols="12">
        <h4 class="mb-3">Tareas</h4>
        <slot name="check-list"></slot>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
export default {
  name: 'PlanningCard',
  props: {
    idx: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    addDeleteButtom: {
      type: Boolean,
      required: true,
    },
    addCheckList: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    capitalize(value) {
      let result = '';
      if (!value) return result;
      result = value.toString();
      return result.charAt(0).toUpperCase() + result.slice(1);
    },
  },
};
</script>

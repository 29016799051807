<template>
    <v-row class="heigth-100" justify="end">
      <v-col cols="11"
             sm="5"
             class="animated fadeIn">
          <v-row>
            <h1 class="mt-10">Enhorabuena</h1>
            <p class="mt-6">Descarga tu cuaderno de bitácora,
            en el que hemos recogido todo tu viaje.
            Esperamos que te haga tanta ilusión recibirlo como a nosotros entregártelo.</p>
            <h3 class="mt-10">¡Disfrútalo!</h3>
          </v-row>

          <v-row class="mt-5">
            <v-btn color="primary"
                   :disabled="btnDisabled"
                   @click="downloadLogbook">Descargar</v-btn>
          </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="d-flex justify-end align-end">
        <div ref="lottieCelebration" class="lottie-background send-to-background"></div>
        <img src="../assets/images/characters/charlie/charlie_welcome.png"
            class="send-to-surface charlie animated bounceInUp"/>
      </v-col>
    </v-row>
</template>

<script>
import axios from 'axios';
import { loadAnimation } from 'lottie-web';
import MenuService from '../services/menu.service';

export default {
  data: () => ({
    btnDisabled: false,
  }),
  created() {
    MenuService.show('Charlie', 'menu');
  },
  mounted() {
    loadAnimation({
      container: this.$refs.lottieCelebration,
      path: './animations/celebration.json',
      renderer: 'svg',
      loop: true,
      autoplay: true,
    });
  },
  methods: {
    downloadLogbook() {
      this.btnDisabled = true;
      const { email } = this.$store.state.user;
      const config = {
        headers: { 'Content-Type': 'application/json' },
        params: {
          email,
        },
      };
      const baseURL = `${process.env.VUE_APP_API_REST}user/history`;
      axios.get(baseURL, config)
        .then(this.downloadInFileSystem)
        .then(this.dispatchNextState)
        .then(this.goToNextView)
        .finally(this.resetView);
    },
    downloadInFileSystem(response) {
      const { data } = response;
      const { mime } = data;
      if (response && data && mime) {
        this.downloadPDF(response.data.data);
      }
    },
    downloadPDF(pdf) {
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement('a');
      const fileName = 'cuaderno_de_bitácora.pdf';

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },
    dispatchNextState() {
      const { dispatch } = this.$store;
      return dispatch('NEXT_STATE');
    },
    goToNextView() {
      this.$router.replace('/end-game');
    },
    resetView() {
      this.btnDisabled = false;
    },
  },
};
</script>

<style scoped lang="scss">
.heigth-100 {
  height: 100%;
}

.charlie {
  height: 300px;
}

.lottie-background {
  position: absolute;
}
@include lg {
  .lottie-background {
    height: 100%;
  }

  .charlie {
    height: 400px;
  }
}
</style>

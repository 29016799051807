<template>
  <v-row justify="center">
    <v-col cols="10" lg="6" v-if="dataset">
      <Planning class="mt-5"
                :tasksCompleted="dataset"
                :modeEdit="true"
                v-on:next="savePlanning" />
    </v-col>
  </v-row>
</template>

<script>
import last from 'lodash/last';
import MenuService from '../services/menu.service';
import Planning from '../components/content_exercises/Planning.vue';

export default {
  name: 'PlanningView',
  components: {
    Planning,
  },
  data: () => ({
    dataset: null,
    activity: null,
  }),
  created() {
    MenuService.show('PLAN DE ACCIÓN', 'back', () => this.$router.replace('/home'));
    this.assignPlanning();
  },
  methods: {
    assignPlanning() {
      this.activity = this.getPlanning();
      if (this.activity) {
        const ActionPlanExercise = last(this.activity.answer);
        this.dataset = ActionPlanExercise.answer;
      }
    },
    getPlanning() {
      const { exercises } = this.$store.state.user;
      const ID_ACTION_PLAN = '173';
      return exercises.find((item) => item.exercise === ID_ACTION_PLAN);
    },
    savePlanning() {
      this.$swal({
        type: 'question',
        title: '¿Estás segur@ de que deseas actualizar tu plan?',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonColor: '#ff6400',
        cancelButtonColor: '#2297aa',
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('UPDATE_EXERCISE')
            .then(() => {
              this.$swal({
                type: 'success',
                title: 'Plan actualizado con éxito',
                confirmButtonColor: '#2297aa',
              });
              this.assignPlanning();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/app.scss'
</style>

import States from './states.enum';

const TextFromStates = {
  [States.WELCOME]: 'COMIENZO',
  [States.INTRO_ASSISTANT]: 'RECONOCIENDONOS',
  [States.INTRO]: 'RE-CONOCIÉNDOTE',

  [States.CAVE]: 'CUEVA',

  [States.PHASE_0]: 'PUERTA 0. SOÑAR',

  [States.PHASE_1]: 'PUERTA 1. DESEAR',

  [States.PHASE_2]: 'PUERTA 2. CREAR',

  [States.PHASE_3]: 'PUERTA 3. CREER',

  [States.PHASE_4]: 'PUERTA 4. MATERIALIZAR',

  [States.PHASE_5]: 'PUERTA 5. CONCLUIR',

  [States.DOWNLOAD_LOGBOOK]: 'CUADERNO DE BITÁCORA',

  [States.END]: 'FIN',
};

export default TextFromStates;

import firebase from 'firebase';
import buildRouteOfActivities from '../../use-cases/build-route-of-activities';

async function dispatchGetConfig({ commit }) {
  const querySnapshot = await firebase.firestore().collection('phases').get();
  const config = {};
  for (let i = 0; i < querySnapshot.docs.length; i += 1) {
    const doc = querySnapshot.docs[i];
    config[doc.id] = doc.data();
  }
  commit('SET_CONFIG', config);
}

function dispatchGetChallengeOfConfig({ rootState }, { phase, challenge }) {
  const { config } = rootState;
  const existPhase = phase in config;
  if (!existPhase) throw new Error('phase not exist in setup.');

  const { challenges } = config[phase];
  const getChallengedWithTheID = (challengeSetup) => challengeSetup.challenge === Number(challenge);
  const Challenge = challenges.find(getChallengedWithTheID);
  if (!Challenge) throw new Error('challenge not found');
  return Challenge;
}

const getCompletedActivityReferences = ({ exercise }) => `exercises/${exercise}`;

function dispatchBuildRouteOfActivities({ rootState }, { challengeId, currentChallenge }) {
  const { user } = rootState;
  const { exercises } = user;
  const completedActivities = exercises.map(getCompletedActivityReferences);
  let addEveluation = false;
  const IsUnconsciousChallenge = challengeId === '4';
  if (IsUnconsciousChallenge) addEveluation = true;
  const options = { challenge: currentChallenge, addEveluation, completedActivities };
  return buildRouteOfActivities(options);
}

const AppConfigModule = {
  actions: {
    GET_CONFIG: dispatchGetConfig,
    GET_CHALLENGE_OF_CONFIG: dispatchGetChallengeOfConfig,
    BUILD_ROUTE_OF_ACTIVITIES: dispatchBuildRouteOfActivities,
  },
};

export default AppConfigModule;

<template>
    <v-container fluid>
      <Exercise v-if="activity !== null
                && this.route
                && this.route.currentStep
                && this.route.currentStep !== 'exercise/evaluation'"
                :phase="phase"
                :challenge="challenge"
                :activity="activity"
                :activityIdx="activityIdx"
                @next-activity="onEnded"
                :key="componentKey"/>
      <Evaluation v-else-if="this.route
                      && this.route.currentStep === 'exercise/evaluation'"
                  :phase="phase"
                  :challenge="challenge"
                  @next-activity="onFinishedChallenge"/>
    </v-container>
</template>

<script>
import Exercise from './exercise/Exercise.vue';
import Evaluation from './Evaluation.vue';
import auth from '../mixins/auth';
import States from '../model/states.enum';

export default {
  name: 'Activities',
  components: {
    Exercise,
    Evaluation,
  },
  mixins: [auth],
  data: () => ({
    componentKey: 0,
    phase: null,
    challenge: null,
    route: null,
    activity: null,
    currentStep: 0,
  }),
  created() {
    const {
      phase,
      challenge,
    } = this.$route.params;
    if (
      challenge === null
      && phase === null
    ) return;
    this.phase = phase;
    this.challenge = challenge;
    if (
      this.$store.state.user.currentRoute
      && this.$store.state.user.currentRoute.currentStep === null
    ) {
      const saveActivities = (activities) => this.$store.dispatch('ASSIGN_ROUTE', activities);
      this.buildRoute()
        .then(saveActivities)
        .then(() => {
          this.getRoute();
          const exerciseDraft = this.$store.state.draftModule.draft;
          if (exerciseDraft !== null) {
            this.activity = exerciseDraft;
          } else {
            this.getCurrentExercise();
          }
        });
    } else {
      this.getRoute();
      const exerciseDraft = this.$store.state.draftModule.draft;
      if (exerciseDraft !== null) {
        this.activity = exerciseDraft;
        this.forceRerender();
      } else {
        this.getCurrentExercise();
      }
    }
  },
  methods: {
    getCurrentExercise() {
      if (
        this.route
        && this.route.currentStep
        && this.route.currentStep !== 'exercise/evaluation'
      ) {
        this.$store.dispatch('GET_EXERCISE', this.route.currentStep)
          .then((doc) => {
            this.activity = doc.exercise;
            console.log(this.activity);
            this.$store.commit('SET_DRAFT', this.activity);
            this.forceRerender();
          })
          .catch(() => {
            let allowOutsideClick = false;
            this.$swal({
              type: 'error',
              title: 'Ups!',
              text: 'Se ha detectado un problema, para solucionarlo será necesario que cierres la sesión y vuelvas a entrar.',
              confirmButtonText: 'De acuerdo',
              confirmButtonColor: '#2297aa',
              allowEscapeKey: false,
              allowEnterKey: false,
              allowOutsideClick,
            }).then(() => {
              this.logoutDispatch();
              allowOutsideClick = true;
            });
          });
      } else {
        this.forceRerender();
      }
    },
    async buildRoute() {
      const { $store, challenge } = this;
      const { dispatch } = $store;
      const currentChallenge = await this.getChallengeOfConfig();
      const Activities = await dispatch(
        'BUILD_ROUTE_OF_ACTIVITIES',
        { challengeId: challenge, currentChallenge },
      );
      return Activities;
    },
    async getChallengeOfConfig() {
      const { $store, phase, challenge } = this;
      const { dispatch } = $store;
      const PhaseKey = phase === 'intro' ? 'intro' : `phase_${phase}`;
      const Parameters = { phase: PhaseKey, challenge };
      const Challenge = await dispatch('GET_CHALLENGE_OF_CONFIG', Parameters);
      return Challenge;
    },
    getRoute() {
      this.route = this.$store.getters.getUserRoute();
    },
    onEnded() {
      const LastStep = this.route.steps.length - 1;
      const IsLastActivity = this.route.currentStep === this.route.steps[LastStep];
      if (IsLastActivity) {
        this.onFinishedChallenge();
      } else if (!IsLastActivity) {
        this.onNextActivity();
        this.showActivityCounter();
      }
    },
    showActivityCounter() {
      const CurrentActivityIdx = this.howManyActivitiesAreLeftToFinish();
      this.$swal({
        type: 'success',
        title: 'Actividad terminada',
        text: `Quedan ${CurrentActivityIdx} actividades para finalizar el reto`,
        confirmButtonColor: '#2297aa',
      });
    },
    howManyActivitiesAreLeftToFinish() {
      const DEFAULT_VALUE = 0;

      const ActivitiesRoute = this.$store.getters.getUserRoute();
      if (!ActivitiesRoute) return DEFAULT_VALUE;

      const { steps, currentStep } = ActivitiesRoute;
      if (!steps || !currentStep) return DEFAULT_VALUE;

      const MAXIMUM_VALUE = steps.length;
      const CurrentValue = steps.findIndex((step) => step === currentStep);
      return MAXIMUM_VALUE - CurrentValue;
    },
    onNextActivity() {
      const { dispatch, commit } = this.$store;
      commit('SET_DRAFT', null);
      dispatch('NEXT_ROUTE_STEP')
        .then(() => this.getCurrentExercise());
    },
    onFinishedChallenge() {
      const { phase, challenge } = this;
      let phaseValue = `phase_${phase}`;
      if (phase === 'cave') phaseValue = 'temple';
      if (phase === 'intro') phaseValue = 'intro';
      const { dispatch, commit } = this.$store;
      commit('SET_DRAFT', null);
      dispatch('RESET_ROUTE_STEP')
        .then(dispatch('SET_CHALLENGE_OF_PHASE', { phase: phaseValue, idChallenge: challenge }))
        .then(this.loadNextStateIsNeed)
        .then((isNeed) => {
          if (isNeed) dispatch('NEXT_STATE');
        })
        .then(() => this.$router.replace('/home'));
    },
    async loadNextStateIsNeed() {
      const { state, getters } = this.$store;
      const { user } = state;
      const ChallengeConfig = await this.getChallengeOfConfig();
      const { rules } = ChallengeConfig;
      let isNeed = false;
      if (user.flowState === States.INTRO) {
        const exercises = getters.getExercisesIn('intro');
        const IsCompleted = rules.required.length === exercises.length;
        if (IsCompleted) isNeed = true;
      } else if (
        user.flowState === States.PHASE_0
        && user.phases.phase_0.totalPoints
      ) {
        isNeed = true;
      } else if (
        user.flowState === States.PHASE_1
        && user.phases.phase_1.totalPoints
      ) {
        isNeed = true;
      } else if (
        user.flowState === States.PHASE_2
        && user.phases.phase_2.totalPoints
      ) {
        isNeed = true;
      } else if (
        user.flowState === States.PHASE_3
        && user.phases.phase_3.totalPoints
      ) {
        isNeed = true;
      } else if (
        user.flowState === States.PHASE_4
        && user.phases.phase_4.totalPoints
      ) {
        isNeed = true;
      } else if (user.flowState === States.PHASE_5) {
        isNeed = true;
      }
      return isNeed;
    },
    forceRerender() {
      this.componentKey += 1;
    },
  },
  computed: {
    activityIdx() {
      return this.route.currentStep.replace('exercises/', '');
    },
  },
};
</script>

<style>
div.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
    <div>
        <ul>
            <li v-for="(item, idx) in dataset.items" :key="idx">
              <InputGroup :title="item.lable">
                <input v-model="item.value"
                       type="text"/>
                <div class="divider"></div>
              </InputGroup>
            </li>
        </ul>
        <div class="actions">
          <v-btn color="primary" class="mt-10 mb-10" @click="next()">SIGUIENTE</v-btn>
        </div>
    </div>
</template>

<script>
import InputGroup from '../InputGroup.vue';

export default {
  name: 'Metaphors',
  components: { InputGroup },
  props: {
    dataset: {
      type: Object,
      required: true,
    },
  },
  methods: {
    next() {
      const isValid = this.dataset.items.every((item) => item.value !== '');
      if (isValid) {
        this.$emit('next', this.dataset);
      } else {
        this.$swal({
          type: 'info',
          title: 'Hola!',
          text: 'Necesito que completes todos los campos.',
          confirmButtonColor: '#2297aa',
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/app.scss';
</style>

import firebase from 'firebase';
import User from '../../model/user';

const FireAuthModule = {
  actions: {
    REAUTH_ACCOUNT: (context, { email, password }) => {
      const credential = firebase.auth.EmailAuthProvider.credential(email, password);
      return firebase.auth().currentUser.reauthenticateWithCredential(credential);
    },
    SIGNUP_USER: ({ commit, dispatch }, formNewUser) => new Promise((resolve, reject) => {
      const { firestore, auth } = firebase;
      const NewUser = User(formNewUser);
      const getConfig = () => dispatch('GET_CONFIG');
      const registerUserData = () => firestore().collection('users').add(NewUser);
      const localSaveUserData = (document) => {
        const user = {
          docId: document.id,
          ...NewUser,
        };
        commit('SET_USER', user);
        return user;
      };
      auth()
        .createUserWithEmailAndPassword(formNewUser.email, formNewUser.password)
        .then(getConfig)
        .then(registerUserData)
        .then(localSaveUserData)
        .then(resolve)
        .catch(({ code }) => reject(code));
    }),
    LOGIN_USER: ({ dispatch }, formUser) => new Promise((resolve, reject) => {
      const { auth } = firebase;
      const { currentUser } = auth();
      if (currentUser) return;
      const getConfig = () => dispatch('GET_CONFIG');
      const getUserData = () => dispatch('GET_USER_DATA', formUser.email);
      auth()
        .signInWithEmailAndPassword(formUser.email, formUser.password)
        .then(getConfig)
        .then(getUserData)
        .then(resolve)
        .catch(reject);
    }),
    SIGNOUT_USER: ({ dispatch }) => new Promise((resolve, reject) => {
      const { auth } = firebase;
      const { currentUser } = auth();
      if (!currentUser) return;
      const localSignOut = () => dispatch('LOCAL_SIGNOUT');
      auth()
        .signOut()
        .then(localSignOut)
        .then(resolve)
        .catch((error) => reject(error.code));
    }),
    ONLY_THE_FIREBASE_SIGNOUT: () => new Promise((resolve, reject) => {
      firebase.auth()
        .signOut()
        .then(resolve)
        .catch(reject);
    }),
    LOCAL_SIGNOUT: ({ commit }) => new Promise((resolve) => {
      commit('SET_CONFIG', null);
      commit('SET_DRAFT', null);
      commit('RESET_USER');
      resolve();
    }),
  },
};

export default FireAuthModule;

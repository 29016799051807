import { render, staticRenderFns } from "./ChatNotification.vue?vue&type=template&id=071a1678&scoped=true"
import script from "./ChatNotification.vue?vue&type=script&lang=js"
export * from "./ChatNotification.vue?vue&type=script&lang=js"
import style0 from "./ChatNotification.vue?vue&type=style&index=0&id=071a1678&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "071a1678",
  null
  
)

export default component.exports
<template>
  <CardWithSymbols color="secondary" class="fill-height">
    <template slot="messages">
      <VueMarkdown class="markdown-body"
                  :emoji="true"
                  :breaks="true"
                  :typographer="true"
                  lang-prefix="markdown"
                  :source="message"></VueMarkdown>
    </template>
    <template slot="actions">
      <v-btn color="primary" @click.prevent="next">Continuar</v-btn>
    </template>
  </CardWithSymbols>
</template>
<script>
import VueMarkdown from 'vue-markdown';
import MenuService from '../services/menu.service';
import CardWithSymbols from '../components/CardWithSymbols.vue';
import States from '../model/states.enum';

export default {
  name: 'Messages',
  components: {
    CardWithSymbols,
    VueMarkdown,
  },
  data: () => ({
    message: '',
  }),
  created() {
    MenuService.hidden();
  },
  mounted() {
    const { flowState } = this.$store.state.user;
    if (flowState === States.CAVE_SUSPEND_MESSAGE) {
      const frases = [
        'Hmm... esta puerta no está conseguida… !por ahora!\n¿Comenzamos?',
        'Hay algunos ejercicios que parece que han sido un reto difícil y no has conseguido la puntuación necesaria.\nInténtalo otra vez',
        'Una segunda oportunidad no es un fracaso, es una nueva oportunidad para hacerlo mejor.',
        'Tus logros tienen valor por todo lo bueno que te aportan y por todo lo que has aprendido gracias al camino que decidiste emprender para conseguirlo.',
      ];
      const numOptions = frases.length - 1;
      const optionSelected = Math.round(Math.random() * numOptions);
      this.message = `**Vuelves a la puerta anterior**\n\n${frases[optionSelected]}`;
    } else if (flowState === States.CAVE_APPROVE_MESSAGE) {
      this.message = '**!Genial¡**\n\nHas superado la cueva.\nPuedes continuar con la siguiente puerta.';
    }
  },
  methods: {
    next() {
      const { dispatch } = this.$store;
      dispatch('NEXT_STATE')
        .then(() => this.$router.replace('/home'));
    },
  },
};
</script>
<style scoped lang="scss">
.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div#message {
    -webkit-box-shadow: 0px -1px 8px 0px #939393;
    -moz-box-shadow: 0px -1px 8px 0px #939393;
    box-shadow: 0px -1px 8px 0px #939393;
    padding: 4em 1em 4em 1em;
    background-color: $surface;
    text-align: center;
    width: 100%;
    max-width: 100%;
  }
  * {
    margin-bottom: 10%;
    color: $foreground-secondary;
  }
}
</style>

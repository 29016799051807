const Guides = {
  AFTER_INTRO: 17,
  BEFORE_REGISTER: 0,
  BEFORE_PHASE_0: 3,
  BEFORE_PHASE_1: 4,
  BEFORE_PHASE_2: 5,
  BEFORE_PHASE_3: 6,
  BEFORE_PHASE_4: 7,
  BEFORE_PHASE_5: 8,
  AFTER_AWARD_P0: 10,
  AFTER_AWARD_P1: 11,
  AFTER_AWARD_P2: 12,
  AFTER_AWARD_P3: 13,
  AFTER_AWARD_P4: 14,
  AFTER_AWARD_P5: 15,
  END: 16,
};

export default Guides;

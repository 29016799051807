export default {
  methods: {
    authAlerts(error) {
      let options = {};
      let action = () => {};
      switch (error) {
        case 'auth/user-not-found':
          options = {
            type: 'question',
            title: 'Hola!',
            text: 'Parece que hay un problema con tu usuario, asegúrate de que el email es el correcto o quizá es que aún no te has registrado.',
            confirmButtonText: 'Registrarme',
            confirmButtonColor: '#2297aa',
          };
          action = ({ value }) => {
            if (!value) return;
            this.$router.replace('/signup');
          };
          break;
        case 'auth/email-already-in-use':
          options = {
            type: 'info',
            title: 'Hola!',
            text: 'Esta direción de correo ya existe.',
            confirmButtonColor: '#2297aa',
          };
          break;
        case 'auth/invalid-email':
          options = {
            type: 'info',
            title: 'Hola!',
            text: 'Este email no es válido, asegúrate de que tu dirección de correo está bien escrita. \nGracias!',
            confirmButtonColor: '#2297aa',
          };
          break;
        case 'unauth/email':
          options = {
            type: 'info',
            title: 'Oops...',
            text: 'Parece que no tienes acceso al juego...',
            confirmButtonColor: '#2297aa',
          };
          break;
        case 'error-singup/law':
          options = {
            type: 'info',
            title: 'Oops',
            text: 'Debes aceptar los términos legales antes de jugar conmigo.',
            confirmButtonColor: '#2297aa',
          };
          break;
        case 'error/not-edited':
          options = {
            type: 'info',
            title: 'Ups!',
            text: 'Es necesario que modifiques los datos para poder actualizar tu perfil.',
            confirmButtonColor: '#2297aa',
          };
          break;
        case 'auth/wrong-password':
          options = {
            type: 'info',
            title: 'Upps!',
            text: 'La dirección de email o la contraseña es incorrecta.',
            confirmButtonColor: '#2297aa',
          };
          break;
        default:
          options = {
            type: 'error',
            title: 'Hola!',
            text: 'Me parece que algo está fallando. ¿Porqué no vuelves a intentarlo más tarde?',
            confirmButtonColor: '#2297aa',
          };
          break;
      }
      if (error) {
        this.$swal(options)
          .then(action);
      }
    },
  },
};

<template>
  <div class="central-container center-content-grid send-to-surface">
    <h1 class="animated bounceInDown">Bienvenid@</h1>
    <div class="buttons-container send-to-surface animated bounceInDown">
      <v-btn color="primary" x-large v-on:click="singin">Regístrate</v-btn>
      <v-btn color="primary" x-large v-on:click="login">Iniciar Sesión</v-btn>
    </div>
    <img src="../assets/images/characters/charlie/charlie_welcome.png"
         class="charlie-welcome send-to-background animated bounceInUp" />

    <p class="version">Versión: {{ appVersión }}</p>
  </div>
</template>

<script>
import firebase from 'firebase';
import LoaderService from '../services/loader.service';
import MenuService from '../services/menu.service';

export default {
  name: 'Access',
  data: () => ({
    appVersión: process.env.APP_VERSION,
  }),
  created() {
    MenuService.hidden();
    this.resetUser();
  },
  methods: {
    login() {
      LoaderService.show();
      this.$router.replace('/login');
    },
    singin() {
      LoaderService.show();
      this.$router.replace('/signup');
    },
    resetUser() {
      const { docId } = this.$store.state.user;
      this.$store.commit('SET_DRAFT', null);
      if (
        firebase.auth().currentUser === null
        && docId !== ''
      ) {
        this.$store.commit('RESET_USER');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.version {
  align-self: flex-end;
  justify-self: flex-start;
  margin-left: 5%;
  margin-bottom: 5%;
}

@include sm-portrait {
  .central-container {
    height: 100vh;
    display: grid;
    grid-template: 1fr 0.5fr 1fr / 1fr;
  }
  .buttons-container {
    display: grid;
    grid-template: 1fr 1fr / 1fr;
    grid-gap: 10px;
  }
}

@include sm-landscape {
  .container {
    grid-template: auto / 10% auto 40%;
  }
  .central-container {
    display: grid;
    grid-template: 0.5fr 1fr 0.5fr / 1fr;
  }
  .buttons-container {
    display: grid;
    grid-template: 1fr 1fr / 1fr;
    grid-gap: 10px;
  }
}

img.charlie-welcome {
  position: absolute;
  bottom: 0%;
  right: 0%;
  max-width: 350px;
}

@include sm-portrait {
  img.charlie-welcome {
    visibility: hidden;
  }
}
@include md {
  img.charlie-welcome {
    visibility: visible;
  }
}
</style>

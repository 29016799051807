const DraftModule = {
  state: {
    draft: null,
  },
  mutations: {
    SET_DRAFT: (state, item) => {
      state.draft = item;
    },
  },
};

export default DraftModule;

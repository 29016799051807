<template>
  <div>
    <div>
      <masonry :cols="2"
               :gutter="10">
        <div v-for="(item, index) in dataset"
            :key="index"
            :data-cy="cyTest(index)"
            @click="selectedItem(index)">
          <img class="img-box" :src="getUrlFrom(item)" />
        </div>
      </masonry>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListImagesComponent',
  props: {
    buttonsDisabled: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: Number,
      default: 10,
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    min: {
      type: Number,
      required: true,
      default: 1,
    },
    max: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data: () => ({
    selected: [],
  }),
  mounted() {
    this.selected = this.dataset.map(() => false);
  },
  methods: {
    cyTest(idx) {
      return `item-${idx}`;
    },
    selectedItem(idx) {
      this.selected[idx] = true;
      this.checkSelected();
    },
    checkSelected() {
      const listSelected = this.dataset.filter((item, idx) => this.selected[idx] === true);
      if (listSelected.length === this.max) {
        this.selected = this.selected.map(() => false);
        this.$emit('next', listSelected);
      }
    },
    getUrlFrom(path) {
      const IncludesSymbols = path.includes('symbols');
      const IncludesImages = path.includes('images');
      if (IncludesSymbols && !IncludesImages) return `${process.env.BASE_URL}images/${path}`;
      return `${process.env.BASE_URL}${path}`;
    },
  },
};
</script>

<style lang="scss" scoped>
  .img-box {
    width: 100%;
    padding: 0.5em;
    border: 0.5px solid $shadow;
    border-radius: .5em;
  }
</style>

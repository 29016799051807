<template>
  <vue-markdown class="mt-4 markdown-body"
                :emoji="true"
                :breaks="true"
                :typographer="true"
                lang-prefix="markdown"
                :source="source"></vue-markdown>
</template>

<script>
import VueMarkdown from 'vue-markdown';

export default {
  name: 'markdown-component',
  components: {
    VueMarkdown,
  },
  props: {
    source: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.markdown-body {
  p {
    text-align: justify;
    img {
      padding: 1px;
      border: 0.5px solid gray;
      border-radius: .5em;
      display: block;
      margin: 0 auto;
    }
  }
}
</style>

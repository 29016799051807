<template>
  <v-row justify="center">
    <v-col :cols="12" :sm="10" :md="9">
      <div v-if="dataset.length === 0" class="empty-view animated jackInTheBox">
        <img src="../../assets/images/empty.png"/>
        <h6>No hay documentos</h6>
      </div>
      <div class="list-grid animated bounceInUp"
          v-for="(item, idx) in dataset"
          :key="idx"
          v-else>
        <div class="item-layout">
          <span>{{item.title}}</span>
          <eva-icon name="download" fill="#2297aa" @click="download(item.url)" />
        </div>
        <v-divider></v-divider>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import download from '../../mixins/download';
import States from '../../model/states.enum';
import { ReadingStories } from '../../model/history.dictionary';

export default {
  mixins: [download],
  data: () => ({
    dataset: [
      {
        url: 'images/arbol_de_navegacion.jpg',
        title: 'Arbol De Navegacion',
      },
    ],
  }),
  mounted() {
    this.loadDocumentsOfActivities();
    this.loadDocumentsOfStories();
  },
  methods: {
    loadDocumentsOfActivities() {
      const { exercises } = this.$store.state.user;
      const documents = ['94', '167', '306'];
      if (exercises && exercises.length) {
        const result = _.chain(exercises)
          .filter((item) => _.includes(documents, item.exercise))
          .map((item) => {
            const idxDataset = _.findIndex(documents, (id) => item.exercise === id);
            let url;
            let title;
            // eslint-disable-next-line prefer-destructuring
            if (idxDataset !== null && item.exercise === '94') {
              title = 'Daruma';
              url = 'pdf_documents/daruma.pdf';
            }
            if (idxDataset !== null && item.exercise === '167') {
              title = 'COMPROMISO';
              url = 'pdf_documents/la_casa_de_papel.pdf';
            }
            if (idxDataset !== null && item.exercise === '306') {
              title = 'Camiseta';
              url = 'pdf_documents/camiseta.pdf';
            }
            return {
              title,
              url,
            };
          })
          .value();
        this.dataset.push(...result);
      }
    },
    loadDocumentsOfStories() {
      const { flow } = this.$store.state.user;
      if (flow.includes(States.PHASE_0_LISTEN_HISTORY)) {
        this.dataset.push({
          title: 'Cuento de Puerta 0',
          url: ReadingStories.phase_0,
        });
      }

      if (flow.includes(States.PHASE_1_LISTEN_HISTORY)) {
        this.dataset.push({
          title: 'Cuento de Puerta 1',
          url: ReadingStories.phase_1,
        });
      }

      if (flow.includes(States.PHASE_2_LISTEN_HISTORY)) {
        this.dataset.push({
          title: 'Cuento de Puerta 2',
          url: ReadingStories.phase_2,
        });
      }

      if (flow.includes(States.PHASE_3_LISTEN_HISTORY)) {
        this.dataset.push({
          title: 'Cuento de Puerta 3',
          url: ReadingStories.phase_3,
        });
      }

      if (flow.includes(States.PHASE_4_LISTEN_HISTORY)) {
        this.dataset.push({
          title: 'Cuento de Puerta 4',
          url: ReadingStories.phase_4,
        });
      }

      if (flow.includes(States.PHASE_5_LISTEN_HISTORY)) {
        this.dataset.push({
          title: 'Cuento de Puerta 5',
          url: ReadingStories.phase_5,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  .list-grid {
    display: flex;
    flex-basis: 1;
    flex-direction: column;
    .item-layout {
      padding: .5em;
      display: flex;
      flex-basis: 1;
      justify-content: space-between;
    }
  }

  .empty-view {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 30%;
    }
  }
}

</style>

import Vue from 'vue';
import firebase from 'firebase';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';
import Wish from '../../model/wish.enum';
import States from '../../model/states.enum';
import Guides from '../../model/guides.enum';
import videoService from '../../services/video.service';
import DraftModule from './draft.module';
import FireStorageModule from './fire_storage.module';
import FireAuthModule from './fire_auth.module';
import UserModule from './user.module';
import AppConfigModule from './app-config.module';
import FireAnalyticsModule from './fire_analytics.module';
import VersionHandlerModule from './version-handler.module';
import { ValueRequiredToPassTheDoor } from '../../model/constants';
import chatService from '../../services/chat.service';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'charlie:store',
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: {
    config: {},
    user: {
      docId: '',
      email: '',
      name: '',
      firstSurname: '',
      secondSurname: '',
      birthdate: '',
      registeredAt: '',
      hasWish: 2,
      legal: false,
      flowState: States.INTRO,
      flow: [],
      currentRoute: {
        steps: [],
        currentStep: null,
      },
      phases: {
        intro: {},
        phase_0: {
          theatre: false,
          impulse: false,
          materialise: false,
          unconscious: false,
          totalPoints: null,
        },
        phase_1: {
          theatre: false,
          impulse: false,
          materialise: false,
          unconscious: false,
          totalPoints: null,
        },
        phase_2: {
          theatre: false,
          impulse: false,
          materialise: false,
          unconscious: false,
          totalPoints: null,
        },
        phase_3: {
          theatre: false,
          impulse: false,
          materialise: false,
          unconscious: false,
          totalPoints: null,
        },
        phase_4: {
          theatre: false,
          impulse: false,
          materialise: false,
          unconscious: false,
          totalPoints: null,
        },
        phase_5: {
          theatre: false,
          totalPoints: null,
        },
        temple: {
          totalPoints: null,
        },
      },
      exercises: [],
    },
  },
  getters: {
    // eslint-disable-next-line no-unused-vars
    getUID: (state) => () => firebase.auth().currentUser.uid,
    // eslint-disable-next-line no-unused-vars
    isLogged: (state) => () => firebase.auth().currentUser !== null,
    getUser: (state) => state.user,
    getRulesOf: (state) => (phase, challenge) => state.config[phase].challenges[challenge].rules,
    getBlankExercisesOf:
      (state) => (phase, challenge) => state.config[phase].challenges[challenge].exercises,
    getExercisesBetween: (state) => (start, end) => state.user.exercises.filter((item) => {
      const exercise = Number.parseInt(item.exercise, NaN);
      return exercise >= start && exercise <= end;
    }),
    getExercisesIn:
      (state) => (phase) => state.user.exercises.filter((item) => item.phase === phase),
    removeExercisesBetween: (state) => (start, end) => state.user.exercises.filter((item) => {
      const exercise = Number.parseInt(item.exercise, NaN);
      return !(exercise >= start && exercise <= end);
    }),
    removeExercisesIn:
      (state) => (phaseValue) => state.user.exercises.filter(({ phase }) => phase !== phaseValue),
    getUserRoute: (state) => () => state.user.currentRoute,
  },
  mutations: {
    RESET_USER: (state) => {
      state.user = {};
    },
    SET_USER: (state, payload) => {
      state.user = payload;
    },
    SET_WISH: (state, payload) => {
      state.user.hasWish = payload;
    },
    SET_CONFIG: (state, payload) => {
      state.config = payload;
    },
    NEXT_STATE: (state, flowState) => {
      firebase.firestore()
        .collection('users')
        .doc(state.user.docId)
        .update({
          flow: firebase.firestore.FieldValue.arrayUnion(flowState),
          flowState,
        });
      state.user.flow.push(flowState);
      state.user.flowState = flowState;
    },
  },
  actions: {
    // Dispachers of user
    UPDATE_USER: ({ state, commit }, formUser) => new Promise((resolve, reject) => {
      const userEdit = {};
      const userDataset = state.user;
      if (state.user.email !== formUser.email) {
        userEdit.email = formUser.email;
        userDataset.email = formUser.email;
      }
      if (state.user.name !== formUser.name) {
        userEdit.name = formUser.name;
        userDataset.name = formUser.name;
      }
      if (state.user.firstSurname !== formUser.firstSurname) {
        userEdit.firstSurname = formUser.firstSurname;
        userDataset.firstSurname = formUser.firstSurname;
      }
      if (state.user.secondSurname !== formUser.secondSurname) {
        userEdit.secondSurname = formUser.secondSurname;
        userDataset.secondSurname = formUser.secondSurname;
      }
      if (state.user.birthdate !== formUser.birthdate) {
        userEdit.birthdate = formUser.birthdate;
        userDataset.birthdate = formUser.birthdate;
      }
      if (
        userEdit && (
          'email' in userEdit
          || 'name' in userEdit
          || 'firstSurname' in userEdit
          || 'secondSurname' in userEdit
          || 'birthdate' in userEdit
        )
      ) {
        const updateDoc = firebase.firestore().doc(`users/${state.user.docId}`).update(userEdit);
        updateDoc
          .then(() => {
            commit('SET_USER', userDataset);
            resolve();
          })
          .catch((error) => reject(error));
      } else {
        reject(new Error('error/not-edited').message);
      }
    }),
    UPDATE_USER_PASSWORD: (contex, { password, newPassword }) => new Promise((resolve, reject) => {
      const user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        password,
      );
      user.reauthenticateWithCredential(credential)
        .then(() => {
          const passwordDoc = firebase.auth().currentUser.updatePassword(newPassword);
          passwordDoc
            .then(() => {
              resolve();
            })
            .catch((error) => reject(error));
        });
    }),
    SET_HAS_WISH: ({ state, commit }, hasWish) => {
      firebase.firestore()
        .collection('users')
        .doc(state.user.docId)
        .update({ hasWish });
      commit('SET_WISH', hasWish);
    },
    SET_HAS_QUESTION: ({ state, commit }, { keyOfQuestion, valueOfQuestion }) => {
      const keyPath = `questions.${keyOfQuestion}`;
      firebase.firestore()
        .collection('users')
        .doc(state.user.docId)
        .update({
          [keyPath]: valueOfQuestion,
        });
      const { user } = state;
      set(user, keyPath, valueOfQuestion);
      commit('SET_USER', user);
    },
    REQUEST_RESET_PASSWORD: (context, { email }) => firebase.auth().sendPasswordResetEmail(email),
    // Other dispachers.
    GET_PHASE: (context, phaseDoc) => new Promise((resolve, reject) => {
      let phase = phaseDoc;
      if (phaseDoc !== 'temple' && phaseDoc !== 'intro') {
        phase = `phase_${phaseDoc}`;
      }
      firebase.firestore()
        .doc(`phases/${phase}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          }
          reject();
        })
        .catch((error) => {
          reject(error.code);
        });
    }),
    SHOW_WORLD_VIDEO: ({ state }) => {
      const { exercises } = state.user;
      const activity = exercises.find((item) => item.exercise === '1');
      if (activity) {
        const TYPE_REQUIRED = 'IMAGENES';
        const getTypeRequired = (currentExercise) => currentExercise.type === TYPE_REQUIRED;
        const exercise = activity.answer.find(getTypeRequired);
        const [symbolImg] = exercise.answer;
        const symbolVideo = symbolImg.replace('symbols/', '')
          .replace('images/', '')
          .replace('.png', '.mp4');
        videoService.show(`worlds/${symbolVideo}`);
      }
    },
    // Dispachers of challenge mapping.
    ASSIGN_ROUTE: ({ commit, state }, route) => new Promise((resolve, reject) => {
      const { user } = state;
      const currentRoute = {
        steps: JSON.parse(JSON.stringify(route)),
        currentStep: route[0],
      };
      firebase.firestore()
        .collection('users')
        .doc(state.user.docId)
        .update({
          currentRoute,
        })
        .then(() => {
          user.currentRoute = currentRoute;
          commit('SET_USER', user);
          resolve();
        })
        .catch(reject);
    }),
    NEXT_ROUTE_STEP: ({ commit, state }) => {
      const { user } = state;
      const { currentStep, steps } = user.currentRoute;
      const nextIdx = findIndex(steps, (item) => item === currentStep) + 1;
      if (nextIdx < steps.length) {
        firebase.firestore()
          .collection('users')
          .doc(user.docId)
          .update({
            'currentRoute.currentStep': steps[nextIdx],
          });
        user.currentRoute.currentStep = steps[nextIdx];
        commit('SET_USER', user);
      }
    },
    RESET_ROUTE_STEP: ({ commit, state }) => {
      const { user } = state;
      firebase.firestore()
        .collection('users')
        .doc(user.docId)
        .update({
          'currentRoute.currentStep': null,
          'currentRoute.steps': [],
        });
      user.currentRoute.currentStep = null;
      user.currentRoute.steps = [];
      commit('SET_USER', user);
    },
    // Dispachers of activities/exercises
    GET_EXERCISE: (context, docExercise) => new Promise((resolve, reject) => {
      firebase.firestore()
        .doc(docExercise)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          }
          reject();
        })
        .catch((error) => {
          reject(error.code);
        });
    }),
    SAVE_EXERCISE: ({ state, commit }, {
      phase, challenge, exercise, answer,
    }) => new Promise((resolve, reject) => {
      const { user } = state;
      const newExercise = {
        phase,
        challenge,
        exercise,
        answer,
      };
      user.exercises.push(newExercise);
      firebase.firestore()
        .collection('users')
        .doc(state.user.docId)
        .update({
          exercises: firebase.firestore.FieldValue.arrayUnion(newExercise),
        })
        .then(() => commit('SET_USER', user))
        .then(resolve())
        .catch(reject());
    }),
    UPDATE_EXERCISE: ({ state, commit }) => new Promise((resolve, reject) => {
      const { user } = state;
      commit('SET_USER', user);
      firebase.firestore()
        .collection('users')
        .doc(state.user.docId)
        .update({
          exercises: JSON.parse(JSON.stringify(user.exercises)),
        })
        .then(resolve())
        .catch(reject());
    }),
    SET_CHALLENGE_OF_PHASE: ({ state, commit }, { phase, idChallenge }) => {
      const { user } = state;
      let challenge = 'theatre';
      if (idChallenge === '2') challenge = 'impulse';
      else if (idChallenge === '3') challenge = 'materialise';
      else if (idChallenge === '4') challenge = 'unconscious';

      if (phase === 'phase_0' && challenge === 'theatre') {
        // chatService.notify('Hola! Soy Charlie, si me necesitas estoy aquí');
        chatService.notify('Recuerda, estoy aquí para ayudarte en lo que necesites');
      } else if (phase === 'phase_0' && challenge === 'impulse') {
        chatService.notify('¿Cómo vas? si me necesitas recuerda que estoy aquí');
      } else if (phase === 'phase_0' && challenge === 'materialise') {
        chatService.notify('Soñar no siempre es fácil, si me necesitas estoy aquí');
      } else if (phase === 'phase_1' && challenge === 'materialise') {
        chatService.notify('Tener bien formulado el objetivo es muy importante, puedes consultar conmigo');
      }

      const path = `phases.${phase}.${challenge}`;
      firebase.firestore()
        .collection('users')
        .doc(user.docId)
        .update({ [path]: true });
      set(user, path, true);
      commit('SET_USER', user);
    },
    SET_EVALUATION: ({ state, commit }, { phase, totalPoints }) => {
      const { user } = state;
      const totalPointsPath = `phases.${phase}.totalPoints`;
      firebase.firestore()
        .collection('users')
        .doc(user.docId)
        .update({
          [totalPointsPath]: totalPoints,
        });
      set(user, totalPointsPath, totalPoints);
      commit('SET_USER', user);
    },
    // Dispachers of cave.
    ACTIVE_CAVE: ({ state, commit }) => {
      const { user } = state;
      let lastPhase = 'phase_0';
      if (user.flow[user.flow.length - 1] === States.PHASE_1) {
        lastPhase = 'phase_1';
      } else if (user.flow[user.flow.length - 1] === States.PHASE_2) {
        lastPhase = 'phase_2';
      } else if (user.flow[user.flow.length - 1] === States.PHASE_3) {
        lastPhase = 'phase_3';
      } else if (user.flow[user.flow.length - 1] === States.PHASE_4) {
        lastPhase = 'phase_4';
      } else if (user.flow[user.flow.length - 1] === States.PHASE_5) {
        lastPhase = 'phase_5';
      }

      firebase.firestore()
        .collection('users')
        .doc(user.docId)
        .update({
          'phases.temple.flowState': 0,
          'phases.temple.lastPhase': lastPhase,
        });
      user.phases.temple.flowState = 0;
      user.phases.temple.lastPhase = lastPhase;
      commit('SET_USER', user);
    },
    DESACTIVATE_CAVE: ({ state, commit }) => {
      const { user } = state;
      const dataset = {
        flowState: null,
        lastPhase: null,
        totalPoints: null,
      };
      firebase.firestore()
        .collection('users')
        .doc(user.docId)
        .update({
          'phases.temple': dataset,
        });
      user.phases.temple = dataset;
      commit('SET_USER', user);
    },
    CAVE_NEXT_FLOW_STATE: ({ state, commit }, { nextFlowState }) => {
      const { user } = state;
      firebase.firestore()
        .collection('users')
        .doc(user.docId)
        .update({
          'phases.temple.flowState': nextFlowState,
        });
      user.phases.temple.flowState = nextFlowState;
      commit('SET_USER', user);
    },
    SUSPEND_CAVE: ({
      state,
      commit,
      getters,
      dispatch,
    }) => {
      const { user } = state;
      const docReference = firebase.firestore()
        .collection('users')
        .doc(state.user.docId);
      const strPhase = user.phases.temple.lastPhase.replace('phase_', '');
      const numLastPhase = parseInt(strPhase, NaN);
      const dataset = {
        theatre: false,
        impulse: false,
        materialise: false,
        unconscious: false,
        totalPoints: null,
      };
      if (
        numLastPhase === 0
        || numLastPhase === 1
      ) {
        const exercises = getters.removeExercisesIn('0');
        docReference.update({
          exercises,
          'phases.phase_0': cloneDeep(dataset),
        });
        user.exercises = exercises;
        user.phases.phase_0 = cloneDeep(dataset);
        dispatch('SET_HAS_WISH', Wish.NO_HAS);
      }
      if (
        numLastPhase === 1
        || numLastPhase === 2
      ) {
        const exercises = getters.removeExercisesIn('1');
        docReference.update({
          exercises,
          'phases.phase_1': cloneDeep(dataset),
        });
        user.exercises = exercises;
        user.phases.phase_1 = cloneDeep(dataset);
      }
      if (
        numLastPhase === 2
        || numLastPhase === 3
      ) {
        const exercises = getters.removeExercisesIn('2');
        docReference.update({
          exercises,
          'phases.phase_2': cloneDeep(dataset),
        });
        user.exercises = exercises;
        user.phases.phase_2 = cloneDeep(dataset);
      }
      if (
        numLastPhase === 3
        || numLastPhase === 4
      ) {
        const exercises = getters.removeExercisesIn('3');
        docReference.update({
          exercises,
          'phases.phase_3': cloneDeep(dataset),
        });
        user.exercises = exercises;
        user.phases.phase_3 = cloneDeep(dataset);
      }
      if (
        numLastPhase === 4
        || numLastPhase === 5
      ) {
        const exercises = getters.removeExercisesIn('4');
        docReference.update({
          exercises,
          'phases.phase_4': cloneDeep(dataset),
        });
        user.exercises = exercises;
        user.phases.phase_4 = cloneDeep(dataset);
      }
      if (
        numLastPhase === 5
      ) {
        const exercises = getters.removeExercisesIn('5');
        docReference.update({
          exercises,
          'phases.phase_5': cloneDeep(dataset),
        });
        user.exercises = exercises;
        user.phases.phase_5 = cloneDeep(dataset);
      }
      commit('SET_USER', user);
    },
    APPROVE_CAVE: ({
      state,
      commit,
    }) => {
      const { user } = state;
      const docReference = firebase.firestore()
        .collection('users')
        .doc(state.user.docId);
      const { lastPhase } = user.phases.temple;
      const dataset = {
        totalPoints: ValueRequiredToPassTheDoor,
      };
      if (lastPhase === 'phase_0') {
        docReference.update({
          'phases.phase_0': dataset,
        });
        user.phases.phase_0 = dataset;
      } else if (lastPhase === 'phase_1') {
        docReference.update({
          'phases.phase_1': dataset,
        });
        user.phases.phase_1 = dataset;
      } else if (lastPhase === 'phase_2') {
        docReference.update({
          'phases.phase_2': dataset,
        });
        user.phases.phase_2 = dataset;
      } else if (lastPhase === 'phase_3') {
        docReference.update({
          'phases.phase_3': dataset,
        });
        user.phases.phase_3 = dataset;
      } else if (lastPhase === 'phase_4') {
        docReference.update({
          'phases.phase_4': dataset,
        });
        user.phases.phase_4 = dataset;
      } else if (lastPhase === 'phase_5') {
        docReference.update({
          'phases.phase_5': dataset,
        });
        user.phases.phase_5 = dataset;
      }
      commit('SET_USER', user);
    },
    // Dispachers of guide.
    GET_GUIDE: ({ state }) => new Promise((resolve, reject) => {
      const { flowState } = state.user;
      let docQuery = null;
      if (flowState === States.GUIDE_AFTER_INTRO) {
        docQuery = Guides.AFTER_INTRO;
      } else if (flowState === States.BEFORE_PHASE_0) {
        docQuery = Guides.BEFORE_PHASE_0;
      } else if (flowState === States.BEFORE_PHASE_1) {
        docQuery = Guides.BEFORE_PHASE_1;
      } else if (flowState === States.BEFORE_PHASE_2) {
        docQuery = Guides.BEFORE_PHASE_2;
      } else if (flowState === States.BEFORE_PHASE_3) {
        docQuery = Guides.BEFORE_PHASE_3;
      } else if (flowState === States.BEFORE_PHASE_4) {
        docQuery = Guides.BEFORE_PHASE_4;
      } else if (flowState === States.BEFORE_PHASE_5) {
        docQuery = Guides.BEFORE_PHASE_5;
      } else if (flowState === States.AFTER_AWARD_P0) {
        docQuery = Guides.AFTER_AWARD_P0;
      } else if (flowState === States.AFTER_AWARD_P1) {
        docQuery = Guides.AFTER_AWARD_P1;
      } else if (flowState === States.AFTER_AWARD_P2) {
        docQuery = Guides.AFTER_AWARD_P2;
      } else if (flowState === States.AFTER_AWARD_P3) {
        docQuery = Guides.AFTER_AWARD_P3;
      } else if (flowState === States.AFTER_AWARD_P4) {
        docQuery = Guides.AFTER_AWARD_P4;
      } else if (flowState === States.AFTER_AWARD_P5) {
        docQuery = Guides.AFTER_AWARD_P5;
      } else if (flowState === States.END) {
        docQuery = Guides.END;
      } else {
        docQuery = Guides.BEFORE_REGISTER;
      }
      firebase.firestore()
        .doc(`all_guides/${docQuery}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve({
              ...doc.data(),
              guideId: docQuery,
            });
          }
          reject();
        })
        .catch((error) => {
          reject(error.code);
        });
    }),
    GET_USE_GUIDE: () => new Promise((resolve, reject) => {
      const docQuery = Guides.BEFORE_REGISTER;
      firebase.firestore()
        .doc(`all_guides/${docQuery}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve({
              ...doc.data(),
              guideId: docQuery,
            });
          }
          reject();
        })
        .catch((error) => {
          reject(error.code);
        });
    }),
    // Dispachers of aplication flow.
    NEXT_STATE: async ({ commit, state, dispatch }) => {
      let { flowState } = state.user;
      const { phases } = state.user;
      const HasCave = process.env.VUE_APP_HAS_CAVE === 'true';
      if (flowState === States.WELCOME) { // WELCOME
        videoService.show('onboarding.mp4');
        flowState = States.INTRO_ASSISTANT;
      } else if (flowState === States.INTRO_ASSISTANT) {
        flowState = States.PHASE_0_LISTEN_HISTORY;
      } else if (flowState === States.INTRO) {
        flowState = States.GUIDE_AFTER_INTRO;
      } else if (flowState === States.GUIDE_AFTER_INTRO) {
        flowState = States.PHASE_0_LISTEN_HISTORY;
      } else if (flowState === States.PHASE_0_LISTEN_HISTORY) { // PHASE 0
        flowState = States.BEFORE_PHASE_0;
      } else if (flowState === States.BEFORE_PHASE_0) {
        flowState = States.PHASE_0;
        chatService.notify('¡Hola! Soy Charlie, tu guía virtual personal, si me necesitas estoy aquí');
      } else if (flowState === States.PHASE_0) {
        if (HasCave && phases.phase_0.totalPoints < ValueRequiredToPassTheDoor) {
          flowState = States.CAVE;
          dispatch('ACTIVE_CAVE');
        } else {
          flowState = States.AFTER_AWARD_P0;
          videoService.show('award/phase_0.mp4');
        }
      } else if (flowState === States.AFTER_AWARD_P0) {
        flowState = States.PHASE_1_LISTEN_HISTORY;
      } else if (flowState === States.PHASE_1_LISTEN_HISTORY) { // PHASE 1
        flowState = States.BEFORE_PHASE_1;
      } else if (flowState === States.BEFORE_PHASE_1) {
        flowState = States.PHASE_1;
        videoService.show('animation_laberinto_1.mp4');
        chatService.notify('¿Cómo vas? Recuerda que estoy aquí para ayudarte en lo que necesites');
      } else if (flowState === States.PHASE_1) {
        if (HasCave && phases.phase_1.totalPoints < ValueRequiredToPassTheDoor) {
          flowState = States.CAVE;
          dispatch('ACTIVE_CAVE');
        } else {
          flowState = States.AFTER_AWARD_P1;
          videoService.show('award/phase_1.mp4');
        }
      } else if (flowState === States.AFTER_AWARD_P1) {
        flowState = States.PHASE_2_LISTEN_HISTORY;
      } else if (flowState === States.PHASE_2_LISTEN_HISTORY) { // PHASE 2
        flowState = States.BEFORE_PHASE_2;
      } else if (flowState === States.BEFORE_PHASE_2) {
        flowState = States.PHASE_2;
        videoService.show('animation_laberinto_2.mp4');
        chatService.notify('¡Animo!,¡Vas muy bien! Recuerda que estoy por aquí…');
      } else if (flowState === States.PHASE_2) {
        if (HasCave && phases.phase_2.totalPoints < ValueRequiredToPassTheDoor) {
          flowState = States.CAVE;
          dispatch('ACTIVE_CAVE');
        } else {
          videoService.show('award/phase_2.mp4');
          flowState = States.AFTER_AWARD_P2;
        }
      } else if (flowState === States.AFTER_AWARD_P2) {
        flowState = States.PHASE_3_LISTEN_HISTORY;
      } else if (flowState === States.PHASE_3_LISTEN_HISTORY) { // Phase 3
        flowState = States.BEFORE_PHASE_3;
      } else if (flowState === States.BEFORE_PHASE_3) {
        flowState = States.PHASE_3;
        videoService.show('animation_laberinto_3.mp4');
        chatService.notify('¡Guau! Esta puerta es muy liberadora, si se te resiste te puedo ayudar');
      } else if (flowState === States.PHASE_3) {
        if (HasCave && phases.phase_3.totalPoints < ValueRequiredToPassTheDoor) {
          flowState = States.CAVE;
          dispatch('ACTIVE_CAVE');
        } else {
          videoService.show('award/phase_3.mp4');
          flowState = States.AFTER_AWARD_P3;
        }
      } else if (flowState === States.AFTER_AWARD_P3) {
        flowState = States.PHASE_4_LISTEN_HISTORY;
      } else if (flowState === States.PHASE_4_LISTEN_HISTORY) { // Phase 4
        flowState = States.BEFORE_PHASE_4;
      } else if (flowState === States.BEFORE_PHASE_4) {
        flowState = States.PHASE_4;
        videoService.show('animation_laberinto_4.mp4');
        chatService.notify('¿Sabes el orgullo que tengo de verte en esta puerta?');
      } else if (flowState === States.PHASE_4) {
        if (HasCave && phases.phase_4.totalPoints < ValueRequiredToPassTheDoor) {
          flowState = States.CAVE;
          dispatch('ACTIVE_CAVE');
        } else {
          videoService.show('award/phase_4.mp4');
          flowState = States.AFTER_AWARD_P4;
        }
      } else if (flowState === States.AFTER_AWARD_P4) {
        dispatch('SHOW_WORLD_VIDEO');
        flowState = States.PHASE_5_LISTEN_HISTORY;
      } else if (flowState === States.PHASE_5_LISTEN_HISTORY) { // Phase 5
        flowState = States.BEFORE_PHASE_5;
      } else if (flowState === States.BEFORE_PHASE_5) {
        flowState = States.PHASE_5;
        videoService.show('animation_laberinto_5.mp4');
        chatService.notify('Ya estas terminando, ¡te queda lo mejor! ¡Celebrar!');
      } else if (flowState === States.PHASE_5) {
        flowState = States.AFTER_AWARD_P5;
      } else if (flowState === States.AFTER_AWARD_P5) {
        flowState = States.DOWNLOAD_LOGBOOK;
        videoService.show('animation_end_game.mp4');
      } else if (flowState === States.CAVE) { // CAVE
        if (HasCave && phases.temple.totalPoints < ValueRequiredToPassTheDoor) {
          flowState = States.CAVE_SUSPEND_MESSAGE;
          await dispatch('SUSPEND_CAVE');
        } else {
          flowState = States.CAVE_APPROVE_MESSAGE;
          await dispatch('APPROVE_CAVE');
        }
      } else if (flowState === States.CAVE_APPROVE_MESSAGE) {
        if (phases.temple.lastPhase.includes('0')) {
          flowState = States.PHASE_1_LISTEN_HISTORY;
          videoService.show('award/phase_0.mp4');
        } else if (phases.temple.lastPhase.includes('1')) {
          flowState = States.PHASE_2_LISTEN_HISTORY;
          videoService.show('award/phase_1.mp4');
        } else if (phases.temple.lastPhase.includes('2')) {
          flowState = States.PHASE_3_LISTEN_HISTORY;
          videoService.show('award/phase_2.mp4');
        } else if (phases.temple.lastPhase.includes('3')) {
          flowState = States.PHASE_4_LISTEN_HISTORY;
          videoService.show('award/phase_3.mp4');
        } else if (phases.temple.lastPhase.includes('4')) {
          flowState = States.PHASE_5_LISTEN_HISTORY;
          videoService.show('award/phase_4.mp4');
        } else if (phases.temple.lastPhase.includes('5')) {
          flowState = States.AFTER_AWARD_P5;
        }
        await dispatch('DESACTIVATE_CAVE');
      } else if (flowState === States.CAVE_SUSPEND_MESSAGE) {
        if (phases.temple.lastPhase.includes('0')) {
          flowState = States.PHASE_0_LISTEN_HISTORY;
          commit('SET_WISH', Wish.NO_HAS);
        } else if (phases.temple.lastPhase.includes('1')) {
          flowState = States.PHASE_0_LISTEN_HISTORY;
        } else if (phases.temple.lastPhase.includes('2')) {
          flowState = States.PHASE_1_LISTEN_HISTORY;
        } else if (phases.temple.lastPhase.includes('3')) {
          flowState = States.PHASE_2_LISTEN_HISTORY;
        } else if (phases.temple.lastPhase.includes('4')) {
          flowState = States.PHASE_3_LISTEN_HISTORY;
        } else if (phases.temple.lastPhase.includes('5')) {
          flowState = States.PHASE_4_LISTEN_HISTORY;
        }
        dispatch('DESACTIVATE_CAVE');
      } else if (flowState === States.DOWNLOAD_LOGBOOK) {
        videoService.show('award/final_celebration.mp4');
        flowState = States.END;
      }
      console.log('> action end: NEXT_STATE flowState', flowState);
      commit('NEXT_STATE', flowState);
    },
  },
  modules: {
    draftModule: DraftModule,
    fireStorageModule: FireStorageModule,
    fireAuthModule: FireAuthModule,
    userModule: UserModule,
    appConfigModule: AppConfigModule,
    fireAnalyticsModule: FireAnalyticsModule,
    versionHandlerModule: VersionHandlerModule,
  },
  plugins: [vuexLocalStorage.plugin],
});

<template>
    <div class="mt-10"  :key="componentId">
      <v-list dense>
        <v-chip v-for="(data, idx) in dataset"
                :key="idx"
                @click="() => selectedValue(idx)"
                :class="{
                  'selectable-chip selectable-chip--active': selected[idx] === true,
                  'selectable-chip': selected[idx] === false,
                }"
                class=""
                large>{{data}}</v-chip>
      </v-list>
      <div class="actions">
        <v-btn color="primary"
                x-large
                @click="next()"
                data-cy="btn-next-exercise"
                class="mt-10 mb-10" >Siguiente</v-btn>
      </div>
    </div>
</template>

<script>
export default {
  name: 'SelectableList',
  props: {
    dataset: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedItems: {
      type: Array,
      required: false,
    },
    min: {
      type: Number,
      required: true,
      default: 1,
    },
    max: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  data: () => ({
    componentId: 1,
    selected: [],
  }),
  watch: {
    selectedItems() {
      this.recheckItems();
    },
  },
  mounted() {
    this.recheckItems();
  },
  methods: {
    next() {
      const SelectedLabels = this.getTheSelectedLabels();
      const ItCompliesWithTheRules = SelectedLabels.length >= this.min
                                     && SelectedLabels.length <= this.max;
      if (ItCompliesWithTheRules) {
        this.resetTheSelectedItems();
        this.$emit('next', SelectedLabels);
      } else {
        this.alertViolationOfTheRules();
      }
    },
    recheckItems() {
      this.selected = this.dataset.map(() => false);
      if (this.selectedItems && this.selectedItems.length > 0) {
        for (let i = 0; i < this.dataset.length; i += 1) {
          const exist = this.selectedItems.find((item) => item === this.dataset[i]);
          if (exist) {
            this.selected[i] = true;
          }
        }
      }
    },
    selectedValue(idx) {
      this.selected[idx] = !this.selected[idx];
      this.componentId += 1;
    },
    alertViolationOfTheRules() {
      this.$swal({
        type: 'info',
        title: '¿Te Ayudo?',
        text: `Has de seleccionar ${this.min} valores, los que tú prefieras.`,
        confirmButtonColor: '#2297aa',
      });
    },
    getTheSelectedLabels() {
      return this.dataset.filter((item, idx) => this.selected[idx] === true);
    },
    resetTheSelectedItems() {
      this.selected = this.selected.map(() => false);
    },
  },
};
</script>

<style lang="scss" scoped>
div.divider {
    width: 100%
}

.selectable-chip {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
}

.selectable-chip--active {
  color: white;
  font-weight: 700;
  background-color: #EC661C !important;
}

span {
    white-space: pre-wrap !important;
}
</style>

<template>
  <v-container fluid>
    <v-row justify="space-around">
      <v-col cols="10" md="5" lg="3">
        <h4>Datos personales</h4>
        <span class="alert-birthdate-empty"
              v-if="birthdateAlert">Debes añadir la fecha de nacimiento.</span>
        <form>
            <InputGroup class="mt-4" title="Tu email">
                <input-validator-component
                        v-model="form.email"
                        type="email"
                        rules="required|email"
                        name="email"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-email"/>
            </InputGroup>
            <InputGroup class="mt-4" title="¿Cómo te llamas?">
                <input-validator-component
                        v-model="form.name"
                        type="name"
                        rules="required|string"
                        name="name"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-name"/>
            </InputGroup>

            <InputGroup class="mt-4" title="Tu primer apellido">
                <input-validator-component
                        v-model="form.firstSurname"
                        type="name"
                        rules="required|string"
                        name="firstSurname"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-firstSurname"/>
            </InputGroup>

            <InputGroup class="mt-4" title="Tu segundo apellido">
                <input-validator-component
                        v-model="form.secondSurname"
                        type="name"
                        rules="required|string"
                        name="secondSurname"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-secondSurname"/>
            </InputGroup>

            <InputGroup class="mt-4" title="¿Qué día naciste?">
                <v-row>
                  <v-col cols="2" class="mr-3">
                    <input-validator-component
                        v-model="form.birthdate.day"
                        placeholder="Día"
                        type="number"
                        rules="required|integer|between:1,31"
                        name="day"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-day"/>
                  </v-col>
                  <v-col cols="2" class="mr-3">
                    <input-validator-component
                        v-model="form.birthdate.month"
                        placeholder="Mes"
                        type="number"
                        rules="required|integer|between:01,12"
                        name="month"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-month"/>
                  </v-col>
                  <v-col cols="2">
                    <input-validator-component
                        v-model="form.birthdate.year"
                        type="number"
                        placeholder="Año"
                        rules="required|integer|between:1930,2002"
                        name="year"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-year"/>
                  </v-col>
                </v-row>
            </InputGroup>
        </form>

        <v-row justify="center">
          <v-btn color="primary"
                class="mt-4"
                data-cy="btn-update-account"
                :disabled="!isBasicFormValid"
                @click.prevent="updateAccount">Actualizar</v-btn>
        </v-row>
      </v-col>
      <v-col cols="11" md="5" lg="3">
        <h4>Contraseña</h4>
        <form>
            <InputGroup class="mt-4" title="Contraseña Actual">
                <input-validator-component
                        v-model="form.password"
                        type="password"
                        rules="required|string|min:8|max:100"
                        name="password"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-password"/>
            </InputGroup>

            <InputGroup class="mt-4" title="Tú Nueva Contraseña">
                <input-validator-component
                        v-model="form.newPassword"
                        type="password"
                        rules="required|string|min:8|max:100"
                        name="newPassword"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-new-password"/>
            </InputGroup>

            <InputGroup class="mt-4" title="Repite Tú Nueva Contraseña">
                <input-validator-component
                        v-model="form.repeatNewPassword"
                        type="password"
                        rules="required|string|min:8|max:100"
                        name="repeatNewPassword"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"
                        data-cy="input-repeat-new-password"/>
            </InputGroup>
          </form>

          <v-row justify="center">
            <v-btn color="primary"
                  class="mt-4"
                  data-cy="btn-update-password"
                  :disabled="!isPaswordFormValid"
                  @click.prevent="updatePassword">Actualizar</v-btn>
          </v-row>
      </v-col>

      <v-col cols="11" md="5" lg="3">
        <h4>Darse de baja</h4>
        <form>
          <InputGroup class="mt-4" title="Contraseña Actual">
            <input-validator-component
                      v-model="form.passwordDeleteAccount"
                      type="password"
                      rules="required|string|min:8|max:100"
                      name="passwordDeleteAccount"
                      @status-change="onValidationStatusChange"
                      @error="onValidationError"
                      data-cy="input-password-delete-account"/>
            </InputGroup>
          </form>

          <v-row justify="center">
            <v-btn color="primary"
                  class="mt-4"
                  data-cy="btn-update-password"
                  :disabled="!isDeleteAccountValid"
                  @click.prevent="deleteAccount">Darse de baja</v-btn>
          </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import LoaderService from '../services/loader.service';
import MenuService from '../services/menu.service';
import InputGroup from '../components/InputGroup.vue';
import InputValidatorComponent from '../components/InputValidatorComponent.vue';
import alertMixin from '../mixins/alert';
import validationsMixin from '../mixins/validations';

export default {
  name: 'Account',
  components: { InputValidatorComponent, InputGroup },
  mixins: [alertMixin, validationsMixin],
  data: () => ({
    birthdateAlert: false,
    form: {
      passwordDeleteAccount: '',
      password: '',
      newPassword: '',
      repeatNewPassword: '',
      email: '',
      name: '',
      firstSurname: '',
      secondSurname: '',
      birthdate: {
        day: '',
        month: '',
        year: '',
      },
    },
    validation: {
      success: {
        passwordDeleteAccount: false,
        password: false,
        newPassword: false,
        repeatNewPassword: false,
        email: false,
        name: false,
        firstSurname: false,
        secondSurname: false,
        day: false,
        month: false,
        year: false,
      },
      errors: {},
    },
  }),
  created() {
    MenuService.show('Perfil', 'back', () => this.$router.replace('/home'));
    this.form = {
      email: this.user.email,
      name: this.user.name,
      firstSurname: this.user.firstSurname,
      secondSurname: this.user.secondSurname,
    };
    if (
      !this.user.birthdate
      || (
        this.user.birthdate
        && !this.user.birthdate.includes('undefined')
      )
    ) {
      const [year, month, day] = this.user.birthdate.split('-');
      this.form.birthdate = {
        day,
        month,
        year,
      };
    } else {
      this.birthdateAlert = true;
      this.form.birthdate = {
        day: '',
        month: '',
        year: '',
      };
    }
  },
  methods: {
    updateAccount() {
      LoaderService.show();
      this.form.birthdate = `${this.form.birthdate.year}-${this.form.birthdate.month}-${this.form.birthdate.day}`;
      this.$store
        .dispatch('UPDATE_USER', this.form)
        .then(() => {
          LoaderService.hidden();
          this.$swal({
            type: 'success',
            title: 'Estupendo!',
            text: 'Acabas de actualizar tu perfil con éxito.',
            confirmButtonColor: '#2297aa',
          });
        })
        .catch((error) => {
          this.authAlerts(error);
          LoaderService.hidden();
        });
    },
    updatePassword() {
      if (
        this.form.newPassword !== ''
        && this.form.repeatNewPassword !== ''
        && this.form.newPassword === this.form.repeatNewPassword) {
        LoaderService.show();
        const dataset = {
          password: this.form.password,
          newPassword: this.form.newPassword,
        };
        this.$store
          .dispatch('UPDATE_USER_PASSWORD', dataset)
          .then(() => {
            LoaderService.hidden();
            this.$swal({
              type: 'success',
              title: 'Estupendo!',
              text: 'Acabas de actualizar tu contraseña con éxito.',
              confirmButtonColor: '#2297aa',
            });
          })
          .catch((error) => {
            this.authAlerts(error);
            LoaderService.hidden();
          });
      } else {
        this.$swal({
          type: 'warning',
          title: 'Oops…',
          text: 'Las contraseñas deben ser iguales',
          confirmButtonColor: '#2297aa',
        });
      }
    },
    deleteAccount() {
      const menssage = {
        type: 'question',
        title: '¿Estás seguro que deseas darte de baja?',
        confirmButtonText: 'Estoy seguro',
        cancelButtonText: 'No',
        showCancelButton: true,
        confirmButtonColor: '#ff6400',
        cancelButtonColor: '#2297aa',
      };
      this.$swal(menssage).then((result) => {
        if (result.value) {
          const dataset = {
            password: this.form.passwordDeleteAccount,
            email: this.user.email,
          };
          this.$store.dispatch('REAUTH_ACCOUNT', dataset).then(() => {
            this.$store.dispatch('DELETE_ACCOUNT');
          });
        }
      });
    },
  },
  computed: {
    isBasicFormValid() {
      return this.validation.success.email
             && this.validation.success.name
             && this.validation.success.firstSurname
             && this.validation.success.secondSurname
             && this.validation.success.day
             && this.validation.success.month
             && this.validation.success.year;
    },
    isPaswordFormValid() {
      return this.validation.success.password
             && this.validation.success.newPassword
             && this.validation.success.repeatNewPassword;
    },
    isDeleteAccountValid() {
      return this.validation.success.passwordDeleteAccount;
    },
    ...mapState([
      'user',
    ]),
  },
};
</script>

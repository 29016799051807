import firebase from 'firebase';

const UserModule = {
  actions: {
    CAN_ACCESS: (contex, email) => new Promise((resolve, reject) => {
      firebase.firestore()
        .collection('can-access')
        .where('email', '==', email)
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) reject(new Error('unauth/email').message);
          else resolve(true);
        })
        .catch(reject);
    }),
    GET_USER_DATA: ({ commit }, userEmail) => new Promise((resolve, reject) => {
      const getUserDataIfExist = (querySnapshot) => {
        if (querySnapshot.empty) throw new Error('auth/user-not-found');

        const [document] = querySnapshot.docs;
        return {
          docId: document.ref.id,
          ...document.data(),
        };
      };

      const commitAndResolve = (user) => {
        commit('SET_USER', user);
        resolve(user);
      };

      firebase.firestore()
        .collection('users')
        .where('email', '==', userEmail)
        .get()
        .then(getUserDataIfExist)
        .then(commitAndResolve)
        .catch((error) => reject(error.code));
    }),
    DELETE_ACCOUNT: ({ rootState, commit }) => {
      const { user } = rootState;
      const { docId } = user;
      firebase.firestore()
        .doc(`users/${docId}`)
        .delete()
        .then(() => {
          firebase.auth().currentUser.delete()
            .then(() => {
              commit('RESET_USER');
              commit('SET_DRAFT', null);
              window.location.reload(true);
            });
        });
    },
  },
};

export default UserModule;

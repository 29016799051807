export default {
  methods: {
    onValidationStatusChange(name, value) {
      this.validation.success[name] = value;
      if (value === false) {
        this.validation.validForm = false;
      }
    },
    onValidationError(name, value) {
      this.validation.errors[name] = value;
    },
  },
  computed: {
    isFormValid() {
      const objKeys = Object.keys(this.validation.success);
      for (let i = 0; i < objKeys.length; i += 1) {
        if (!this.validation.success[objKeys[i]]) {
          return false;
        }
      }
      return true;
    },
  },
};

const States = {
  WELCOME: 0,

  INTRO: 100,
  GUIDE_AFTER_INTRO: 101,
  INTRO_ASSISTANT: 102,

  CAVE: 200,
  CAVE_TOTAL_EVALUATION: 201,
  CAVE_SUSPEND_MESSAGE: 202,
  CAVE_APPROVE_MESSAGE: 203,

  PHASE_0: 300,
  PHASE_0_LISTEN_HISTORY: 302,
  PHASE_0_ONLY_LISTEN_HISTORY: 304,
  BEFORE_PHASE_0: 305,
  AFTER_AWARD_P0: 306,

  PHASE_1: 400,
  PHASE_1_SHOW_MOTIVATION_TEXT: 401,
  PHASE_1_LISTEN_HISTORY: 402,
  BEFORE_PHASE_1: 405,
  AFTER_AWARD_P1: 406,

  PHASE_2: 500,
  PHASE_2_SHOW_MOTIVATION_TEXT: 501,
  PHASE_2_LISTEN_HISTORY: 502,
  BEFORE_PHASE_2: 505,
  AFTER_AWARD_P2: 506,

  PHASE_3: 600,
  PHASE_3_SHOW_MOTIVATION_TEXT: 601,
  PHASE_3_LISTEN_HISTORY: 602,
  BEFORE_PHASE_3: 605,
  PHASE_3_QUESTION_YOU_HAVE_BLOCKAGES: 606,
  PHASE_3_QUESTION_TONE_DOWN_EMOTION: 607,
  AFTER_AWARD_P3: 608,

  PHASE_4: 700,
  PHASE_4_SHOW_MOTIVATION_TEXT: 701,
  PHASE_4_LISTEN_HISTORY: 702,
  BEFORE_PHASE_4: 705,
  AFTER_AWARD_P4: 706,

  PHASE_5: 800,
  PHASE_5_SHOW_MOTIVATION_TEXT: 802,
  PHASE_5_LISTEN_HISTORY: 803,
  BEFORE_PHASE_5: 805,
  AFTER_AWARD_P5: 806,

  DOWNLOAD_LOGBOOK: 901,
  END: 999,
};

export default States;

<template>
  <v-row justify="center" align="center">
    <v-col cols="11" lg="6">
      <form class="mt-10 inputs-container">
        <InputGroup title="Tu email" class="mt-4">
          <input-validator-component
                  v-model="form.email"
                  type="email"
                  rules="required|email|min:10|max:100"
                  name="email"
                  data-cy="input-email"
                  @status-change="onValidationStatusChange"
                  @error="onValidationError"/>
        </InputGroup>
        <InputGroup title="Tú Contraseña" class="mt-4">
          <input-validator-component
                  v-model="form.password"
                  type="password"
                  rules="required|string|min:8|max:100"
                  name="password"
                  data-cy="input-password"
                  @status-change="onValidationStatusChange"
                  @error="onValidationError"/>
        </InputGroup>
      </form>
      <v-row justify="end">
        <router-link to="/reques-reset-password">Olvidé mi contraseña</router-link>
      </v-row>
      <v-row justify="center" class="mt-4">
        <v-btn color="primary"
              data-cy="btn-access"
              :disabled="!isFormValid || isDisabledSubmitButton"
              @click.prevent="login"
              x-large>Acceder</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import MenuService from '../services/menu.service';
import InputGroup from '../components/InputGroup.vue';
import alertMixin from '../mixins/alert';
import validationsMixin from '../mixins/validations';
import InputValidatorComponent from '../components/InputValidatorComponent.vue';
import auth from '../mixins/auth';

export default {
  name: 'Login',
  components: { InputValidatorComponent, InputGroup },
  mixins: [alertMixin, validationsMixin, auth],
  data: () => ({
    isDisabledSubmitButton: false,
    form: {
      email: '',
      password: '',
    },
    validation: {
      success: {
        email: false,
        password: false,
      },
      errors: {},
    },
  }),
  created() {
    MenuService.show(
      'Acceso',
      'back',
      () => this.$router.replace('access'),
    );
  },
  methods: {
    login() {
      this.disableSubmitButton();
      this.$store
        .dispatch('LOGIN_USER', this.form)
        .then(this.goToHome)
        .catch(this.errorHandler)
        .finally(this.finallyHandler);
    },
    goToHome() {
      this.$router.replace('/home');
    },
    errorHandler(error) {
      const { isAxiosError, response } = error;
      let code;
      if (isAxiosError) {
        code = response.data.code;
      } else {
        code = error.code;
      }
      this.authAlerts(code);
    },
    finallyHandler() {
      this.enableSubmitButton();
    },
    enableSubmitButton() {
      this.isDisabledSubmitButton = false;
    },
    disableSubmitButton() {
      this.isDisabledSubmitButton = true;
    },
  },
};
</script>

<style scoped lang="scss">
a {
  color: $primary;
  font-weight: 600;
  text-decoration: none;
  justify-self: flex-start;
  align-self: flex-end;
  margin-top: 5% !important;
}
</style>

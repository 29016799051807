import States from './states.enum';

const LabyrinthFromStates = {
  [States.PHASE_0]: `${process.env.BASE_URL}images/labyrinth/phase_0.png`,
  [States.PHASE_1]: `${process.env.BASE_URL}images/labyrinth/phase_1.png`,
  [States.PHASE_2]: `${process.env.BASE_URL}images/labyrinth/phase_2.png`,
  [States.PHASE_3]: `${process.env.BASE_URL}images/labyrinth/phase_3.png`,
  [States.PHASE_4]: `${process.env.BASE_URL}images/labyrinth/phase_4.png`,
  [States.PHASE_5]: `${process.env.BASE_URL}images/labyrinth/phase_5.png`,
};

export default LabyrinthFromStates;

import Vue from 'vue';
import firebase from 'firebase';
import Router from 'vue-router';
import store from '../store';
import Home from '../../views/Home.vue';
import Access from '../../views/Access.vue';
import Login from '../../views/Login.vue';
import Signup from '../../views/Signup.vue';
import Account from '../../views/Account.vue';
import Resources from '../../views/resources/Resources.vue';
import Challenges from '../../views/Challenges.vue';
import SeeHistory from '../../views/SeeHistory.vue';
import Activities from '../../views/Activities.vue';
import Welcome from '../../views/Welcome.vue';
import Evaluation from '../../views/Evaluation.vue';
import Cave from '../../views/Cave.vue';
import States from '../../model/states.enum';
import LoaderService from '../../services/loader.service';
import Messages from '../../views/Messages.vue';
import Guide from '../../views/guide/view.vue';
import UseGuideView from '../../views/guide/use-guide-view.vue';
import Question from '../../views/Question.vue';
import PlanningView from '../../views/PlanningView.vue';
import RequestResetPassword from '../../views/RequestResetPassword.vue';
import Travel from '../../views/Travel.vue';
import MandalaView from '../../views/MandalaView.vue';
import End from '../../views/End.vue';
import EndGameView from '../../views/guide/end-game-view.vue';
import AssistantChatAgreement from '../../views/AssistantChatAgreement.vue';

// Clean redirect error indicator.
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject);
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/access',
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/',
      redirect: '/access',
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/access',
      name: 'access',
      component: Access,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/account',
      name: 'account',
      component: Account,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/resources',
      name: 'resources',
      component: Resources,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/evaluation/:challenge/:phase',
      name: 'evaluation',
      component: Evaluation,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/see/history/:id',
      name: 'seeHistory',
      component: SeeHistory,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/cave',
      name: 'cave',
      component: Cave,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/phase/:id',
      name: 'phase',
      component: Challenges,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/motivation',
      name: 'motivation',
      component: Messages,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/guide',
      name: 'guide',
      component: Guide,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/guide/before/signup',
      name: 'guide/before/signup',
      component: UseGuideView,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/end-game',
      name: 'end-game',
      component: EndGameView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/activities/:phase/:challenge',
      name: 'activities',
      component: Activities,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/question',
      name: 'question',
      component: Question,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/planning',
      name: 'planning',
      component: PlanningView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/mandala',
      name: 'mandala',
      component: MandalaView,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/reques-reset-password',
      name: 'reques-reset-password',
      component: RequestResetPassword,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/path',
      name: 'path',
      component: Travel,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/end',
      name: 'end',
      component: End,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/intro-assistant',
      name: 'intro-assistant',
      component: AssistantChatAgreement,
      meta: {
        requiresAuth: true,
      },
    },
  ],
});

function isNotAMenuOption(to) {
  return !to.fullPath.includes('/mandala')
         && !to.fullPath.includes('/account')
         && !to.fullPath.includes('/resources')
         && !to.fullPath.includes('/path')
         && !to.fullPath.includes('/planning')
         && !to.fullPath.includes('/intro-assistant');
}

router.beforeEach((to, from, next) => {
  LoaderService.show();
  const UserAuth = firebase.auth().currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth === true && !UserAuth) next({ path: '/access', replace: true });
  else if (requiresAuth === false && UserAuth) {
    const IsInvalidRoute = to.name === 'signup'
      || to.name === 'login'
      || to.name === 'access'
      || to.name === 'reques-reset-password';
    if (IsInvalidRoute) {
      next({ path: '/home', replace: true });
    } else {
      next();
    }
  } else if (requiresAuth === true && UserAuth) {
    const User = store.getters.getUser;
    const IsNotAMenuOption = isNotAMenuOption(to);
    if (
      User.flowState === States.DOWNLOAD_LOGBOOK
      && !to.fullPath.includes('/end')
      && IsNotAMenuOption
    ) {
      next({ path: '/end', replace: true });
    } else if (
      User.flowState === States.END
      && !to.fullPath.includes('/end-game')
      && IsNotAMenuOption
    ) {
      next({ path: '/end-game', replace: true });
    } else if (
      User.flowState === States.CAVE
      && !to.fullPath.includes('/cave')
      && !to.fullPath.includes('/evaluation/5/cave')
      && !to.fullPath.includes('/evaluation/6/cave')
      && !to.fullPath.includes('/evaluation/total/cave')
      && IsNotAMenuOption
    ) {
      const STATE_FIRST_EVELUATION = 4;
      const STATE_SECOND_EVELUATION = 10;
      if (
        User.phases.temple.flowState === STATE_FIRST_EVELUATION
        && User.phases.temple.totalPoints
      ) {
        next({ path: '/evaluation/5/cave', replace: true });
      } else if (
        User.phases.temple.flowState === STATE_SECOND_EVELUATION
        && User.phases.temple.totalPoints
      ) {
        next({ path: '/evaluation/6/cave', replace: true });
      } else {
        next({ path: '/cave', replace: true });
      }
    } else if (
      User.flowState === States.CAVE_TOTAL_EVALUATION
      && !to.fullPath.includes('/evaluation/')
    ) {
      next({ path: '/evaluation/total/cave', replace: true });
    } else if (
      (
        User.flowState === States.CAVE_SUSPEND_MESSAGE
        || User.flowState === States.CAVE_APPROVE_MESSAGE
      ) && !to.fullPath.includes('/motivation')
    ) {
      next({ path: '/motivation', replace: true });
    } else if (
      User.flowState === States.INTRO_ASSISTANT
      && !to.fullPath.includes('/intro-assistant')
      && IsNotAMenuOption
    ) {
      next({ path: '/intro-assistant', replace: true });
    } else if (
      User.flowState === States.INTRO
      && !to.fullPath.includes('/activities/intro/1')
      && IsNotAMenuOption
    ) {
      next({ path: '/activities/intro/1', replace: true });
    } else if (
      User.flowState === States.WELCOME
      && to.fullPath !== '/welcome'
    ) {
      next({ path: '/welcome', replace: true });
    } else if (
      (
        User.flowState === States.GUIDE_AFTER_INTRO
        || User.flowState === States.BEFORE_PHASE_0
        || User.flowState === States.BEFORE_PHASE_1
        || User.flowState === States.BEFORE_PHASE_2
        || User.flowState === States.BEFORE_PHASE_3
        || User.flowState === States.BEFORE_PHASE_4
        || User.flowState === States.BEFORE_PHASE_5
        || User.flowState === States.AFTER_AWARD_P0
        || User.flowState === States.AFTER_AWARD_P1
        || User.flowState === States.AFTER_AWARD_P2
        || User.flowState === States.AFTER_AWARD_P3
        || User.flowState === States.AFTER_AWARD_P4
        || User.flowState === States.AFTER_AWARD_P5
      )
      && to.fullPath !== '/guide'
    ) {
      next({ path: '/guide', replace: true });
    } else if (
      (
        User.flowState === States.PHASE_3_QUESTION_YOU_HAVE_BLOCKAGES
        || User.flowState === States.PHASE_3_QUESTION_TONE_DOWN_EMOTION
      ) && to.fullPath !== '/question'
    ) {
      if (from.fullPath === '/question') {
        next(false);
        LoaderService.hidden();
      } else {
        next({ path: '/question', replace: true });
      }
    } else if (
      (
        User.flowState === States.PHASE_0_LISTEN_HISTORY
        || User.flowState === States.PHASE_0_ONLY_LISTEN_HISTORY
      )
      && !to.fullPath.includes('/see/history/0')
      && !to.fullPath.includes('/activities/intro/1')
      && IsNotAMenuOption
    ) {
      next({ path: '/see/history/0', replace: true });
    } else if ( // Go to See history of phase 1
      User.flowState === States.PHASE_1_LISTEN_HISTORY
      && !to.fullPath.includes('/see/history/1')
      && IsNotAMenuOption
    ) {
      next({ path: '/see/history/1', replace: true });
    } else if ( // Go to See history of phase 2
      User.flowState === States.PHASE_2_LISTEN_HISTORY
      && !to.fullPath.includes('/see/history/2')
      && IsNotAMenuOption
    ) {
      next({ path: '/see/history/2', replace: true });
    } else if ( // Go to See history of phase 3
      User.flowState === States.PHASE_3_LISTEN_HISTORY
      && !to.fullPath.includes('/see/history/3')
      && IsNotAMenuOption
    ) {
      next({ path: '/see/history/3', replace: true });
    } else if ( // Go to See history of phase 4
      User.flowState === States.PHASE_4_LISTEN_HISTORY
      && !to.fullPath.includes('/see/history/4')
      && IsNotAMenuOption
    ) {
      next({ path: '/see/history/4', replace: true });
    } else if ( // Go to See history of phase 5
      User.flowState === States.PHASE_5_LISTEN_HISTORY
      && !to.fullPath.includes('/see/history/5')
      && IsNotAMenuOption
    ) {
      next({ path: '/see/history/5', replace: true });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  LoaderService.hidden();
});

export default router;

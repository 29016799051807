import firebase from 'firebase';

const FireAnalyticsModule = {
  actions: {
    LOG_EVENT: (context, { eventName }) => {
      firebase.analytics().logEvent(eventName);
    },
    LOG_SESSION_DURATION_IN_EXERCISES: (context, parameters) => {
      const { currentUser } = firebase.auth();
      if (!currentUser) return;
      firebase.firestore().collection('session-duration').add(parameters);
    },
  },
};

export default FireAnalyticsModule;

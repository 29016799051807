import { BehaviorSubject } from 'rxjs';

const chatSubject = new BehaviorSubject({ visible: false, context: null });

export default {
  show: (context = null) => chatSubject.next({ show: true, context }),
  hidden: () => chatSubject.next({ show: false, context: null }),
  notify: (message) => chatSubject.next({ notify: true, message }),
  getChatObservable: () => chatSubject.asObservable(),
};

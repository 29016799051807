<template>
  <v-row justify="center">
    <v-col cols="12" xl="8">
        <v-tabs v-model="tab"
                centered
                show-arrows>
            <v-tab href="#documents">Documentos</v-tab>
            <v-tab href="#images">Imagenes</v-tab>
            <v-tab href="#audio">Audio</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item value="documents">
                <DocumentResources />
            </v-tab-item>
            <v-tab-item value="images">
                <ImageResources/>
            </v-tab-item>
            <v-tab-item value="audio">
                <AudioResources/>
            </v-tab-item>
        </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import DocumentResources from './DocumentResources.vue';
import ImageResources from './ImageResources.vue';
import AudioResources from './AudioResources.vue';
import MenuService from '../../services/menu.service';

export default {
  name: 'Resources',
  components: { DocumentResources, ImageResources, AudioResources },
  created() {
    MenuService.show('RECURSOS', 'back', () => this.$router.replace('/home'));
  },
  data: () => ({
    tab: null,
    tabs: 2,
  }),
};
</script>

<style>

</style>

export default {
  methods: {
    downloadDocuments(docs) {
      let timeDownload = 0;
      docs.forEach(async (doc) => {
        setTimeout(() => this.download(doc), timeDownload);
        timeDownload += 1000;
      });
    },
    buildUniqueString() {
      const UniqueString = Math.random().toString(36).substr(2, 9);
      return `${UniqueString}`;
    },
    getFileName(path, extension) {
      const PathWitoutExtension = path.replace(extension, '');
      const SLASH_SYMBOL = '/';
      const PathLenght = PathWitoutExtension.lenght;
      const LastIndeOfSlash = PathWitoutExtension.lastIndexOf(SLASH_SYMBOL);
      const FileName = PathWitoutExtension.slice(LastIndeOfSlash + 1, PathLenght);
      const UniqueString = this.buildUniqueString();
      return `${FileName}_${UniqueString}${extension}`;
    },
    download(url) {
      const link = document.createElement('a');
      let urlTarget = url;
      // TODO: Clean this code smells.
      let extension = '.pdf';
      if (url.includes('.png')) extension = '.png';
      else if (url.includes('.jpg')) extension = '.jpg';
      else if (url.includes('.jpeg')) extension = '.jpeg';

      const URL_IMAGE = 'https://www.charlieelemprendedor.com/pwa_charlie_assets/';

      const IncludesSymbols = url.includes('symbols');
      const IncludesImages = url.includes('images');
      const BaseURL = process.env.BASE_URL || '/';
      if ( // IMAGE PNG
        urlTarget.includes(URL_IMAGE)
        && extension === '.png'
      ) {
        urlTarget = urlTarget.replace(URL_IMAGE, `${BaseURL}images/`);
      } else if ( // PDF
        urlTarget.includes(URL_IMAGE)
        && extension === '.pdf'
      ) {
        urlTarget = urlTarget.replace(URL_IMAGE, `${BaseURL}pdf_documents/`);
      } else if (
        IncludesSymbols && !IncludesImages
      ) {
        urlTarget = `${BaseURL}images/${url}`;
      } else if (
        !urlTarget.includes(URL_IMAGE)
      ) {
        urlTarget = `${BaseURL}${urlTarget}`;
      }
      const fileName = this.getFileName(urlTarget, extension);

      link.href = urlTarget;
      link.setAttribute('target', '_blank');
      link.setAttribute('download', fileName);
      link.click();
      this.showInformationMessage();
    },
    showInformationMessage() {
      this.$swal({
        type: 'info',
        title: 'Archivo descargado',
        text: 'Se acaba de descargar un archivo, revisa tu barra de notificaciones o busca en la carpeta de descargas.',
        confirmButtonText: 'De acuerdo',
        confirmButtonColor: '#2297aa',
      });
    },
  },
};

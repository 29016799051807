const BasePath = 'audios/stories/';

const AudioStories = {
  phase_0: `${BasePath}phase_0.wav`,
  phase_1: `${BasePath}phase_1.wav`,
  phase_2: `${BasePath}phase_2.wav`,
  phase_3: `${BasePath}phase_3.wav`,
  phase_4: `${BasePath}phase_4.wav`,
  phase_5: `${BasePath}phase_5.wav`,
};
const ReadingStories = {
  phase_0: 'pdf_documents/stories/puerta_0_soñar.pdf',
  phase_1: 'pdf_documents/stories/puerta_1_desear.pdf',
  phase_2: 'pdf_documents/stories/puerta_2_crear.pdf',
  phase_3: 'pdf_documents/stories/puerta_3_determinar.pdf',
  phase_4: 'pdf_documents/stories/puerta_4_materializar.pdf',
  phase_5: 'pdf_documents/stories/puerta_5_aprender.pdf',
};

export {
  AudioStories,
  ReadingStories,
};

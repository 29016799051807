<template>
  <CardWithSymbols backgroundColor="secondary" class="fill-height">
    <template slot="messages">
      <h1>BIENVENID@</h1>
      <br>
      <h5>ESTAS A PUNTO DE COMENZAR UNA AVENTURA MARAVILLOSA</h5>
      <br>
      <h3>¿ESTAS PREPARAD@?</h3>
      <br>
    </template>

    <template slot="actions">
      <v-btn @click.prevent="next"
            color="primary"
            class="btn--outlined"
            data-cy="btn-continue">Continuar</v-btn>
    </template>
  </CardWithSymbols>
</template>

<script>
import MenuService from '../services/menu.service';
import CardWithSymbols from '../components/CardWithSymbols.vue';

export default {
  name: 'Welcome',
  components: { CardWithSymbols },
  created() {
    MenuService.hidden();
  },
  methods: {
    next() {
      this.$store
        .dispatch('NEXT_STATE')
        .then(() => this.$router.replace('/guide'));
    },
  },
};
</script>

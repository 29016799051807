import Vue from 'vue';
import bugsnag from '@bugsnag/js';
import bugsnagVue from '@bugsnag/plugin-vue';
import firebase from 'firebase';
import VueMasonry from 'vue-masonry-css';
import VueMarkdown from 'vue-markdown';
import VueSocialSharing from 'vue-social-sharing';
import VueSweetalert2 from 'vue-sweetalert2';
import EvaIcons from 'vue-eva-icons';
import VueLazyload from 'vue-lazyload';
import App from './App.vue';
import router from './plugins/router';
import store from './plugins/store';
import FirebaseConfig from './plugins/firebase/conf';
import 'sweetalert2/dist/sweetalert2.min.css';
import vuetify from './plugins/vuetify';
import './services/beforeunload.service';
// import './registerServiceWorker';

console.log(
  process.env.VUE_APP_MODELO,
  process.env.VUE_APP_FIRE_PROJECT_ID,
);

const bugsnagClient = bugsnag({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  appVersion: process.env.APP_VERSION,
  autoBreadcrumbs: true,
  releaseStage: process.env.VUE_APP_MODELO,
});

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(EvaIcons);
Vue.use(VueMarkdown);
Vue.use(VueSocialSharing);
Vue.use(VueLazyload);
Vue.use(VueMasonry);
bugsnagClient.use(bugsnagVue, Vue);

firebase.initializeApp(FirebaseConfig);
// firebase.analytics();
let vueApp;
firebase.auth()
  .onAuthStateChanged((user) => {
    if (!vueApp) {
      vueApp = new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
      }).$mount('#app');
    }

    if (user) {
      store.dispatch('GET_USER_DATA', user.email);
    }
  });

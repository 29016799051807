import { Subject } from 'rxjs';

const subject = new Subject();

export default {
  /**
   * @param {string} title
   * @param {'menu' | 'back'} action
   * @param {function} actionCallback
   * @return {void}
  */
  show: (title, action, actionCallback) => subject.next({ title, action, actionCallback }),
  /**
   * @return {void}
  */
  hidden: () => subject.next(null),
  /**
   * @return {Observable}
  */
  getMenuObservable: () => subject.asObservable(),
};

<template>

  <v-row justify="center" align="center">
    <v-col cols="11" lg="6">
      <form class="inputs-container">
          <InputGroup title="Tu email">
              <input-validator-component
                      v-model="form.email"
                      type="email"
                      rules="required|email"
                      name="email"
                      @status-change="onValidationStatusChange"
                      @error="onValidationError"
                      data-cy="input-email"/>
          </InputGroup>
      </form>
      <v-row justify="center" class="mt-10">
        <v-btn @click.prevent="request"
               :disabled="!isFormValid"
               outlined
               color="primary"
               data-cy="btn-request-reset-password">Solicitar</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import MenuService from '../services/menu.service';
import InputGroup from '../components/InputGroup.vue';
import InputValidatorComponent from '../components/InputValidatorComponent.vue';
import alertMixin from '../mixins/alert';
import validationsMixin from '../mixins/validations';

export default {
  name: 'RequestResetPassword',
  components: { InputValidatorComponent, InputGroup },
  mixins: [alertMixin, validationsMixin],
  data: () => ({
    form: {
      email: '',
    },
    validation: {
      success: {
        email: false,
      },
      errors: {},
    },
  }),
  created() {
    MenuService.show('Recuperar', 'back', () => this.$router.replace('login'));
  },
  methods: {
    request() {
      this.$store.dispatch('REQUEST_RESET_PASSWORD', this.form)
        .then(() => {
          this.$swal({
            type: 'success',
            text: 'Te he enviado un enlace a tu email para resetear tu contraseña, asegúrate de revisar la carpeta de spam, por si acaso.',
            confirmButtonColor: '#2297aa',
          });
        }).catch(() => {
          this.$swal({
            type: 'error',
            title: 'Upps!',
            text: 'Parece que algo no va bien, por favor, vuelve a intentarlo más tarde.',
            confirmButtonColor: '#2297aa',
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  display: flex;
  flex-direction: column;
  h1 {
    align-self: center;
  }
  button {
    align-self: center;
    margin-top: 8%;
    margin-bottom: 8%;
  }
}
.inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  div.input-group {
    margin-top: 8%;
    padding: .3em;
  }
}

.input-birthdate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    width: 25%;
  }
}
</style>

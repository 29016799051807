<template>
  <v-row v-if="activeLoader"
         class="modal"
         justify="center"
         align="center"
         data-test="loaderElement">
    <img width="40%" src="../assets/logo.png">
  </v-row>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    activeLoader: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  outline: 0;
  z-index: 1072;

  background-color: white;

  img {
    max-width: 200px;
  }
}
</style>

<template>
  <transition
        enter-active-class="animated fadeInRight"
        leave-active-class="animated fadeOutLeft">
    <div v-if="active"
         class="exercise-modal">
      <div class="actions w-100 h-total">
        <img width="30%" src="../../../assets/images/characters/charlie/charlie_face.png">
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'cover-component',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">

.exercise-modal {
  z-index: 1000;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 0%;
  left: 0%;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 0;
  overflow: hidden;
}

.h-total {
  height: 100%;
}

div.actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>

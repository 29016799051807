<template>
  <radar-chart :chart-data="chartData" :options="options"></radar-chart>
</template>

<script>
import RadarChart from './RadarChart.vue';

export default {
  name: 'ScoreTableRadarChart',
  components: { RadarChart },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartData: null,
    options: {
      responsive: true,
    },
  }),
  created() {
    this.chartData = {
      labels: this.exercise.dataset.map((item) => item.title),
      datasets: this.getChartData(),
    };
  },
  methods: {
    getChartData() {
      const lastQuestions = this.exercise.dataset.map((item) => {
        const lastQuestion = item.questions[item.questions.length - 1];
        return {
          howIFeel: lastQuestion.howIFeel,
          howDoIWantToSeeMe: lastQuestion.howDoIWantToSeeMe,
        };
      });
      return [
        {
          label: 'COMO ME SIENTO',
          borderColor: '#efbd50',
          pointBackgroundColor: 'rgba(179,181,198,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          data: lastQuestions.map((question) => question.howIFeel),
          backgroundColor: [
            'rgba(255, 100, 0, 1)', // Naranja
            'rgba(239, 189, 80, 1)', // Amarillo
            'rgba(34, 151, 170, 1)', // Azul
            'rgba(0, 128, 0, 1)', // Verde
            'rgba(255, 0, 0, 1)', // Rojo
            'rgba(31, 31, 31, 1)', // Negro
          ],
        },
        {
          label: 'COMO QUIERO VERME',
          borderColor: '#2297aa',
          pointBackgroundColor: 'rgba(255,99,132,1)',
          pointBorderColor: '#fff',
          pointHoverBackgroundColor: '#fff',
          pointHoverBorderColor: 'rgba(179,181,198,1)',
          data: lastQuestions.map((question) => question.howDoIWantToSeeMe),
        },
      ];
    },
  },
};
</script>

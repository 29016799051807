import States from './states.enum';
import Wish from './wish.enum';

export default ({
  email, name, firstSurname,
  secondSurname, birthdate, law,
  gender,
}) => ({
  email,
  name,
  firstSurname,
  secondSurname,
  birthdate,
  gender,
  law,
  flowState: States.WELCOME,
  flow: [],
  hasWish: Wish.UNKNOW,
  registeredAt: Date.now(),
  currentRoute: {
    steps: [],
    currentStep: null,
  },
  phases: {
    intro: {},
    phase_0: {
      theatre: false,
      impulse: false,
      materialise: false,
      unconscious: false,
      totalPoints: null,
    },
    phase_1: {
      theatre: false,
      impulse: false,
      materialise: false,
      unconscious: false,
      totalPoints: null,
    },
    phase_2: {
      theatre: false,
      impulse: false,
      materialise: false,
      unconscious: false,
      totalPoints: null,
    },
    phase_3: {
      theatre: false,
      impulse: false,
      materialise: false,
      unconscious: false,
      totalPoints: null,
    },
    phase_4: {
      theatre: false,
      impulse: false,
      materialise: false,
      unconscious: false,
      totalPoints: null,
    },
    phase_5: {
      theatre: false,
      totalPoints: null,
    },
    temple: {
      totalPoints: null,
    },
  },
  exercises: [],
});

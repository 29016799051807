<template>
  <v-row justify="center" align="center">
    <v-col cols="12">
      <transition-group
        name="custom-classes-transition"
        enter-active-class="animated fadeInDown"
        leave-active-class="animated bounceOutRight"
      >
          <PlanningCard
            v-for="(task, idx) in tasks"
            :key="makeId(idx)"
            class="w-100 mb-5"
            :idx="idx"
            :title="getObjectiveOrGoal(idx)"
            :subtitle="getObjectiveOrGoalText(idx)"
            :addDeleteButtom="!isFirstItem() && isLastItem(idx)"
            :addCheckList="threeMonthFlag && isLastItem(idx)"
            @delete="dropTask"
          >
            <template v-slot:textarea-description>
              <InputGroup title="Descripción">
                <textarea v-model="task.description"
                          type="text"
                          rows="5"
                          cols="2"/>
                <div class="divider"></div>
              </InputGroup>
            </template>

            <template v-slot:input-timeLimit>
              <InputGroup title="¿Para cuando?">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      background-color="#efefef"
                      v-model="task.timeLimit.month"
                      :items="months"
                      label="Mes"
                      hide-details
                      dense
                      filled></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <input-validator-component
                        v-model="task.timeLimit.year"
                        type="number"
                        placeholder="Año"
                        rules="required|integer|between:2020,2030"
                        name="año"
                        @status-change="onValidationStatusChange"
                        @error="onValidationError"/>
                  </v-col>
                </v-row>
              </InputGroup>
            </template>

            <template v-slot:check-list>
              <v-row class="mb-3"
                     justify="center"
                     align="center"
                   v-for="(checkItem, idx) in task.checkList"
                   :key="idx">
                  <v-col cols="1">
                    <v-checkbox v-model="checkItem.done"
                                hide-details></v-checkbox>
                  </v-col>
                  <v-col cols="8">
                    <InputGroup>
                      <input type="text"
                            v-model="checkItem.target"/>
                      <div class="divider"></div>
                    </InputGroup>
                  </v-col>
                  <v-col cols="1">
                    <eva-icon name="trash"
                              fill="#ff6400"
                              width="30"
                              height="30"
                              @click="dropCheckItem(task, idx)"/>
                  </v-col>
              </v-row>

              <v-btn color="primary"
                     text
                     @click="addCheckItem(task)">Añadir tarea</v-btn>
            </template>
          </PlanningCard>
      </transition-group>
    </v-col>
    <v-col cols="12">
      <v-row justify="center" align="center">
        <v-btn color="primary"
              x-large
              v-show="!threeMonthFlag && thereMustBeNoEmptyFields"
              @click="addTask">Añadir meta</v-btn>
        <v-btn color="primary"
              x-large
              class="w-50"
              v-show="threeMonthFlag && hasTask && thereMustBeNoEmptyFields"
              @click="next()">{{btnFinish()}}</v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import moment from 'moment';
import find from 'lodash/find';
import PlanningCard from './PlanningCard.vue';
import InputGroup from '../InputGroup.vue';
import InputValidatorComponent from '../InputValidatorComponent.vue';
import validationsMixin from '../../mixins/validations';

export default {
  name: 'Planning',
  mixins: [validationsMixin],
  components: {
    PlanningCard,
    InputGroup,
    InputValidatorComponent,
  },
  props: {
    tasksCompleted: {
      type: Array,
    },
    modeEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    months: [
      { text: 'Enero', value: 1 },
      { text: 'Febrero', value: 2 },
      { text: 'Marzo', value: 3 },
      { text: 'Abril', value: 4 },
      { text: 'Mayo', value: 5 },
      { text: 'Junio', value: 6 },
      { text: 'Julio', value: 7 },
      { text: 'Agosto', value: 8 },
      { text: 'Septiembre', value: 9 },
      { text: 'Octubre', value: 10 },
      { text: 'Noviembre', value: 11 },
      { text: 'Diciembre', value: 12 },
    ],
    tasks: [
      {
        timeLimit: {
          month: '',
          year: '',
        },
        description: '',
        checkList: [],
      },
      {
        timeLimit: {
          month: '',
          year: '',
        },
        description: '',
        checkList: [],
      },
    ],
    validation: {
      success: {
        año: false,
        mes: false,
      },
      errors: {},
    },
  }),
  created() {
    if (this.$props && this.$props.tasksCompleted) {
      this.tasks = this.$props.tasksCompleted;
    }
  },
  methods: {
    next() {
      this.$emit('next', this.tasks);
    },
    addTask() {
      this.tasks.push({
        timeLimit: {
          month: '',
          year: '',
        },
        description: '',
        checkList: [],
      });
    },
    addCheckItem(task) {
      task.checkList.push({
        done: false,
        target: '',
      });
    },
    dropTask(idx) {
      this.tasks.splice(idx, 1);
    },
    dropCheckItem(task, idxCheckItem) {
      task.checkList.splice(idxCheckItem, 1);
    },
    getObjectiveOrGoal(idxTask) {
      if (idxTask === 0) {
        return 'objetivo';
      }
      return this.tasks[idxTask - 1].description;
    },
    getObjectiveOrGoalText(idxTask) {
      let result = '';
      if (idxTask === 0) result = '¿Cual es tu objetivo?';
      else if (idxTask === 1) result = '¿Qué tendría que suceder antes para que se cumpla tu objetivo?';
      else result = '¿Qué tendría que suceder antes para que se cumpla tu meta?';
      return result;
    },
    isNotMaximumLength() {
      return this.tasks.length < 5;
    },
    isLastItem(itemIdx) {
      return this.tasks.length - 1 === itemIdx;
    },
    isFirstItem() {
      return this.tasks.length - 1 === 0;
    },
    makeId(idx) {
      return `item-${idx}`;
    },
    btnFinish() {
      return this.modeEdit ? 'GUARDAR' : 'FINALIZAR';
    },
    normalizeWithTwoDigits(number) {
      return number < 10 ? `0${number}` : `${number}`;
    },
  },
  computed: {
    hasTask() {
      const result = find(this.tasks, (task) => task.checkList && task.checkList.length > 0);
      return result !== undefined;
    },
    threeMonthFlag() {
      if (!this.tasks || this.tasks.length < 2) return false;

      const lastTask = this.tasks[this.tasks.length - 1];
      const { year, month } = lastTask.timeLimit;

      if (
        !month
        || !year
        || year.length !== 4
      ) return false;

      const targetDate = `${year}-${this.normalizeWithTwoDigits(month)}-01`;
      const targetMomentLimit = moment(targetDate, 'YYYY-MM-DD', true);
      const threeMonthsFromNow = moment().add(3, 'months').format('YYYY-MM-DD');

      return targetMomentLimit.isSameOrBefore(threeMonthsFromNow);
    },
    thereMustBeNoEmptyFields() {
      if (!this.tasks || this.tasks.length < 2) return false;
      const lastIndex = this.tasks.length - 1;
      const lastTask = this.tasks[lastIndex];
      if (
        this.getObjectiveOrGoal(lastIndex) === ''
        || lastTask.timeLimit.month === ''
        || lastTask.timeLimit.year === ''
        || lastTask.description === ''
        || lastTask.timeLimit.year === ''
      ) return false;
      return true;
    },
  },
};
</script>

<style scoped lang="scss">
.w-100 {
  width: 100%;
}
div.divider {
  height: 1px;
  background-color: $divider;
}
</style>

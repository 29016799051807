<template>
  <ContainerSimbols :color="backgroundColor">
    <v-col cols="11" md="5" lg="6">
      <v-card id="message">
        <slot name="messages"></slot>
        <br>
        <slot name="actions"></slot>
      </v-card>
    </v-col>
  </ContainerSimbols>
</template>
<script>
import ContainerSimbols from './ContainerSimbols.vue';

export default {
  name: 'CardWithSymbols',
  props: {
    backgroundColor: {
      type: String,
      default: 'primary',
    },
  },
  components: {
    ContainerSimbols,
  },
};
</script>

<style scoped lang="scss">
div#message {
  -webkit-box-shadow: 0px -1px 8px 0px #939393;
  -moz-box-shadow: 0px -1px 8px 0px #939393;
  box-shadow: 0px -1px 8px 0px #939393;
  padding: 2em 1em 2em 1em;
  background-color: $surface;
  text-align: center;
  align-self: center;
  justify-self: center;
  margin-top: 10%;
  margin-bottom: 10%;
}
* {
  color: $foreground-secondary;
}
</style>
